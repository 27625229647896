import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/es";
import "react-big-calendar/lib/css/react-big-calendar.css";

import APPOINTMENT_SERVICE from "../../services/appointment";
import { UserContext } from "../../context/context";
import Layout from "../Layout/Layout";

const localizer = momentLocalizer(moment);

const DentistCalendar = () => {
  const userContext = useContext(UserContext);
  const history = useHistory();
  const [appointments, setAppointments] = useState([]);
  const { id } = useParams();
  const role = userContext.loggedUser.role;
  const events = [];

  useEffect(() => {
    const allAppointments = async () => {
      const response = await APPOINTMENT_SERVICE.GET_ALL_DENTIST(id);
      setAppointments(response.data);
    };
    allAppointments();
    return () => { };
  }, [id]);

  if (appointments) {
    appointments.map((appointment) => {
      if (appointment.category) {
        const dateObject = new Date(appointment.date);
        const year = dateObject.getUTCFullYear();
        const monthTemp = dateObject.getUTCMonth() + 1;
        const month = monthTemp.toString().length === 1 ? "0" + monthTemp : monthTemp;
        const dayTemp = dateObject.getUTCDate();
        const day = dayTemp.toString().length === 1 ? "0" + dayTemp : dayTemp;
        const hoursTemp = Math.floor(parseInt(appointment.starts) / 100);
        const hours = hoursTemp.toString().length === 1 ? "0" + hoursTemp : hoursTemp;
        const mins = ("0" + parseInt(appointment.starts - hours * 100)).slice(-2);
        appointment.ends = !appointment.ends ? appointment.starts + 100 : appointment.ends;
        const hoursEndTemp = Math.floor(parseInt(appointment.ends) / 100);
        const hoursEnd = hoursEndTemp.toString().length === 1 ? "0" + hoursEndTemp : hoursEndTemp;
        const minsEnd = ("0" + parseInt(appointment.ends - hours * 100)).slice(-2);
        const startDate = year + "-" + month + "-" + day + "T" + hours + ":" + mins + ":00";
        const endDate =
          year + "-" + month + "-" + day + "T" + hoursEnd + ":" + minsEnd + ":00";
        events.push({
          title: appointment.category ? appointment.category : "Procedimiento Eliminado",
          start: new Date(startDate),
          end: new Date(endDate),
        });
      }
      return events;
    });
  }
  const onEventClick = (event) => {
    userContext.calendarDate(event.start);
    history.push("/" + role + "/" + id);
  };
  const goBack = () => {
    history.push("/" + role + "/" + id);
  };

  return (
    <Layout>

      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 600 }}
        views={["month", "week", "day"]}
        selectable
        onSelectEvent={(event) => onEventClick(event)}
        messages={{
          date: "Fecha",
          time: "Tiempo",
          event: "Event",
          week: "Semana",
          day: "Día",
          month: "Mes",
          previous: "Atrás",
          next: "Siguiente",
          today: "Hoy",
          agenda: "Agenda",
          noEventsInRange: "No hay eventos.",
          showMore: function showMore(total) {
            return "+" + total + " mas";
          },
        }}
      />
      <div className="text-right">
        <button
          type="submit"
          className="btn btn-secondary  mr-0 my-2"
          onClick={goBack}
        >
          Volver a citas
        </button>
      </div>
    </Layout>
  );
};

export default DentistCalendar;
