export function calculateAge(birthday){
  if(!birthday || (!(birthday instanceof Date) && isNaN(birthday))){
    console.log(typeof birthday);
    return "Desconocida";
  }

  let today = new Date();
  let age = today.getFullYear() - birthday.getFullYear();
  let m = today.getMonth() - birthday.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
      age--;
  }

  return age;
}