import React, { useState } from 'react';
import UploadFile from '../components/Patient/Digitalization/UploadFile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage, faCamera } from '@fortawesome/free-solid-svg-icons';
import FormData from 'form-data';

import PROFILE_SERVICE from '../services/profile';

import Layout from '../components/Layout/Layout';
import TakePhoto from '../components/Patient/Digitalization/TakePhoto';
import { useHistory } from 'react-router-dom';

const NewFileRecord = () => {
  const [cameraActive, setCameraActive] = useState(false);
  const [photo, setPhoto] = useState("");
  const [uploadFile, setUploadFile] = useState(false);
  const [uploadImage, setUploadImage] = useState(false);
  const [fileName, setFileName] = useState("");
  const [firstUrl] = useState(window.location.href.split("/")[0]);
  const [lastUrl] = useState(window.location.href.split("/")[window.location.href.split("/").length - 1]);
  const [message, setMessage] = useState('')
  const [uploaded, setUploaded] = useState(false)

  const history = useHistory();

  const handleCameraActive = () => {
    if (cameraActive === true) {
      setUploadImage(false)
      return setCameraActive(false)
    }
    return setCameraActive(true);
  }

  const handleUploadImage = () => {
    return setUploadImage(true);
  }

  const handleUploadFile = () => {
    return setUploadFile(true);
  }

  const handlePhotoOnDelete = () => {
    setUploaded(false)
    return setPhoto("");
  }

  const sendPhoto = async () => {
    let data = new FormData();
    const blob = await fetch(photo).then((res) => res.blob());
    data.append('file', blob, "Foto.png");
    data.append('clinic', '6193faaf9df8b3153ee60c9c');
    data.append('dentist', '619bb4afb3704669a38b1115');
    data.append('category', 'endodoncia');
    data.append('date', Date.now());

    let rawUrl = window.location.href;
    rawUrl = rawUrl.split('/')
    const urlSize = rawUrl.length;
    const id = rawUrl[urlSize - 1];

    try {
      await PROFILE_SERVICE.SAVE_FILE({ id, data });
      setMessage('Archivo cargado exitósamene')

      history.goBack()
    } catch (error) {
      console.log('error', error)
    }
  }

  return (
    <Layout>
      <div className="row m-0 px-5">
        <div className="col card px-0">
          <div className="card-body d-flex align-items-center justify-content-center" style={{ minHeight: "400px" }}>

            {!uploadFile && !uploadImage && !photo ?
              <>
                <div
                  className="d-flex flex-column align-items-center justify-content-center mx-2"
                  onClick={handleUploadFile}
                >
                  <FontAwesomeIcon icon={faFileImage} size="5x" />
                  <p>Subir un archivo</p>
                </div>
                <div
                  className="d-flex flex-column align-items-center justify-content-center mx-2"
                  onClick={handleUploadImage}
                >
                  <FontAwesomeIcon icon={faCamera} size="5x" />
                  <p>Tomar una foto</p>
                </div>
              </>
              : ""
            }
            {photo ?
              <img src={photo} alt="record" />
              : ""
            }
            {fileName ?
              <>
                <FontAwesomeIcon icon={faFileImage} size="5x" />
                <ul className="mt-3">
                  {fileName.map((file) => (
                    <li key={file}>{file}</li>
                  ))}
                </ul>
                <p className="mt-2">{ }</p>
              </>
              : ""
            }
            {cameraActive && !photo ?
              <TakePhoto setPhoto={setPhoto} />
              : ""
            }
          </div>

          {uploadFile || uploadImage ?

            <div className="card-footer d-flex justify-content-center align-items-center ">
              <button
                className="btn btn-danger ml-0"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = firstUrl + `/patients/` + lastUrl;
                }}>
                Regresar
              </button>

              {uploadFile ?
                <UploadFile fileName={fileName} setFileName={setFileName} />
                : ""
              }

              {uploadImage && !photo ?

                cameraActive ?

                  <button className="btn btn-primary" onClick={handleCameraActive}>Desactivar cámara</button>
                  : <button className="btn btn-primary" onClick={handleCameraActive}>Activar cámara</button>


                : photo ?
                  <>

                    <button className="btn btn-danger" onClick={handlePhotoOnDelete}>Eliminar</button>
                    <button className="btn btn-primary" onClick={sendPhoto}>Enviar</button>


                  </>
                  : ""
              }
            </div>
            : ""
          }
          { uploaded ?
            <p className="d-flex justify-content-center align-items-center"> {message} </p>
            : ""
          }
        </div>

      </div>
    </Layout>
  );
};

export default NewFileRecord;
