import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Layout from "../components/Layout/Layout";

import DENTIST_SERVICE from "../services/dentist";
import USER_SERVICE from "../services/user";
// import DentistCard from "../components/Dentist/DentistCard";

// import axios from "axios";

const DentistProfile = () => {
  const history = useHistory();
  const { id } = useParams();

  const [user, setUser] = useState({});

  const deleteDentist = async () => {
    await DENTIST_SERVICE.DELETE(id);
    await USER_SERVICE.DELETE(id);
    history.push("/admin/dentists");
  };

  useEffect(() => {
    const getInitialData = async () => {
      let res = await DENTIST_SERVICE.GET_ONE(id);

      let userResponse = await USER_SERVICE.GET_ONE(res.data.idUser);

      setUser({
        name: userResponse.data.name,
        surname: userResponse.data.surname,
        email: userResponse.data.email,
        especialty: res.data.especialty,
        office: res.data.office,
      });
    }

    getInitialData()
  }, [id]);

  return (
    <Layout>
      <div
        className="flex flex-start container-fluid h-100"
        style={{ backgroundColor: "#063348" }}
      >
        <h2 className="page-title marsides2">Dentista</h2>
      </div>

      <div className="row mx-2 mx-md-5" style={{ paddingTop: "1.5em" }}>
        <div className="col-12 px-0 pb-4">
          <div className="card">
            <h4 className="card-header">{user.name + " " + user.surname}</h4>
            <div className="card-body">
              <p>
                <strong>Especialidad: </strong> {user.especialty}
              </p>
              <p>
                <strong>Email: </strong> {user.email}
              </p>
              <p>
                <strong>Oficina: </strong> {user.office}
              </p>
              <div>
                <button
                  className="btn btn-danger float-right"
                  onClick={deleteDentist}
                >
                  Eliminar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DentistProfile;
