import React from 'react';
import DentistForm from '../components/Dentist/DentistForm';

const NewDentist = () => {
  return (
    <>
      <DentistForm />
    </>
  );
};

export default NewDentist;