import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import USER_SERVICE from "../services/user"
import Layout from "../components/Layout/Layout";
import UserInfoCard from "../components/User/UserInfoCard";

const UserProfile = () =>{
  const { id } = useParams() ;

  const [user, setUser] = useState({
    name: "",
    surname: "",
    birthday: "",
    email: "",
    phone: "",
    id: "",
    contactMethods: {},
  });

  const userServiceGetOne = async (id) => {
    let { data } = await USER_SERVICE.GET_ONE(id);
    try {
      if(data.birthday){
        data.birthday = new Date(data.birthday);
        data.birthday = data.birthday.toLocaleDateString();
      }
    } catch (error) {
      //Ok :c there is no date
    }
    setUser(data);
  };

  useEffect(()=>{
    try {
      userServiceGetOne(id);
    } catch (error) {
      console.log(error.message);
    }
  },[id])

  return(
    <Layout>
      <div className="row mx-2 mx-md-5">
        <UserInfoCard
          name = {user.name}
          surname = {user.surname}
          birthday = {user.birthday}
          email = {user.email}
          phone = {user.phone}
          id={user.id}
          contactMethods={user.contactMethods}
        />
      </div>
    </Layout>
  )
}

export default UserProfile;
