import { FastField, Formik } from "formik";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import * as Yup from "yup";

const FastTextArea = (props, ref) => {
    const { validation, setValue, id, rows } = props;
    const [initialValue, setInitialValue] = useState(null);

    useImperativeHandle(ref, () => ({
        async onSubmit() {
            fastField.current.handleSubmit();
            let errors = await fastField.current.validateForm();
            return (errors);
        },
        setInitialValue(values) {
            setInitialValue(values[id]);
        }
    }));

    const fastField = useRef(null);

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    text: initialValue ?? '',
                }}
                validationSchema={Yup.object().shape({
                    text: validation ? validation : Yup.string(),
                })}
                innerRef={fastField}
                onSubmit={() => { }}
            >
                <FastField
                    name='text'
                    component={TextArea}
                    setValue={setValue}
                    id={id}
                    rows={rows}
                />
            </Formik>
        </>
    );
}

const TextArea = ({ field: { onChange, name, value, onBlur },
    form: { touched, errors },
    ...props }) => {

    return (
        <div className='flex flex-column'>
            <textarea
                type='text'
                rows={props.rows ? props.rows : 2}
                className={`form-control ${touched[name]
                    && errors[name] ? "is-invalid" : ""}`}
                onChange={onChange}
                name={name}
                value={value}
                onBlur={e => {
                    onBlur(e);
                    if (!errors[name]) {
                        props.setValue(e.target.value, props.id);
                    }
                }}
            />
            {touched[name] && errors[name]
                && <div className="invalid-feedback">{errors[name]}</div>}
        </div>
    );
}

export default forwardRef(FastTextArea);
