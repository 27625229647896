import AppRouter from "./AppRouter";
import {React} from 'react'
import "./Styles/App.css"
import dotenv from 'dotenv';
dotenv.config();

function App() {
  return (
    <div>
      <AppRouter />
    </div>
  );
}

export default App;
