import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import "../../Styles/DashboardCard.css"

const DashboardCard = ({ icon, title, links, backgroundColor }) => {
  return (
    <>
      <div className="card mb-3 mr-2 mx-md-0 dashboard-card">
        <div className="row no-gutters d-flex align-items-center">
          <div
            className="col-md-4 d-flex align-items-center justify-content-center py-3 admin-card-header"
            style={{
              background: backgroundColor,
            }}
          >
            <FontAwesomeIcon icon={icon} size="5x" color="#fff" />
          </div>
            <div className="col-md-8">
              <div className="card-body py-2 py-md-0">
                <h4 className="font-weight-bold my-0" style={{color:"#063348"}}>{title}</h4>
                {
                  links.map((link, idx) => (
                    <Link className="d-block" key={idx} to={link.url} style={{ color: '#5AC0CC' }}>
                      {link.title}
                    </Link>
                  ))
                }
              </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default DashboardCard;
