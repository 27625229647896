import * as Yup from "yup";

export default function EvolutionNotesValidation(id) {
  const evolutionNotesValidation = {
    procedure: Yup.string(),
    date: Yup.string(),
    dentist: Yup.string(),
    dentistSignature: Yup.string(),
    student: Yup.string(),
    fileNumber: Yup.string(),
  }

  return evolutionNotesValidation;
}
