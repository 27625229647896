import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import TextInputs from "./MedicalRecordsComponents/TextInputs";
import ModalSign from "./MedicalRecordsComponents/ModalSign";
import SelectionBox from "./MedicalRecordsComponents/SelectionBox";
import FastDatePicker from './MedicalRecordsComponents/FastDatePicker'
import FastTextArea from './MedicalRecordsComponents/FastTextArea'
import FastTextInput from './MedicalRecordsComponents/FastTextInput'
import Consentment from './Consentment';
import TreatmentValidation from "./Validation/TreatmentValidation";
import CLINIC_SERVICE from '../../services/clinic';

var bottomLine = {
    backgroundColor: "transparent",
    border: "none",
    borderBottom: "1px solid #000000",
    borderRadius: "0",
};

function Treatment(props, ref) {
    const treatmentValidation = TreatmentValidation();

    const refs = {
        treatmentTooth: useRef(null),
        specialty: useRef(null),
        clinic: useRef(null),
        date: useRef(null),
        futureControl: useRef(null),
        studentName: useRef(null),
        doctorName: useRef(null),
        consentment: useRef(null),
    };

    const setValue = props.setValue;
    const isBlocked = props.blockForm;

    const [studentSign, setStudentSign] = useState(false);
    const [imageStudentUrl, setImageStudentUrl] = useState(null);
    const sigStudentCanvas = useRef({});

    const [doctorSign, setDoctorSign] = useState(false);
    const [imageDoctorUrl, setImageDoctorUrl] = useState(null);
    const sigDoctorCanvas = useRef({});

    const clear = () => {
        sigStudentCanvas.current.clear();
    };
    const save = e => {
        setImageStudentUrl(sigStudentCanvas.current.toDataURL());
        setStudentSign(true);
        // formik.values["studentSignature"] = sigStudentCanvas.current.toDataURL()

        setValue(sigStudentCanvas.current.toDataURL(), 'treatment.student.signature');
    };
    //doctor signature
    const clearDoctorSignature = () => {
        sigDoctorCanvas.current.clear();
    };
    const saveDoctorSignature = () => {
        setImageDoctorUrl(sigDoctorCanvas.current.toDataURL());
        setDoctorSign(true);
        setValue(sigDoctorCanvas.current.toDataURL(), 'treatment.doctor.signature');

    };

    // Initial values
    const [initialVals, setInitialVals] = useState(null);
    useEffect(() => {

        if (initialVals) {
            setImageStudentUrl(initialVals['treatment.student.signature']);
            setStudentSign(true);

            setImageDoctorUrl(initialVals['treatment.doctor.signature']);
            setDoctorSign(true);
        }

    }, [initialVals]);


    useEffect(() => {

        const getClinics = async () => {
            const { data } = await CLINIC_SERVICE.GET_ALL();
            await data.forEach((element) => {
                // console.log(element.name)
                //  setClinics(oldArray => [...oldArray, element]);
            });


        }
        getClinics();
    }, [])



    useImperativeHandle(ref, () => ({
        async onSubmit() {
            const errors = [];
            for (var ref in refs) {
                try {
                    const eachErrors = await refs[ref].current.onSubmit();
                    for (var err in eachErrors)
                        errors.push(eachErrors[err]);
                } catch (error) {
                    console.log(ref);
                }
            }
            return errors;
        },
        setInitialValues(values) {
            setInitialVals(values);
            for (var ref in refs) {
                try {
                    refs[ref].current.setInitialValue(values);
                } catch (error) {
                    console.log(error);
                }
            }
        },
    }));


    return (
        <div className="container" id="treatment">
            <button className="btn-block btn-primary dropdown-toggle rounded ml-0 my-1 py-2" type="button"
                data-toggle="collapse" data-target="#collapseTreatment">
                Plan de tratamiento
            </button>
            <div className={`form-group ${isBlocked ? "disable" : ""}`}>
                <div className="collapse show" data-parent="#treatment" id="collapseTreatment">
                    <div className="form-row flex flex-row flex-align-items-start">
                        <div className="flex flex-column marsides1">
                            <label style={{ fontWeight: "bold" }}>ESPECIALIDAD</label>

                            <SelectionBox
                                validation={treatmentValidation.specialty}
                                items={['Odontopediatría', 'Endodoncia', 'Ortodoncia', 'Servicios Generales']}
                                id="treatment.specialty"
                                setValue={setValue}
                                ref={refs["specialty"]}
                            />
                        </div>
                        <div className="flex flex-column marsides1">
                            <label style={{ fontWeight: "bold" }}>FECHA</label>
                            <FastDatePicker
                                id='treatment.date'
                                validation={treatmentValidation.date}
                                setValue={setValue}
                                ref={refs["date"]}
                            />
                        </div>
                        <div className="flex flex-column marsides1">
                            <label style={{ fontWeight: "bold" }}>CLÍNICA</label>
                            <SelectionBox
                                validation={treatmentValidation.specialty}
                                items={["Clínica Dental Especializada", "Clínica Dental de Posgrado", "Clinica Dental Universitaria"]}
                                id="treatment.clinic"
                                setValue={setValue}
                                ref={refs["clinic"]}
                            />
                            { /*<select>
                                {
                                    clinics.map((clinic)=>{
                                        return <option id="treatment.specialty" ref={refs["clinic"]} setvalue={setValue} key={clinic._id}>{clinic.name}</option>
                                    })
                                }
                            </select>*/}
                        </div>
                    </div>
                    <br />
                    <div>
                        <TextInputs setValue={setValue} ref={refs["treatmentTooth"]} />
                    </div>
                    <br />
                    <div style={{ marginBottom: "1rem" }}>
                        <label style={{ fontWeight: "bold" }}>
                            INDICACIONES DE FUTURO CONTROL
                        </label>
                        <FastTextArea
                            validation={treatmentValidation.indications}
                            setValue={setValue}
                            id='treatment.futureControl'
                            rows={3}
                            ref={refs["futureControl"]}
                        />
                    </div>
                    <div>
                        <p className="text-center" style={{ fontWeight: "bold" }}>
                            VIGENCIA DEL PLAN DE TRATAMIENTO: 3 MESES
                        </p>
                    </div>
                    <div className="form-row row-cols-2 text-center">
                        <div className="col-6">
                            <label style={{ fontWeight: "bold" }} htmlFor="alumno">
                                NOMBRE Y FIRMA DEL ALUMNO
                            </label>

                            {!studentSign ? (
                                <div className="m-1">
                                    <ModalSign refer={sigStudentCanvas} clear={clear} save={save} id="studentSignature" />
                                </div>
                            ) : (
                                <div className="m-1">
                                    <img src={imageStudentUrl} alt="treatment" style={{ width: "200px" }} />
                                </div>
                            )}
                            <FastTextInput
                                validation={treatmentValidation.studentName}
                                setValue={setValue}
                                id='treatment.student.name'
                                style={bottomLine}
                                placeholder='Nombre del Alumno'
                                ref={refs["studentName"]}
                            />
                        </div>
                        <div className="col-6">
                            <label style={{ fontWeight: "bold" }} htmlFor="doctor">
                                AUTORIZA NOMBRE Y FIRMA DR.
                            </label>

                            {!doctorSign ? (
                                <div className="m-1">
                                    <ModalSign refer={sigDoctorCanvas} clear={clearDoctorSignature}
                                        save={saveDoctorSignature}
                                        id="drSignature"
                                    />
                                </div>
                            ) : (
                                <div className="m-1">
                                    <img src={imageDoctorUrl} alt="tratments" style={{ width: "200px" }} />
                                </div>
                            )}
                            <FastTextInput
                                validation={treatmentValidation.doctorName}
                                setValue={setValue}
                                id='treatment.doctor.name'
                                style={bottomLine}
                                placeholder='Nombre del Doctor'
                                ref={refs["doctorName"]}
                            />
                        </div>
                    </div>
                    <br />
                    <br />
                </div>
            </div>

            <button className="btn-block btn-primary dropdown-toggle rounded ml-0 my-1 py-2" type="button"
                data-toggle="collapse" data-target="#collapseConsentment">
                Consentimiento Informado
            </button>
            <div className="collapse" id="collapseConsentment" data-parent="#treatment"
                style={{ 'borderStyle': 'solid', 'borderWidth': '1px' }}>
                <div className={`form-group ${isBlocked ? "disable" : ""}`}>
                <Consentment
                    setValue={setValue}
                    ref={refs["consentment"]}
                    initialValues={initialVals}
                />
                </div>
            </div>

        </div>
    );
}

export default forwardRef(Treatment);
