import React, { useContext } from "react";
import Modal from "react-modal";

import { UserContext } from "../../context/context";

import APPOINTMENT_SERVICE from "../../services/appointment";

const ConfirmAppointmentModal = (props) => {

  const userContext = useContext(UserContext);

  Modal.setAppElement("body");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "0",
      boxShadow: "0 1rem 3rem rgba(0,0,0,.175)",
      padding: "4%",
    },
  };

  const confirmAppointment = () => {
    const data = { confirmed: true };
    // const token = localStorage.getItem("token")
    APPOINTMENT_SERVICE.UPDATE(props.appointmentId, data).then();
    userContext.changeSate();
    props.onRequestClose()
  };

  const  afterOpenModal = () => { }
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      onAfterOpen={afterOpenModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="row">
        <p>Su cita será confirmada para el dia {props.date} a las {props.hours}:{props.mins}Hrs</p>
      </div>
      <div className="text-right row">
        <button
          type="button"
          className="col btn btn-secondary btn-block mt-4"
          onClick={props.onRequestClose}
        >
          Cerrar
        </button>
        <button
          type="button"
          className="col btn btn-success  btn-block mt-4"
          onClick={confirmAppointment}
        >
          Confirmar
        </button>
      </div>
    </Modal>
  );
};
export default ConfirmAppointmentModal;
