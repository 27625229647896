import React, { useState, useContext, useEffect } from "react";
import {useHistory} from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from 'yup';

import AppointmentFormStep1 from './AppointmentFormStep1';
import AppointmentFormStep2 from './AppointmentFormStep2';
import AppointmentConfirmation from './AppointmentConfirmation';
import PROCEDURE_CLINIC from '../../services/clinic';
import { UserContext } from "../../context/context";

import APPOINTMENT_SERVICE from '../../services/appointment'

const RecurrentAppointmentModal = (props) =>{

  const userContext = useContext(UserContext);
  const history = useHistory();

  const procedures = props.procedures;
  const [schedules, setSchedules] = useState([]);

  const [clinics, setClinics] = useState([]);
  // Fetch clinics
  useEffect(() => {
    let isMounted = true;

    const getClinics = async () => {
      const { data } = await PROCEDURE_CLINIC.GET_ALL();
      if (isMounted)
        setClinics(data)
    };

    getClinics();
    return () => { isMounted = false }; // cleanup toggles value, if unmounted
  }, []);

  // States for pages
  const [pageState, setPage] = useState(0);

  // Change page
  const nextPage = () =>{ setPage(pageState + 1);}
  const backPage = () =>{ setPage(pageState - 1);}

  // Show pages
  let showPage1 = (pageState === 0) ? { display: "block" } : { display: "none" };
  let showPage2 = (pageState === 1) ? { display: "block" } : { display: "none" };
  let showPage3 = (pageState === 2) ? { display: "block" } : { display: "none" };

  //Modify the buttons according to the page
  let backButtonText = (pageState === 1) ? (pageState === 2) ? 'Ok' : 'Volver' : 'Cerrar';
  let nextButtonText = (pageState === 0) ? 'Siguiente' : (pageState === 1) ? 'Guardar' : '';
  let styleButton = (pageState === 2) ? { display: "none" } : { display: "" };

  // Handles the form in step 1
  const formikStep1 = useFormik({
    initialValues: {
      service: 'Elige...',
      clinic: 'Elige...'
    },
    validationSchema: Yup.object({
      service: Yup.string()
        .notOneOf(['Elige...',''], 'Elige una opción valida'),
      clinic: Yup.string().notOneOf(
        ["Elige...", ""],
        "Elige una opción valida"
      )
    }),
  });

  // Handles the form in step 2
  const formikStep2 = useFormik({
    initialValues: {
      date:"",
      hour:"",
      dentists: ""
    },
    validationSchema: Yup.object({
      date: Yup.date()
        .required('Requerido'),
      hour: Yup.string()
        .required('Requerido'),
    }),
  });

  const formik = useFormik({
    initialValues: {
      date:"",
      starts:"",
      ends:"",
      patient: "",
      dentistsArray:"",
      clinic:"",
      procedure:"Elige...",
      procedureInfo:'',
      clinicInfo:'',
      dateString:'',
    },
    onSubmit: values => {
      if (pageState===0){
        nextPage()
      }else if(pageState===1){

        values.procedureInfo = procedures[formikStep1.values.service];
        values.dateString = formikStep2.values.date;
        values.clinicInfo = props.appointment.clinic;

        const startHour = schedules[parseInt(formikStep2.values.hour)].hour;

        values.date = formikStep2.values.date;
        values.starts = startHour;
        const end = (parseInt(startHour) + 100);
        values.ends = end.toString();

        values.patient = props.patientId;
        values.dentistsArray = formikStep2.values.dentists;
        values.clinic = formikStep1.values.clinic;
        values.status = 5 // recurrent active

        values.category = formikStep1.values.service;
        values.procedure = formikStep1.values.service;
        nextPage();
      }
    },
  });

  // Reset the state of the component
  const resetState= () =>{
    formikStep1.handleReset();
    formikStep2.handleReset();
    formik.handleReset();
    props.close();
    setPage(0);
    setSchedules([]);
  }

  // Create the new appointment and updates the current appointment
  const newRecurrentAppointment = () => {

    const {date, starts, ends, patient, dentistsArray, clinic, category, status } = formik.values;
    const data = {date, starts, ends, patient, dentistsArray, clinic, category, status}

    APPOINTMENT_SERVICE.CREATE(data).then();

    const statusUpdate = { status: 3 };
    APPOINTMENT_SERVICE.UPDATE(props.appointment.id, statusUpdate).then();

    userContext.changeSate();
    resetState();
    history.push(`/patient/${props.patientId}`)
    return [history]
  }


  return (
    <div className={`modal ${props.modalClass}`} style={props.modalStyle}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <form onSubmit={formik.handleSubmit}>
            {/* Form content */}
            <div className="modal-body">
              <div>Agendar Siguiente Cita</div>
              <AppointmentFormStep1
                formik={formikStep1}
                formik1a={""}
                style={showPage1}
                minors={false}
                toggleMinors={""}
                clinics={clinics}
                procedures={procedures}
                showMinorsToggle={false}
              />
              <AppointmentFormStep2
                category={props.category}
                procedures={procedures}
                edit={true}
                schedules={schedules}
                setSchedules={setSchedules}
                formik={formikStep2}
                style={showPage2}
                dentist={props.appointment.dentist}
              />
              <AppointmentConfirmation
                value={formik.values}
                style={showPage3}
              />
            </div>

            <div className="modal-footer">
              <div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={
                    pageState === 0
                      ? resetState
                      : pageState === 1
                      ? backPage
                      : newRecurrentAppointment
                  }
                >
                  {backButtonText}
                </button>
                <button
                  type={"submit"}
                  style={styleButton}
                  className="btn btn-primary"
                  disabled={
                    pageState === 0
                      ? !(formikStep1.isValid && formikStep1.dirty)
                      : !(formikStep2.isValid && formikStep2.dirty)
                  }
                >
                  {nextButtonText}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RecurrentAppointmentModal;
