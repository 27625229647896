import React, { Fragment } from 'react';
import '../Styles/Landing.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare, faLinkedin, faInstagram, faTwitterSquare,
} from '@fortawesome/free-brands-svg-icons';
import Hero from '../assets/img/hero.jpg';
import Layout from '../components/Layout/Layout';

const Landing = () => {
  return (
    <>
      <Layout >
        <div
          className="container-fluid align-items-center mt-n4 p-0 home"
        >
          <div className="row p-0 m-0">
            <div className="col p-0 hero-container">
              <img src={Hero} className="img-fluid position-relative hero" alt="..." />
              <div className="position-absolute">
                <h1 className="display-3 font-weight-bold text-light text-center text-uppercase" >ION</h1>
                <h1 className="display-5 font-weight-bold text-light text-center text-uppercase" >Clínica Dental</h1>
              </div>

            </div>
          </div>
          <div className="row align-items-center info-row p-0 m-0">
            <div className="col px-0 pt-3 info align-items-center">
              <h1 className=" font-weight-bold text-center mb-4 mx-2 mx-sm-0 text-uppercase">
                Únete desde la comodidad de tu hogar
              </h1>
              <ul className="d-md-flex">
                <li className="mx-2">
                  <h3 className="font-weight-bold text-uppercase">Registrate</h3>
                  <p>
                    Solo llena un formulario y empieza a ser parte de nuestros increíbles clientes
                  </p>
                </li>
                <li className="mx-2">
                  <h3 className="font-weight-bold text-uppercase">Agenda</h3>
                  <p>
                    Elíge fácilmente el tratamiento, la fecha, la hora y obten tu cita inmediatamente
                  </p>
                </li>
                <li className="mx-2">
                  <h3 className="font-weight-bold text-uppercase">Sonríe!</h3>
                  <p>
                    ¡Asiste a tu cita con uno de los mejores especialistas y preparate para lucir tu
                    sonrisa!
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="row py-4 m-0">
            <div className="col col-12 social-media">
              <h3 className="font-weight-bold text-capitalize text-center">¡Síguenos en redes!</h3>
            </div>
            <div className="col d-flex justify-content-center">
              <a href="https://www.facebook.com/UniversidadION/" className="mx-2" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faFacebookSquare} size="5x" color="#01437D" />
              </a>
              <a href="https://twitter.com/ionhermosillo" className="mx-2" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faTwitterSquare} size="5x" color="#01437D" />
              </a>
              <a href="https://www.instagram.com/universidadion/" className="mx-2" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faInstagram} size="5x" color="#01437D" />
              </a>
              <a href="https://www.linkedin.com/company/universidad-ion" className="mx-2" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faLinkedin} size="5x" color="#01437D" />
              </a>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Landing;
