
const ImageModal = ({ image }) => {

    return (
        <div className="modal fade" id="imageModal" tabIndex="-1" aria-labelledby="imageModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-scrollable">
                <div className="modal-content text-center">
                    <div className="modal-body">
                        {
                            (image && !image.endsWith('.pdf'))  ?
                            <img key={image}
                                src={image}
                                className="img-fluid"
                                alt="Responsive"
                            /> :
                            <object aria-label="PDF" data={ image } width="100%" style={{height: '85vh'}}></object>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImageModal;