import React, { useRef, useImperativeHandle, forwardRef } from "react";
import FastTextInput from "./MedicalRecordsComponents/FastTextInput";
import FastRadialButtons from "./MedicalRecordsComponents/FastRadialButtons";
import FastDatePicker from "./MedicalRecordsComponents/FastDatePicker";
import DentalHistoryValidation from "./Validation/DentalHistoryValidation";

const DentalHistory = (props, ref) => {
    const dentalHistoryValidation = DentalHistoryValidation();
    const { setValue, block } = props;
    const isBlocked = true;

    useImperativeHandle(ref, () => ({
        async onSubmit() {
            const errors = [];
            for (let ref in refs) {
                try {
                    const eachErrors = await refs[ref].current.onSubmit();
                    for (let err in eachErrors)
                        errors.push(eachErrors[err]);
                } catch (error) {
                    console.log(ref);
                }
            }
            return errors;
        },
        setInitialValues(values) {
            for (let ref in refs) {
                try {
                    refs[ref].current.setInitialValue(values);
                } catch (error) {
                    console.log(ref);
                }
            }
        },
    }));


    const refs = {
        'primerVisita': useRef(null),
        'fechaÚltimaVisita': useRef(null),
        'motivoÚltimaVisita': useRef(null),
        'dolorDental': useRef(null),
        'descripciónDolorDental': useRef(null),
        'dietaBalanceada': useRef(null),
        'descripciónDietaBalanceada': useRef(null),
        'alimentadoSenoMaterno': useRef(null),
        'descripciónSenoMaterno': useRef(null),
        'usaBiberón': useRef(null),
        'descripciónUsaBiberón': useRef(null),
        'usaChupón': useRef(null),
        'descripciónUsaChupón': useRef(null),
        'tieneHabito': useRef(null),
        'habitoDuración': useRef(null),
        'habitoFrecuencia': useRef(null),
        'habitoIntensidad': useRef(null),
        'cepilladoDientes': useRef(null),
        'pastaDental': useRef(null),
        'complementosCepilladoDental': useRef(null)
    }

    return (
        <div className="container">
            <div className={`form-group ${isBlocked && block ? "disable" : ""}`}>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="primerVisita">
                            Es la primer visita al dentista?
                        </label>
                        <div className="form-check form-check-inline">
                            <FastRadialButtons
                                setValue={setValue}
                                id="dentalHistory.primerVisita"
                                options={["Si", "No"]}
                                ref={refs["primerVisita"]}
                                name="primerVisita"
                            />
                        </div>
                        <div id="visitaAnterior">
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="fechaUltimaVisita">Fecha de última visita</label>
                                    <FastDatePicker
                                        validation={dentalHistoryValidation.fechaÚltimaVisita}
                                        id='dentalHistory.fechaÚltimaVisita'
                                        setValue={setValue}
                                        ref={refs["fechaÚltimaVisita"]}
                                    />
                                </div>
                                <div className="">
                                    <label htmlFor="motivoVisita">Motivo</label>
                                    <FastTextInput
                                        validation={dentalHistoryValidation.motivoÚltimaVisita}
                                        setValue={setValue}
                                        id="dentalHistory.motivoÚltimaVisita"
                                        ref={refs["motivoÚltimaVisita"]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="dolorDental">Ha presentado dolor dental?</label>
                    <div className="form-check form-check-inline">
                        <FastRadialButtons
                            setValue={setValue}
                            id="dentalHistory.dolorDental"
                            options={["Si", "No"]}
                            ref={refs["dolorDental"]}
                            name="dolorDental"
                        />
                    </div>

                    <div className="" id="hideDolorDental">
                        <div className="form-group">
                            <FastTextInput
                                validation={dentalHistoryValidation.descripciónDolorDental}
                                setValue={setValue}
                                id="dentalHistory.descripciónDolorDental"
                                ref={refs["descripciónDolorDental"]}
                                placeholder="Describa"
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="dietaBalanceada">Tiene una dieta balanceada?</label>
                    <div className="form-check form-check-inline">
                        <FastRadialButtons
                            setValue={setValue}
                            id="dentalHistory.dietaBalanceada"
                            options={["Si", "No"]}
                            ref={refs["dietaBalanceada"]}
                            name="dietaBalanceada"
                        />
                    </div>
                    <div id="hideDieta">
                        <div className="form-group">
                            <FastTextInput
                                validation={dentalHistoryValidation.descripciónDietaBalanceada}
                                setValue={setValue}
                                id="dentalHistory.descripciónDietaBalanceada"
                                ref={refs["descripciónDietaBalanceada"]}
                                placeholder="Describa"
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="senoMaterno">
                        Es/Fue alimentado con seno materno?
                    </label>
                    <div className="form-check form-check-inline">
                        <FastRadialButtons
                            setValue={setValue}
                            id="dentalHistory.alimentadoSenoMaterno"
                            options={["Si", "No"]}
                            ref={refs["alimentadoSenoMaterno"]}
                            name="alimentadoSenoMaterno"
                        />
                    </div>

                    <div id="hideSenoMaterno">
                        <div className="form-group">
                            <FastTextInput
                                validation={dentalHistoryValidation.descripciónSenoMaterno}
                                setValue={setValue}
                                id="dentalHistory.descripciónSenoMaterno"
                                ref={refs["descripciónSenoMaterno"]}
                                placeholder="Describa"
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="biberon">Usa biberón?</label>
                    <div className="form-check form-check-inline">
                        <FastRadialButtons
                            setValue={setValue}
                            id="dentalHistory.usaBiberón"
                            options={["Si", "No"]}
                            ref={refs["usaBiberón"]}
                            name="usaBiberón"
                        />
                    </div>

                    <div className="" id="hideBiberon">
                        <div className="form-group">
                            <FastTextInput
                                validation={dentalHistoryValidation.descripciónUsaBiberón}
                                setValue={setValue}
                                id="dentalHistory.descripciónUsaBiberón"
                                ref={refs["descripciónUsaBiberón"]}
                                placeholder="Describa"
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="chupon">Usa chupón?</label>
                    <div className="form-check form-check-inline">
                        <FastRadialButtons
                            setValue={setValue}
                            id="dentalHistory.usaChupón"
                            options={["Si", "No"]}
                            ref={refs["usaChupón"]}
                            name="usaChupón"
                        />
                    </div>

                    <div className="" id="hideChupon">
                        <div className="form-group">
                            <FastTextInput
                                validation={dentalHistoryValidation.descripciónUsaChupón}
                                setValue={setValue}
                                id="dentalHistory.descripciónUsaChupón"
                                ref={refs["descripciónUsaChupón"]}
                                placeholder="Describa"
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="habitos">Tiene algún hábito?</label>
                    <div className="form-check form-check-inline">
                        <FastRadialButtons
                            setValue={setValue}
                            id="dentalHistory.tieneHabito"
                            options={["Si", "No"]}
                            ref={refs["tieneHabito"]}
                            name="tieneHabito"
                        />
                    </div>

                    <div className="" id="hideHabitos">
                        <div className="form-group">
                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <FastTextInput
                                        validation={dentalHistoryValidation.habitoFrecuencia}
                                        setValue={setValue}
                                        id="dentalHistory.habitoFrecuencia"
                                        placeholder="Frecuencia"
                                        ref={refs["habitoFrecuencia"]}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <FastTextInput
                                        validation={dentalHistoryValidation.habitoDuración}
                                        setValue={setValue}
                                        id="dentalHistory.habitoDuración"
                                        placeholder="Duración"
                                        ref={refs["habitoDuración"]}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <FastTextInput
                                        validation={dentalHistoryValidation.habitoIntensidad}
                                        setValue={setValue}
                                        id="dentalHistory.habitoIntensidad"
                                        placeholder="Intensidad"
                                        ref={refs["habitoIntensidad"]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group  col-md-6">
                        <label htmlFor="cepillado">
                            Cuantas veces cepilla sus dientes?
                        </label>
                        <FastTextInput
                            validation={dentalHistoryValidation.cepilladoDientes}
                            setValue={setValue}
                            id="dentalHistory.cepilladoDientes"
                            ref={refs["cepilladoDientes"]}
                        />
                    </div>
                    <div className="form-group  col-md-6">
                        <label htmlFor="tipoPastaDental">Tipo de pasta dental</label>
                        <FastTextInput
                            validation={dentalHistoryValidation.pastaDental}
                            setValue={setValue}
                            id="dentalHistory.pastaDental"
                            ref={refs["pastaDental"]}
                        />
                    </div>
                    <div className="form-group col-md-12">
                        <label htmlFor="complementoCepillado">
                            Complementa el cepillado con hilo dental, enjuague bucal, otros?
                        </label>
                        <FastTextInput
                            validation={dentalHistoryValidation.complementosCepilladoDental}
                            setValue={setValue}
                            id="dentalHistory.complementosCepilladoDental"
                            ref={refs["complementosCepilladoDental"]}
                        />
                    </div>
                </div>
                <div></div>
                LOS DATOS AQUÍ PROPORCIONADOS SON CORRECTOS Y PERMITIRAN AL ESPECIALISTA
                TRATAR A MI HIJO(A) EN LAS MEJORES CONDICIONES DE ACUERDO CON LAS
                NECESIDADES DEL CASO
            </div>
            <hr />
        </div>
    );
};

export default forwardRef(DentalHistory);
