import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import History from "../components/MedicalRecords/History";
import Identification from "../components/MedicalRecords/Identification";
import DentalHistory from "../components/MedicalRecords/DentalHistory";
import PATIENT_SERVICE from "../services/patient";

import flat from "flat";

const ProfileInfo = () => {
  const { id } = useParams();

  const [values] = useState({});
  function setValue(value, id) {
    values[id] = value;
  }

  const [patientName, setName] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [blockForm, setBlockForm] = useState(false);

  const refs = {
    identification: useRef(null),
    history: useRef(null),
    dentalHistory: useRef(null),
  };

  useEffect(() => {
    const getPatientInfo = async () => {
      const response = await PATIENT_SERVICE.GET_ONE(id);
      const data = response.data;

      // if has_data then blockForm = true
      data ? setBlockForm(true) : setBlockForm(false);

      for (var ref in refs) {
        try {
          refs[ref].current.setInitialValues(flat.flatten(data));
        } catch (error) {
          console.log(error);
        }
      }

      const generalInfo = data.generalInfo;
      setName(
        `${generalInfo.name} ${generalInfo.paternalLastName} ${generalInfo.maternalLastName}`
      );
    }

    getPatientInfo()
  });

  const updateProfileInfo = async () => {
    const jsonValues = flat.unflatten(values);
    try {
      const { data } = await PATIENT_SERVICE.EDIT(id, jsonValues);
      if (data) {
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);

        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }

  }

  return (
    <Layout>
      {/* <form onSubmit={onSubmit}> */}
      <div className="container card py-4">
        {
          showMessage ?
            <p className="alert alert-success">Se editó correctamente</p>
            :
            <p></p>
        }

        <div>
          <h3 className="pl-2 font-weight-bold">{patientName.toUpperCase()}</h3>
        </div>
        <br />
        <div className="endRow">

          <button className="btn btn-primary" onClick={() => { updateProfileInfo() }}>
            Actualizar datos
          </button>
        </div>
        <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <a
              className="nav-link active"
              id="identificacion-tab"
              data-toggle="tab"
              href="#identification"
              role="tab"
            >
              Ficha de Identificación
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link"
              id="identificacion-tab"
              data-toggle="tab"
              href="#history"
              role="tab"
            >
              Historia Médica
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link"
              id="historia-dental-tab"
              data-toggle="tab"
              href="#historia-dental"
              role="tab"
            >
              Historia Dental
            </a>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="identification"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <Identification
              setValue={setValue}
              ref={refs["identification"]}
              blockForm={blockForm}
            />
          </div>
          <div
            className="tab-pane fade"
            id="history"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <History
              setValue={setValue}
              ref={refs["history"]}
              blockForm={blockForm}
            />
          </div>
          <div
            className="tab-pane fade"
            id="historia-dental"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <DentalHistory
              setValue={setValue}
              ref={refs["dentalHistory"]}
              blockForm={blockForm}
            />
          </div>
        </div>
      </div>
      {/* </form> */}
    </Layout>
  );
};

export default ProfileInfo;
