import React from 'react';
import Layout from '../../components/Layout/Layout';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import DashboardCard from '../../components/DashboardCard/DashboardCard';

const Receptionist = () => {
  return (
    <Layout>
      <div className="col">
        <div className="row no-gutters mx-2">
          <DashboardCard 
            icon={faQrcode}
            title="Código QR"
            links={[
              {
                title: "Pantalla de Qr",
                url: "/receptionist/appointments"
              }
            ]}
            backgroundColor="#d2d6de"
          />
        </div>
      </div>
    </Layout>
  );
}

export default Receptionist;
