import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import ModalSign from "./MedicalRecordsComponents/ModalSign";
import FastTextInput from "./MedicalRecordsComponents/FastTextInput";
import ConsentmentValidation from "./Validation/ConsentmentValidation";
import FastDatePicker from "./MedicalRecordsComponents/FastDatePicker";

let bottomLine = {
  backgroundColor: "transparent",
  border: "none",
  borderBottom: "1px solid #000000",
  borderRadius: "0",
};

const Consentment = (props, ref) => {
  const consentmentValidation = ConsentmentValidation();

  const refs = {
    "patientName": useRef(null),
    "patientAge": useRef(null),
    "tutorName": useRef(null),
    "dentistName": useRef(null),
    "tutorKinship": useRef(null),
    "consentDate": useRef(null)
  }


  const { setValue, isBlocked } = props;

  // const today = new Date();
  const [signed, setSigned] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const sigCanvas = useRef({});

  const [witnessSigned, setWitnessSigned] = useState(false);
  const [witnessImageUrl, setWitnessImageUrl] = useState(null);
  const sigWitnessCanvas = useRef({});

  const [witness2Signed, setWitness2Signed] = useState(false);
  const [witness2ImageUrl, setWitness2ImageUrl] = useState(null);
  const sigWitness2Canvas = useRef({});

  const clear = () => {
    sigCanvas.current.clear();
  };
  const save = () => {
    setImageUrl(sigCanvas.current.toDataURL());
    setSigned(true);
    setValue(
      sigCanvas.current.toDataURL(),
      "treatment.consentment.tutor.signature"
    );
  };
  //wtiness
  const clearWitness = () => {
    sigWitnessCanvas.current.clear();
  };
  const saveWitness = () => {
    setWitnessImageUrl(sigWitnessCanvas.current.toDataURL());
    setWitnessSigned(true);
    setValue(
      sigWitnessCanvas.current.toDataURL(),
      "treatment.consentment.witnessOne.signature"
    );
  };
  //witness 2
  const clearWitness2 = () => {
    sigWitness2Canvas.current.clear();
  };
  const saveWitness2 = () => {
    setWitness2ImageUrl(sigWitness2Canvas.current.toDataURL());
    setWitness2Signed(true);
    setValue(
      sigWitness2Canvas.current.toDataURL(),
      "treatment.consentment.witnessTwo.signature"
    );
  };
  const showDescription = (id, idHidden) => {
    if (document.getElementById(id).checked === true) {
      document.getElementById(idHidden).classList.remove("hidden");
    }
  };
  const hideDescription = (id, idHidden) => {
    if (document.getElementById(id).checked === true) {
      document.getElementById(idHidden).classList.add("hidden");
    }
  };


  // Initial values
  const [initialVals, setInitialVals] = useState(null);
  useEffect(() => {
    if (initialVals) {
      if (initialVals['treatment.consentment.consent']) {
        showDescription("consientoTrue", "firmasConsentment");
      }

      if (initialVals['treatment.consentment.tutor.signature']) {
        setImageUrl(initialVals['treatment.consentment.tutor.signature']);
        setSigned(true);
      }

      if (initialVals['treatment.consentment.witnessOne.signature']) {
        setWitnessImageUrl(initialVals['treatment.consentment.witnessOne.signature']);
        setWitnessSigned(true);
      }

      if (initialVals['treatment.consentment.witnessTwo.signature']) {
        setWitness2ImageUrl(initialVals['treatment.consentment.witnessTwo.signature']);
        setWitness2Signed(true);
      }
    }
  }, [initialVals]);

  useImperativeHandle(ref, () => ({
    async onSubmit() {
      const errors = [];
      for (let ref in refs) {
        try {
          const eachErrors = await refs[ref].current.onSubmit();
          for (let err in eachErrors)
            errors.push(eachErrors[err]);
        } catch (error) {
          console.log(ref);
        }
      }
      return errors;
    },
    setInitialValue(values) {
      setInitialVals(values);
      for (let ref in refs) {
        try {
          refs[ref].current.setInitialValue(values);
        } catch (error) {
          console.log(error);
        }
      }
    },
  }));

  return (
    <div className="container" style={{ fontWeight: "bold" }}>
      <div className={`form-group ${isBlocked ? "disable" : ""}`}>
        <p className="text-center" style={{ fontSize: "xx-large" }}>
          CONSENTIMIENTO INFORMADO
        </p>
      </div>
      <div className="flex flex-align-items-base flex-just-space-end">
        <p className="text-right marright1">
          Hermosillo, Sonora a
        </p>
        <FastDatePicker
          id='treatment.consentment.date'
          setValue={setValue}
          ref={refs["consentDate"]}
        />
      </div>
      <div className="form-group">
        <div>
          <div className="text-left">
            Por medio de la presente doy mi autorización para que sea atendido
            mi hijo(a)
          </div>
          <br />
          <div className="text-left input-group">
            <FastTextInput
              setValue={setValue}
              id='treatment.consentment.patient.name'
              style={bottomLine}
              placeholder='Nombre completo'
              validation={consentmentValidation.patientName}
              ref={refs['patientName']}
            />
            de
            <FastTextInput
              setValue={setValue}
              id='treatment.consentment.patient.age'
              style={bottomLine}
              placeholder='Edad'
              validation={consentmentValidation.pacientAge}
              ref={refs['patientAge']}
            />
            años de edad, en la Universidad ION.
          </div>
          <br />
          <div className="text-left">
            La persona responsable para realizar la historia clínica y
            posteriormente autorizar el plan de tratamiento adecuado a las
            necesidades de mi hijo(a) será:
            <FastTextInput
              setValue={setValue}
              id='treatment.consentment.tutor.name'
              style={bottomLine}
              placeholder='Nombre de padre o tutor'
              validation={consentmentValidation.tutorName}
              ref={refs['tutorName']}
            />
            <br />
            cuyo parentesco es:
            <FastTextInput
              setValue={setValue}
              id='treatment.consentment.tutor.kinship'
              style={bottomLine}
              placeholder='Parentesco'
              validation={consentmentValidation.relationship}
              ref={refs['tutorKinship']}
            />
            <br />
          </div>
          <br />
          <div className="text-left">
            Manifiesto que el cirujano dentista
            <FastTextInput
              setValue={setValue}
              id='treatment.consentment.dentist.name'
              style={bottomLine}
              placeholder='Nombre completo de cirujano dentista'
              validation={consentmentValidation.dentistName}
              ref={refs['dentistName']}
            />
            <br />
            <div className="text-left">
              <br />
              amablemente me informo de manera verbal, libre y sin coerción
              alguna, en forma clara, sencilla y suficiente, acerca del
              diagnóstico y las alternativas de tratamiento.
            </div>
            <div className="text-left">
              Estoy informado(a) que durante la práctica de la odontología y sus
              diversas disciplinas de especialización en ocasiones incluyen
              riesgos, complicaciones e incluso posibilidad de que se presente
              una emergencia medicoodontológica; por lo tanto, los resultados no
              se pueden garantizar, acepto afrontar los riesgos por ser mayor el
              beneficio esperado.
            </div>
            <p className="text-center">
              Por lo anterior, firmo al calce para dar constancia y efectos
              legales a que haya lugar.
            </p>
            <p className="text-center">
              Paciente, padre o tutor, representante legal o familiar.
            </p>
          </div>
          <div className="input-group form-group ">
            <div className="form-row mx-auto">
              <div className="form-group">
                {initialVals ?
                  <div className="form-check form-check-inline">
                    <div>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="consentment"
                        id="consientoTrue"
                        defaultChecked={initialVals['treatment.consentment.consent']}
                        value="true"
                        onClick={() => {
                          showDescription("consientoTrue", "firmasConsentment");
                          setValue(true, "treatment.consentment.consent");
                        }}
                      />
                      <label className="form-check-label" htmlFor="consientoTrue">
                        Consiento
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="consentment"
                        id="consientoFalse"
                        defaultChecked={!initialVals['treatment.consentment.consent']}
                        value="false"
                        onClick={() => {
                          hideDescription("consientoFalse", "firmasConsentment");
                          setValue(false, "treatment.consentment.consent");
                        }}
                      />
                      <label className="form-check-label" htmlFor="consientoFalse">
                        No Consiento
                      </label>
                    </div>
                  </div>
                  :
                  <div className="form-check form-check-inline">
                    <div>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="consentment"
                        id="consientoTrue"
                        value="true"
                        onClick={() => {
                          showDescription("consientoTrue", "firmasConsentment");
                          setValue(true, "treatment.consentment.consent");
                        }}
                      />
                      <label className="form-check-label" htmlFor="consientoTrue">
                        Consiento
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="consentment"
                        id="consientoFalse"
                        value="false"
                        onClick={() => {
                          hideDescription("consientoFalse", "firmasConsentment");
                          setValue(false, "treatment.consentment.consent");
                        }}
                      />
                      <label className="form-check-label" htmlFor="consientoFalse">
                        No Consiento
                      </label>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden" id="firmasConsentment">
        <div className="text-center">
          <div className="align-self-center">
            <label htmlFor="firma" className="">
              Firma:
            </label>
          </div>
          {!signed ? (
            <div className="">
              <ModalSign
                refer={sigCanvas}
                clear={clear}
                save={save}
                id="signature"
              />
            </div>
          ) : (
            <div className="">
              <img src={imageUrl} alt="Consentment" style={{ width: "200px" }} />
            </div>
          )}
        </div>
        <div className="container">
          <div className="row">
            <div className="row">
              <div className="col md-6 text-center">
                <div>
                  <label htmlFor="testigo1">Testigo I FIRMA</label>
                </div>
                {!witnessSigned ? (
                  <>
                    <ModalSign
                      refer={sigWitnessCanvas}
                      clear={clearWitness}
                      save={saveWitness}
                      id="witnessSignature1"
                    />
                  </>
                ) : (
                  <img src={witnessImageUrl} alt="Consentment" style={{ width: "200px" }} />
                )}
              </div>
              <div className="col md-6 text-center">
                <div className="">
                  <div>
                    <label htmlFor="testigo2">Testigo II FIRMA</label>
                  </div>
                  {!witness2Signed ? (
                    <>
                      <ModalSign
                        refer={sigWitness2Canvas}
                        clear={clearWitness2}
                        save={saveWitness2}
                        id="witnessSignature2"
                      />
                    </>
                  ) : (
                    <img src={witness2ImageUrl} alt="Consentment" style={{ width: "200px" }} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="fw-lighter">
              Con base en el Código Civil. Obligaciones en general sobre el
              consentimiento informado artículos 1803 y 1812
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Consentment);
