//import { Link } from "react-router-dom";
import AppointmentRowTable from "./AppointmentRowTable";
import AppointmentHeadTable from "./AppointmentHeadTable";
import { useEffect, useState } from "react";

const AppointmentTable = ({ appointments, startDate, dayCount, startHour, endHour }) => {
  const theadData = [
    "Hora",
    "Clínica",
    "Doctor",
    "Paciente",
    "Servicio",
    "Status",
  ];
  const [groups1, setGroups1] = useState({});

  const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  const getDateNoHours = (date) => {
    let day = date.getDate();
    let month = date.getMonth()+1;
    //Format for day adding a 0 before the day if needed
    if (day <= 9) {
      day = "0" + day;
    }
    //Format for month adding a 0 before the month if needed
    if (month <= 9) {
      month = "0" + month;
    }
    return `${month}-${day}-${date.getFullYear()}`
  }

  useEffect(() => {
    const groupAppointmentsAvailable = (appointments) => {
      let days = {};
      for (let i = 0; i < dayCount; i++) {
        let date = getDateNoHours(addDays(startDate, i));
        days[date] = {};
        
      }
      // Separate appointments by days
      for (let app of appointments) {
        if (app.status !== 1) {
          let appDate = getDateNoHours(new Date(app.date));
          if (appDate in days) {
            let times = (app.ends - app.starts) / 50;
            for (let i = 0, hour = app.starts; i < times; i++) {
              if (hour in days[appDate]) {
                days[appDate][hour].push(app);
              } else {
                days[appDate][hour] = [app];
              }
              hour % 100 === 0 ? hour += 30 : hour += 70;
            }
          }
        }
      }
      Object.keys(days).map((day, i) => {
        let availableSlots = [];
        for (let j = startHour; j < endHour;) {
          if (j in days[day]) {
            if (availableSlots.length !== 0) {
              let finalHour = availableSlots[availableSlots.length - 1] + (availableSlots[availableSlots.length - 1] % 100 === 0 ? 30 : 70);
              days[day][availableSlots[0]] = [{
                date: new Date(day),
                starts: availableSlots[0],
                ends: finalHour,
                clinic: "",
                doctor: "",
                patient: "",
                service: "",
                comments: "",
                status: "Disponible",
              }]
              availableSlots = [];
            }
          } else {
            availableSlots.push(j);
          }
          j % 100 === 0 ? j += 30 : j += 70;
        }
        if (availableSlots.length !== 0) {
          let finalHour = availableSlots[availableSlots.length - 1] + (availableSlots[availableSlots.length - 1] % 100 === 0 ? 30 : 70);
          days[day][availableSlots[0]] = [{
            date: new Date(day),
            starts: availableSlots[0],
            ends: finalHour,
            clinic: "",
            doctor: "",
            patient: "",
            service: "",
            comments: "",
            status: "Disponible",
          }];
        }
        return days;
      })
      
      return days;
    }
    setGroups1(groupAppointmentsAvailable(appointments));



  }, [appointments, startHour, endHour, dayCount, startDate]);

  return (
    <>
      {Object.keys(groups1).map((day, i) => {
        return (
          <table className="table table-hover">
            {day <= 9 ?
              <thead>
                <tr style={{ textAlign: "center", fontSize: "20px" }}>
                  <td colSpan={theadData.length}>
                    {day}
                  </td>
                </tr>
                <tr>
                  {theadData.map((h, idx) => {
                    return <AppointmentHeadTable key={idx} item={h} />;
                  })}
                </tr>
              </thead>
              : <thead>
                <tr style={{ textAlign: "center", fontSize: "20px" }}>
                  <td colSpan={theadData.length}>
                    {day}
                  </td>
                </tr>
                <tr>
                  {theadData.map((h, idx) => {
                    return <AppointmentHeadTable key={idx} item={h} />;
                  })}
                </tr>
              </thead>
            }
            <tbody>
              {Object.keys(groups1[day]).map((hour, j) => {
                return groups1[day][hour].map((app, k) => {
                  return (
                    <AppointmentRowTable
                      key={i + "-" + j + "-" + k}
                      appointmentData={app}
                      hourRow={hour}
                      dateRow={day}
                      setHour={k === 0 ? true : false}
                      numRows={groups1[day][hour].length}
                      id={i + "-" + j + "-" + k}
                      startHour={startHour}
                      end={endHour}
                    />
                  );
                });
              })}
            </tbody>
          </table>
        );
      })}
    </>
  );
};

export default AppointmentTable;
