import React, { useState, useContext } from "react";
import Modal from "react-modal";
import { useFormik } from "formik";
import * as Yup from "yup";

import AUTH_SERVICE from "../../services/auth";
import APPOINTMENT_SERVICE from "../../services/appointment";
import { UserContext } from "../../context/context";

const RegisterPatient = (props) => {
  const userContext = useContext(UserContext);
  const id = props.idAppointment;
  Modal.setAppElement("body");
  const [errorMessage, setErrorMessage] = useState("");

  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      email: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Nombre(s) es requerido."),
      surname: Yup.string().required("Apellido(s) es requerido."),
      email: Yup.string()
        .email("Correo electrónico invalido.")
        .required("Un correo electrónico es requerido."),
    }),
    onSubmit: async (values) => {
      try {
        setErrorMessage("");
        const response = await AUTH_SERVICE.REGISTER(values);
        const {
          data: { id: idUser },
        } = response;
        const data = { patient: idUser };
        await APPOINTMENT_SERVICE.UPDATE(id, data);
        props.onRequestClose();
        userContext.changeSate();
        //window.location.reload();
        //alert("Se ha registrado al paciente y su cuenta se ha creado.");
      } catch (error) {
        console.log(error);
        setErrorMessage("El usuario ya existe");
      }
    },
  });

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "0",
      boxShadow: "0 1rem 3rem rgba(0,0,0,.175)",
      padding: "4%",
    },
  };

  function afterOpenModal() {}
  return (
    <Modal
      isOpen={props.isOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={props.onRequestClose}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="">
        <button type="button" className="close" onClick={props.onRequestClose}>
          x
        </button>
        <h1>Registro del Paciente</h1>
        <form>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Nombre(s)</label>
            <input
              type="text"
              className={`form-control ${
                formik.errors.name && formik.touched.name ? "is-invalid" : ""
              }`}
              id="name"
              placeholder="Nombre"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.name && formik.touched.name && (
              <div className="invalid-feedback">{formik.errors.name}</div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Apellido(s)</label>
            <input
              type="text"
              placeholder="Apellido"
              className={`form-control ${
                formik.errors.surname && formik.touched.surname
                  ? "is-invalid"
                  : ""
              }`}
              id="surname"
              name="surname"
              value={formik.values.surname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.surname && formik.touched.surname && (
              <div className="invalid-feedback">{formik.errors.surname}</div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputPassword1">Correo electrónico</label>
            <input
              type="email"
              className={`form-control ${
                formik.errors.email && formik.touched.email ? "is-invalid" : ""
              }`}
              placeholder="ejemplo@gmail.com"
              id="inputEmail"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.email && formik.touched.email && (
              <div className="invalid-feedback">{formik.errors.email}</div>
            )}
            {errorMessage && <div className="text-danger">{errorMessage}</div>}
          </div>
          <div className="text-right">
            <button
              type="submit"
              className="btn btn-primary btn-lg btn-block mt-4"
              onClick={formik.handleSubmit}
            >
              Registrar paciente
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default RegisterPatient;
