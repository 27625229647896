import * as Yup from "yup";

const MedicalHistoryValidation = () => {
    const medicalHistoryValidation = {
        familyMedic: Yup.string().max(200, "Muy Largo"),
        institution: {
            name: Yup.string().matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ][a-zA-ZÀ-ÖØ-öø-ÿ\s]*$/, "Contiene caracteres no permitidos").min(1).max(99, "Demasiado largo"),
            address: Yup.string().max(200, "Muy Largo"),
            phone: Yup.string().matches(/^[0-9]+$/, "Solo numeros").min(8, 'Ingrese numero valido').max(10, 'Ingrese numero valido'),
        },
        gestationTime: Yup.string().required(" ").max(10, "Muy largo"),
        birthType: Yup.string().required(" ").max(10, "Muy largo"),
        birthHeight: Yup.string().required(" ").max(10, "Muy largo"),
        birthWeight: Yup.string().required(" ").max(10, "Muy largo"),
        actualHeight: Yup.string().required(" ").max(10, "Muy largo"),
        actualWeight: Yup.string().required(" ").max(10, "Muy largo"),
        bloodTransfusion: Yup.string().required(" "),
        actualTreatment: Yup.string().required(" "),
        observations: Yup.string(),
        asaPatient: Yup.string().required(' '),
        bloodType: Yup.string().required(' '),
        bloodRh: Yup.string().required(' '),
        diagnosedConditions: Yup.array(),
        VaccinationCard: Yup.string().required(" "),
        completeVaccinationScheme: Yup.string().required(" "),

    };
    return medicalHistoryValidation;
}

export default MedicalHistoryValidation;
