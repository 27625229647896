import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import Row from './MedicalRecordsComponents/Row'
import CheckBox from './MedicalRecordsComponents/CheckBox'
import SelectionBox from './MedicalRecordsComponents/SelectionBox'
import Tooth from './MedicalRecordsComponents/Tooth'
import FastTextArea from './MedicalRecordsComponents/FastTextArea'
import ClinicalExamValidation from './Validation/ClinicalExamValidation'
import FastRadialButtons from "./MedicalRecordsComponents/FastRadialButtons";

const clinicalExamValidation = ClinicalExamValidation();

const ClinicalExam = (props, ref) => {

    const setValue = props.setValue;
    const isBlocked = props.blockForm;
    // Tooth values also taken from here.
    const [initialCheckBoxValues, setIntialCheckValues] = useState([]);
    const planoTerminal = [
        'Mesial',
        'Mesial exagerado',
        'Distral',
        'Recto'
    ]

    const refs = {
        'checkBoxCabeza': useRef(null),
        'checkBoxCara': useRef(null),
        'checkBoxCuello': useRef(null),
        'checkBoxManos': useRef(null),
        'checkBoxLabios': useRef(null),
        'checkBoxTronco': useRef(null),
        'checkBoxExtremidades': useRef(null),
        'extraoralesObservaciones': useRef(null),
        'checkBoxEncía y periodonto': useRef(null),
        'checkBoxMucosa bucal': useRef(null),
        'checkBoxFrenillos': useRef(null),
        'checkBoxLengua': useRef(null),
        'checkBoxPiso de boca': useRef(null),
        'checkBoxPaladar': useRef(null),
        'checkBoxOrofaringe': useRef(null),
        'intraoralesObservaciones': useRef(null),
        'craneo': useRef(null),
        'perfil': useRef(null),
        'musculos': useRef(null),
        'planoTerminalDer': useRef(null),
        'planoTerminalIzq': useRef(null),
        'claseOclusion': useRef(null),
        'lineaMedia': useRef(null),
        'sobremordidaVertical': useRef(null),
        'sobremordidaHorizontal': useRef(null),
        'mordidaCruzada': useRef(null)
    };

    useImperativeHandle(ref, () => ({
        async onSubmit() {
            const errors = [];
            for (let ref in refs) {
                try {
                    const eachErrors = await refs[ref].current.onSubmit();
                    for (let err in eachErrors)
                        errors.push(eachErrors[err]);
                } catch (error) {
                    console.log(error);
                }
            }

            return errors;
        },
        setInitialValues(values) {
            setIntialCheckValues(values);
            for (let ref in refs) {
                try {
                    refs[ref].current.setInitialValue(values);
                } catch (error) {
                    console.log(error);
                }
            }
        },
    }));

    // Form validation and submit.

    const clinicalExamPrefix = 'clinicalExam.';

    return (
        <div className={`container ${isBlocked} ? "disable" : ""`}>
            <div className={`form-group ${isBlocked ? "disable" : ""}`}>
                <h4 className='padtop1'>Hallazgos EXTRAORALES</h4>
                <Row>
                    <CheckBox prefix={clinicalExamPrefix} label='Cabeza' setValue={setValue} initialValues={initialCheckBoxValues} />
                    <CheckBox prefix={clinicalExamPrefix} label='Cara' setValue={setValue} initialValues={initialCheckBoxValues} />
                    <CheckBox prefix={clinicalExamPrefix} label='Cuello' setValue={setValue} initialValues={initialCheckBoxValues} />
                    <CheckBox prefix={clinicalExamPrefix} label='Manos' setValue={setValue} initialValues={initialCheckBoxValues} />
                    <CheckBox prefix={clinicalExamPrefix} label='Labios' setValue={setValue} initialValues={initialCheckBoxValues} />
                    <CheckBox prefix={clinicalExamPrefix} label='Tronco' setValue={setValue} initialValues={initialCheckBoxValues} />
                    <CheckBox prefix={clinicalExamPrefix} label='Extremidades' setValue={setValue} initialValues={initialCheckBoxValues} />
                </Row>
                <h5 className=''>Observaciones:</h5>
                <FastTextArea
                    validation={clinicalExamValidation.extraoralesObservaciones}
                    setValue={setValue}
                    id='clinicalExam.extraoralesObservaciones'
                    rows={3}
                    ref={refs['extraoralesObservaciones']}

                />
                <br /><br />
                <hr />
                <h4 className='padtop1'>Hallazgos INTRAORALES</h4>
                <Row>
                    <CheckBox prefix={clinicalExamPrefix} label='Encía y periodonto' setValue={setValue} initialValues={initialCheckBoxValues} />
                    <CheckBox prefix={clinicalExamPrefix} label='Mucosa bucal' setValue={setValue} initialValues={initialCheckBoxValues} />
                    <CheckBox prefix={clinicalExamPrefix} label='Frenillos' setValue={setValue} initialValues={initialCheckBoxValues} />
                    <CheckBox prefix={clinicalExamPrefix} label='Lengua' setValue={setValue} initialValues={initialCheckBoxValues} />
                    <CheckBox prefix={clinicalExamPrefix} label='Piso de boca' setValue={setValue} initialValues={initialCheckBoxValues} />
                    <CheckBox prefix={clinicalExamPrefix} label='Paladar' setValue={setValue} initialValues={initialCheckBoxValues} />
                    <CheckBox prefix={clinicalExamPrefix} label='Orofaringe' setValue={setValue} initialValues={initialCheckBoxValues} />
                    <CheckBox prefix={clinicalExamPrefix} label='Vías aéreas' setValue={setValue} initialValues={initialCheckBoxValues} />
                </Row>
                <h5 className=''>Observaciones:</h5>
                <FastTextArea
                    validation={clinicalExamValidation.intraoralesObservaciones}
                    setValue={setValue}
                    id='clinicalExam.intraoralesObservaciones'
                    rows={3}
                    ref={refs['intraoralesObservaciones']}
                />
                <br /><br />
                <hr />
                <h4 className='padtop1'>ANÁLISIS DE LA OCLUSIÓN</h4>
                <h5 className='padtop1'>Tipo facial</h5>
                <hr className='w75' />
                <Row>
                    <h5 className='padsides1 w25'>Cráneo:</h5>
                    <FastRadialButtons
                        setValue={setValue}
                        id='clinicalExam.cráneo'
                        options={['Doliocefálico', 'Mesocefálico', 'Braquicefálico']}
                        ref={refs['craneo']}
                        name='cráneo'
                    />
                </Row>
                <hr className='w75' />
                <Row>
                    <h5 className='padsides1 w25'>Perfil:</h5>
                    <FastRadialButtons
                        setValue={setValue}
                        id='clinicalExam.perfil'
                        options={['Cóncavo', 'Convexo', 'Recto']}
                        ref={refs['perfil']}
                        name='perfil'
                    />
                </Row>
                <hr className='w75' />
                <Row>
                    <h5 className='padsides1 w25'>Músculos:</h5>
                    <FastRadialButtons
                        setValue={setValue}
                        id='clinicalExam.musculos'
                        options={['Hipotónicos', 'Hipertónicos']}
                        ref={refs['musculos']}
                        name='musculos'
                    />
                </Row>
                <hr className='w75' />
                <div>
                    <h5 className='padsides1'>Plano terminal:</h5>
                    <Row>
                        <div className='marsides1'>
                            <label className='padright05'>DERECHO</label>
                            <SelectionBox
                                items={planoTerminal}
                                id='clinicalExam.planoTerminalDer'
                                setValue={setValue}
                                ref={refs['planoTerminalDer']}
                            />
                        </div>
                        <div className='marsides1'>
                            <label className='padright05'>IZQUIERDO</label>
                            <SelectionBox
                                items={planoTerminal}
                                id='clinicalExam.planoTerminalIzq'
                                setValue={setValue}
                                ref={refs['planoTerminalIzq']}
                            />
                        </div>
                    </Row>
                </div>
                <hr className='w75' />
                <label className='padsides1 size5'>Clase de oclusión:</label>
                <div className='marsides5'>
                    <SelectionBox
                        items={['I', 'II', 'III']}
                        id='clinicalExam.claseOclusion'
                        setValue={setValue}
                        ref={refs['claseOclusion']}
                    />
                </div>
                <hr className='w75' />
                <Row>
                    <h5 className='padsides1 w25'>Línea media:</h5>
                    <FastRadialButtons
                        setValue={setValue}
                        id='clinicalExam.lineaMedia'
                        options={['Normal', 'Desviada izquierda', 'Desviada derecha']}
                        ref={refs["lineaMedia"]}
                        name='lineaMedia'
                    />
                </Row>
                <hr className='w75' />
                <Row>
                    <h5 className='padsides1 w25'>Sobremordida vertical:</h5>
                    <FastRadialButtons
                        setValue={setValue}
                        id='clinicalExam.sobremordidaVertical'
                        options={['Normal', 'Abierto', 'Profunda']}
                        ref={refs["sobremordidaVertical"]}
                        name='sobremordidaVertical'
                    />
                </Row>
                <hr className='w75' />
                <Row>
                    <h5 className='padsides1 w25'>Sobremordida horizontal:</h5>
                    <FastRadialButtons
                        setValue={setValue}
                        id='clinicalExam.sobremordidaHorizontal'
                        options={['Normal', 'Borde a borde']}
                        ref={refs["sobremordidaHorizontal"]}
                        name='sobremordidaHorizontal'
                    />
                </Row>
                <hr className='w75' />
                <Row>
                    <h5 className='padsides1 w25'>Mordida cruzada:</h5>
                    <FastRadialButtons
                        setValue={setValue}
                        id='clinicalExam.mordidaCruzada'
                        options={['Anterior', 'Posterior a borde', 'Unilateral', 'Bilateral']}
                        name='mordidaCruzada'
                        ref={refs["mordidaCruzada"]}
                    />
                </Row>
                <br /><br />
                <hr />
                <h4 className='padtop1' style={{ fontWeight: "bold" }}>ODONTOGRAMA DIAGNÓSTICO</h4>
                <div className="flex flex-wrap flex-just-space-even" style={{ fontWeight: "bold" }}>
                    <label>c Caries</label>
                    <label>t Tratamiento previo</label>
                    <label>✓ Sana</label>
                    <label>? Ausente</label>
                    <label>z Fractura</label>
                    <label>* Movilidad</label>
                </div>

                <div className='flex flex-just-space-center flex-wrap'>
                    <div className='flex flex-column'>
                        <div className='flex flex-just-space-center'>
                            <Tooth number='18' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='17' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='16' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='15' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='14' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='13' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='12' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='11' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                        </div>
                        <br />
                        <div className='flex flex-just-space-center'>
                            <Tooth number='48' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='47' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='46' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='45' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='44' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='43' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='42' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='41' setValue={setValue} initialValues={initialCheckBoxValues} />
                        </div>
                    </div>
                    <span className='w3 hide-on-narrow'></span>
                    <div className='flex flex-column'>
                        <div className='flex flex-just-space-center'>
                            <Tooth number='21' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='22' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='23' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='24' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='25' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='26' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='27' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='28' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                        </div>
                        <br />
                        <div className='flex flex-just-space-center'>
                            <Tooth number='31' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='32' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='33' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='34' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='35' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='36' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='37' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='38' setValue={setValue} initialValues={initialCheckBoxValues} />
                        </div>
                    </div>
                </div>

                <div className='flex flex-just-space-center flex-wrap'>
                    <div className='flex flex-column'>
                        <div className='flex flex-just-space-center'>
                            <Tooth number='55' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='54' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='53' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='52' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='51' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                        </div>
                        <br />
                        <div className='flex flex-just-space-center'>
                            <Tooth number='85' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='84' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='83' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='82' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='81' setValue={setValue} initialValues={initialCheckBoxValues} />
                        </div>
                    </div>
                    <span className='w3 hide-on-narrow'></span>
                    <div className='flex flex-column'>
                        <div className='flex flex-just-space-center'>
                            <Tooth number='61' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='62' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='63' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='64' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='65' top='yes' setValue={setValue} initialValues={initialCheckBoxValues} />
                        </div>
                        <br />
                        <div className='flex flex-just-space-center'>
                            <Tooth number='71' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='72' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='73' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='74' setValue={setValue} initialValues={initialCheckBoxValues} />
                            <Tooth number='75' setValue={setValue} initialValues={initialCheckBoxValues} />
                        </div>
                    </div>
                </div>
                <br /><br />
                <hr />
            </div>
        </div>
    );
}

export default forwardRef(ClinicalExam);
