export function getDescription(category){
  switch(category){
    case "Clínica integral en adultos y niños":
      return "Descripción de clínica integral en adultos y niños";
    case "Prótesis":
      return "Descripción de Prótesis";
    case "Cirugía maxilofacial":
      return "Descripción de Cirugía maxilofacial"
    case "Tratamiento restaurativos":
      return "Descripción de Tratamiento restaurativos";
    case "Endodoncia":
      return "Descripción de Endodoncia";
    case "Ortodoncia":
      return "Descripción de Ortodoncia";
    case "Periodoncia":
      return "Descripción de Periodoncia"
    case "Elige...":
      return "";
    default:
      console.error("Category without any description, please check the file utils/categoriesDescription");
      return "";
  }
}