import * as Yup from 'yup';


const ClinicalExamValidation = () => {
    const clinicalExamValidation = {
        extraoralesObservaciones: Yup.string(),
        intraoralesObservaciones: Yup.string(),
        craneo: Yup.string().required(" "),
        perfil: Yup.string().required(" "),
        musculos: Yup.string().required(" "),
        lineaMedia: Yup.string().required(" "),
        sobremordidaVertical: Yup.string().required(" "),
        sobremordidaHorizontal: Yup.string().required(" "),
        mordidaCruzada: Yup.string().required(" "),
    }

    return clinicalExamValidation;
}

export default ClinicalExamValidation;