import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useState,
} from "react";
import SelectionBox from "./MedicalRecordsComponents/SelectionBox";
import FastTextInput from "./MedicalRecordsComponents/FastTextInput";
import FastTextArea from "./MedicalRecordsComponents/FastTextArea";
import CheckBox from "./MedicalRecordsComponents/CheckBox";
import FastRadialButtons from "./MedicalRecordsComponents/FastRadialButtons";
import MedicalHistoryValidation from "./Validation/MedicalHistoryValidation";

const medicalHistoryValidation = MedicalHistoryValidation();

const History = (props, ref) => {
  const { blockForm, setValue, block } = props;

  const [initialCheckBoxValues, setIntialCheckValues] = useState([]);
  // const [readOnly, setReadOnly] = useState(false);
  const [readOnly,] = useState(false);
  const [prevObservations, setPrevObservations] = useState([]);

  useImperativeHandle(ref, () => ({
    async onSubmit() {
      const errors = [];
      for (var ref in refs) {
        try {
          const eachErrors = await refs[ref].current.onSubmit();
          for (var err in eachErrors)
            errors.push(eachErrors[err]);
        } catch (error) {
          console.log(ref);
        }
      }
      return errors;
    },
    setInitialValues(values) {
      setIntialCheckValues(values);
      // setReadOnly(true);

      if (values["medicalHistory.observations"]) {
        setPrevObservations(values["medicalHistory.observations"].split('\n'));
      }

      for (var ref in refs) {
        try {
          refs[ref].current.setInitialValue(values);
        } catch (error) {
          console.log(ref);
        }
      }
    },
  }));



  const refs = {
    familyMedic: useRef(null),
    institutionName: useRef(null),
    institutionAddress: useRef(null),
    institutionPhone: useRef(null),
    gestationTime: useRef(null),
    birthType: useRef(null),
    birthHeight: useRef(null),
    birthWeight: useRef(null),
    actualHeight: useRef(null),
    actualWeight: useRef(null),
    bloodTransfusion: useRef(null),
    actualTreatment: useRef(null),
    observations: useRef(null),
    asaPatient: useRef(null),
    bloodType: useRef(null),
    bloodRh: useRef(null),
    vaccinesVaccinationCard: useRef(null),
    vaccinesCompleteVaccinationScheme: useRef(null),
  };
  const isBlocked = true;
  return (
    <div className="container">
      <div className={`form-group ${isBlocked && block ? "disable" : ""}`}>
        <div className="form-group">
          <label htmlFor="inputNumbreMedico">
            Nombre del pediatra o médico
          </label>
          <div>
            <FastTextInput
              validation={medicalHistoryValidation.familyMedic}
              setValue={setValue}
              id="medicalHistory.familyMedic"
              ref={refs["familyMedic"]}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group  col-md-4">
            <label htmlFor="inputInstitucion">Institución</label>
            <FastTextInput
              validation={medicalHistoryValidation.institution.name}
              setValue={setValue}
              id="medicalHistory.institution.name"
              ref={refs["institutionName"]}
            />
          </div>
          <div className="form-group  col-md-6">
            <label htmlFor="inputDireccion">Dirección</label>
            <FastTextInput
              validation={medicalHistoryValidation.institution.address}
              setValue={setValue}
              id="medicalHistory.institution.address"
              ref={refs["institutionAddress"]}
            />
          </div>
          <div className="form-group col-md-2">
            <label htmlFor="inputTelefono">Teléfono</label>
            <FastTextInput
              validation={medicalHistoryValidation.institution.phone}
              setValue={setValue}
              id="medicalHistory.institution.phone"
              ref={refs["institutionPhone"]}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group  col-md-4">
            <label htmlFor="inputGestacion">Tiempo de gestación</label>
            <FastTextInput
              validation={medicalHistoryValidation.gestationTime}
              setValue={setValue}
              id="medicalHistory.gestationTime"
              ref={refs["gestationTime"]}
            />
          </div>
          <div className="form-group  col-md-4">
            <label htmlFor="inputParto">Tipo de parto</label>
            <FastTextInput
              validation={medicalHistoryValidation.birthType}
              setValue={setValue}
              id="medicalHistory.birthType"
              ref={refs["birthType"]}
            />
          </div>
          <div className="form-group col-md-2">
            <label htmlFor="inputTalla">Talla al nacer</label>
            <FastTextInput
              validation={medicalHistoryValidation.birthHeight}
              setValue={setValue}
              id="medicalHistory.birthHeight"
              ref={refs["birthHeight"]}
            />
          </div>
          <div className="form-group col-md-2">
            <label htmlFor="inputPeso">Peso al nacer</label>
            <FastTextInput
              validation={medicalHistoryValidation.birthWeight}
              setValue={setValue}
              id="medicalHistory.birthWeight"
              ref={refs["birthWeight"]}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group  col-md-4">
            <label htmlFor="inputTallaActual">Talla actual</label>
            <FastTextInput
              validation={medicalHistoryValidation.actualHeight}
              setValue={setValue}
              id="medicalHistory.actualHeight"
              ref={refs["actualHeight"]}
            />
          </div>
          <div className="form-group  col-md-4">
            <label htmlFor="inputPesoActual">Peso Actual</label>
            <FastTextInput
              validation={medicalHistoryValidation.actualWeight}
              setValue={setValue}
              id="medicalHistory.actualWeight"
              ref={refs["actualWeight"]}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputTransfusion">
              Ha recibido transfusión o algun producto sanguíneo
            </label>
            <div className="form-check form-check-inline" id="inputTransfusion">
              <FastRadialButtons
                setValue={setValue}
                id="medicalHistory.bloodTransfusion"
                options={["Si", "No"]}
                ref={refs["bloodTransfusion"]}
                name="bloodTransfusion"
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="inputRecibioTratamiento">
            Recibió o esta recibiendo algún tratamiento
          </label>
          <div>
            <FastTextInput
              validation={medicalHistoryValidation.actualTreatment}
              setValue={setValue}
              id="medicalHistory.actualTreatment"
              ref={refs["actualTreatment"]}
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="diagnosticoCondiciones">
            Ha sido diagnosticado con alguna de las siguientes condiciones:
          </label>
          <br />
          <br />
          <div className="flex flex-wrap">
            <div className="form-check flex flex-column">
              <CheckBox
                prefix={"medicalHistory.diagnosedConditions."}
                label="Anemia"
                setValue={setValue}
                initialValues={initialCheckBoxValues}
              />
              <CheckBox
                prefix={"medicalHistory.diagnosedConditions."}
                label="Artritis"
                setValue={setValue}
                initialValues={initialCheckBoxValues}
              />
              <CheckBox
                prefix={"medicalHistory.diagnosedConditions."}
                label="Asma"
                setValue={setValue}
                initialValues={initialCheckBoxValues}
              />
              <CheckBox
                prefix={"medicalHistory.diagnosedConditions."}
                label="Cáncer"
                setValue={setValue}
                initialValues={initialCheckBoxValues}
              />
              <CheckBox
                prefix={"medicalHistory.diagnosedConditions."}
                label="Convulsiones"
                setValue={setValue}
                initialValues={initialCheckBoxValues}
              />
              <CheckBox
                prefix={"medicalHistory.diagnosedConditions."}
                label="Diabetes"
                setValue={setValue}
                initialValues={initialCheckBoxValues}
              />
            </div>
            <div className="form-check flex flex-column">
              <CheckBox
                prefix={"medicalHistory.diagnosedConditions."}
                label="Enf. Cardiacas"
                setValue={setValue}
                initialValues={initialCheckBoxValues}
              />
              <CheckBox
                prefix={"medicalHistory.diagnosedConditions."}
                label="Fibrosis Quística"
                setValue={setValue}
                initialValues={initialCheckBoxValues}
              />
              <CheckBox
                prefix={"medicalHistory.diagnosedConditions."}
                label="Hemofilia"
                setValue={setValue}
                initialValues={initialCheckBoxValues}
              />
              <CheckBox
                prefix={"medicalHistory.diagnosedConditions."}
                label="Hepatitis"
                setValue={setValue}
                initialValues={initialCheckBoxValues}
              />
              <CheckBox
                prefix={"medicalHistory.diagnosedConditions."}
                label="Varicela"
                setValue={setValue}
                initialValues={initialCheckBoxValues}
              />
              <CheckBox
                prefix={"medicalHistory.diagnosedConditions."}
                label="HIV"
                setValue={setValue}
                initialValues={initialCheckBoxValues}
              />
            </div>
            <div className="form-check flex flex-column">
              <CheckBox
                prefix={"medicalHistory.diagnosedConditions."}
                label="Neumonia"
                setValue={setValue}
                initialValues={initialCheckBoxValues}
              />
              <CheckBox
                prefix={"medicalHistory.diagnosedConditions."}
                label="Parálisis Cerebral"
                setValue={setValue}
                initialValues={initialCheckBoxValues}
              />
              <CheckBox
                prefix={"medicalHistory.diagnosedConditions."}
                label="Problemas Auditivos"
                setValue={setValue}
                initialValues={initialCheckBoxValues}
              />
              <CheckBox
                prefix={"medicalHistory.diagnosedConditions."}
                label="Problemas en los ojos"
                setValue={setValue}
                initialValues={initialCheckBoxValues}
              />
              <CheckBox
                prefix={"medicalHistory.diagnosedConditions."}
                label="Problemas Ortopedicos"
                setValue={setValue}
                initialValues={initialCheckBoxValues}
              />
            </div>
            <div className="form-check flex flex-column">
              <CheckBox
                prefix={"medicalHistory.diagnosedConditions."}
                label="Problemas Renales"
                setValue={setValue}
                initialValues={initialCheckBoxValues}
              />
              <CheckBox
                prefix={"medicalHistory.diagnosedConditions."}
                label="Retraso psicomotor"
                setValue={setValue}
                initialValues={initialCheckBoxValues}
              />
              <CheckBox
                prefix={"medicalHistory.diagnosedConditions."}
                label="Sindromes"
                setValue={setValue}
                initialValues={initialCheckBoxValues}
              />
              <CheckBox
                prefix={"medicalHistory.diagnosedConditions."}
                label="Problemas Conductuales"
                setValue={setValue}
                initialValues={initialCheckBoxValues}
              />
            </div>
          </div>
        </div>
      </div>
      {readOnly ?
        <>
          <div className={`form-group ${blockForm && block ? "disable" : ""}`}>
            <label> Observaciones Anteriores </label>
            {prevObservations.map(obs => {
              return (
                <div key={Math.random()}>
                  <label style={{ color: "#e74c3c" }}>
                    -{obs}
                  </label>
                </div>
              );
            })}
          </div>
          <div className={`form-group ${isBlocked && block ? "disable" : ""}`}>
            <label htmlFor="observaciones">Agregar Observación</label>
            <FastTextArea
              validation={medicalHistoryValidation.observations}
              setValue={setValue}
              id="medicalHistory.observations"
            // ref={refs["observations"]}
            />
          </div>
        </>
        :
        <div className={`form-group ${blockForm && block ? "disable" : ""}`}>
          <label htmlFor="observaciones">Observaciones</label>
          <FastTextArea
            validation={medicalHistoryValidation.observations}
            setValue={setValue}
            id="medicalHistory.observations"
            ref={refs["observations"]}
          />
        </div>
      }
      <div className={`form-group ${blockForm && block ? "disable" : ""}`}>
        <div className="form-row">
          <div className="form-group  col-md-4">
            <label htmlFor="asa">Paciente ASA</label>
            <SelectionBox
              validation={medicalHistoryValidation.asa}
              items={["I", "II", "III", "IV", "V"]}
              id="medicalHistory.asaPatient"
              setValue={setValue}
              ref={refs["asaPatient"]}
            />
          </div>
          <div className="form-group  col-md-4">
            <label htmlFor="grupoSanguineo">Grupo Sanguíneo</label>
            <SelectionBox
              validation={medicalHistoryValidation.bloodType}
              items={["A", "B", "AB", "O"]}
              id="medicalHistory.blood.type"
              setValue={setValue}
              ref={refs["bloodType"]}
            />
          </div>
          <div className="form-group  col-md-4">
            <label htmlFor="factorRh">Factor RH</label>
            <SelectionBox
              validation={medicalHistoryValidation.bloodRh}
              items={["+", "-"]}
              id="medicalHistory.blood.rh"
              setValue={setValue}
              ref={refs["bloodRh"]}
            />
          </div>
        </div>
        <div>
          <label htmlFor="inputCartillaVacunacion">
            Cartilla de Vacunación
          </label>
          <FastRadialButtons
            setValue={setValue}
            id="medicalHistory.vaccines.vaccinationCard"
            options={["Si", "No"]}
            ref={refs["vaccinesVaccinationCard"]}
            name="vaccinesVaccinationCard"
          />
        </div>
        <div>
          <label htmlFor="inputEsquemaVacunacion">
            Esquema de vacunación completo
          </label>
          <FastRadialButtons
            setValue={setValue}
            id="medicalHistory.vaccines.completeVaccinationScheme"
            options={["Si", "No"]}
            ref={refs["vaccinesCompleteVaccinationScheme"]}
            name="vaccinesCompleteVaccinationScheme"
          />
        </div>
      </div>

      <hr />
    </div>
  );
};

export default forwardRef(History);
