import * as Yup from "yup";

export default function TreatmentValidation(name) {
    const treatmentValidation={
        date: Yup.date().required(' '),
        specialty: Yup.string().required(' '),
        indications:Yup.string().max(999, "Demasiado largo"),
        studentName:Yup.string().required(' ').matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ][a-zA-ZÀ-ÖØ-öø-ÿ\s]*$/, "Contiene caracteres no permitidos").min(1).max(99, "Demasiado largo"),
        doctorName:Yup.string().required(' ').matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ][a-zA-ZÀ-ÖØ-öø-ÿ\s]*$/, "Contiene caracteres no permitidos").min(1).max(99, "Demasiado largo"),
        treatmentTooth17:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth27:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth16:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth26:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth15:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth25:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth14:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth24:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth13:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth23:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth12:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth22:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth11:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth21:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth55:Yup.string().max(99, "Demasiado largo"),
        treatmentToot65:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth54:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth64:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth53:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth63:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth52:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth62:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth51:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth61:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth85:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth75:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth84:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth74:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth83:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth73:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth82:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth72:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth81:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth71:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth47:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth37:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth46:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth36:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth45:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth35:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth44:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth34:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth43:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth33:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth42:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth32:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth41:Yup.string().max(99, "Demasiado largo"),
        treatmentTooth31:Yup.string().max(99, "Demasiado largo"),
    }
    return treatmentValidation;
}