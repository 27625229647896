import { React, useEffect, useState, useMemo } from "react";

const Tooth = (props) => {
    const { number, setValue,top } = props;
    const initialValue = props.initialValues ? props.initialValues[`clinicalExam.tooth.t${number}`] ?? '✓' : '✓';

    // const toothStates = [
    //     '✓', // Sana
    //     'c', //caries
    //     't', //tratamientoPrevio
    //     '?', //Ausente
    //     'z', // Fracture
    //     '*' //Movilidad
    // ]

    const toothStates = useMemo(() => {
        return [
            '✓', // Sana
            'c', //caries
            't', //tratamientoPrevio
            '?', //Ausente
            'z', // Fracture
            '*' //Movilidad
        ]
      }, []);

    const [state, setState] = useState(0);

    useEffect(() => {
        var isMounted = true;
        let idx = toothStates.indexOf(initialValue);

        if (isMounted)
            setState(idx);

        return () => { isMounted = false };
    }, [initialValue, toothStates]);

    function changeState() {
        const index = state < toothStates.length - 1 ? state + 1 : 0;
        setState(index);
        setValue(toothStates[index], `clinicalExam.tooth.t${number}`);
    }

    if (top) {
        return (
            <div className='toothOuter martop1 marsides01' onClick={changeState}>
                {number}
                <div className='toothInner crossed'>
                    <div className='toothMostInner flex flex-align-items-center flex-just-space-center'>
                        {toothStates[state]}
                    </div>
                </div>
            </div>
        );
    }

    // If not, bottom
    return (
        <div className='toothOuter marbottom1 marsides01' onClick={changeState}>
            <div className='toothInner crossed'>
                <div className='toothMostInner flex flex-align-items-center flex-just-space-center'>
                    <div className='toothState'>
                        {toothStates[state]}
                    </div>
                </div>
            </div>
            {number}
        </div>
    );

}

export default Tooth;
