import '../../../Styles/ProcedureForm.css';
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import PROCEDURE_SERVICE from '../../../services/procedure';
import CLINIC_SERVICE from '../../../services/clinic';
import { UserContext } from "../../../context/context";

import Layout from '../../Layout/Layout';

const ProcedureForm = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [procedure, setProcedure] = useState({});

  const [, setClinics] = useState([]);
  const history = useHistory();
  const userContext = useContext(UserContext);

  let categories = [
    'Clínica integral en adultos y niños', 
    'Cirugía maxilofacial', 
    'Periodoncia (limpieza)', 
    'Prótesis', 
    'Odontopediatría', 
    'Tratamiento restaurativos', 
    'Ortopedia', 
    'Ortodoncia', 
    'Endodoncia'
  ];

  const { id } = useParams();

  const clinicService = async () => {
    const { data } = await CLINIC_SERVICE.GET_ALL();
    setClinics(data);
    setIsLoading(false);
  }

  const procedureService = async (id) => {
    const { data } = await PROCEDURE_SERVICE.GET_ONE(id);
    setProcedure(data);
  }

  useEffect(() => {
    setIsLoading(true);
    try {
      clinicService();
    } catch (error) {
      console.log(error);
    }
    try {
      procedureService(id);
    } catch (error) {
      console.log(error);
    }
  }, [setProcedure, id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: procedure.name || "",
      description: procedure.description || "",
      cost: procedure.cost || "",
      duration: procedure.duration || "",
      category: procedure && procedure.category ? procedure.category : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Nombre requerido."),
      description: Yup.string().required("Descripción requerida."),
      cost: Yup.number().required("Costo requerido."),
      duration: Yup.number().required("Duración requerida."),
      // locationId: Yup.string().test("noDefault", "Ubicación requerida.", val => val !== "default").required("Ubicación requerida."),
    }),
    onSubmit: async (values) => {
      try {
        setErrorMessage("");
        let response;
        if (id !== 'new') {
          response = await PROCEDURE_SERVICE.UPDATE(id, {
            name: values.name,
            description: values.description,
            cost: values.cost,
            duration: values.duration,
            category: values.category,
          }
          );
        } else {
          response = await PROCEDURE_SERVICE.CREATE({
            name: values.name,
            description: values.description,
            cost: values.cost,
            duration: values.duration,
            category: values.category,
          });
        }
        if (response?.status === 200) {
          userContext.changeSate();
          history.push("/procedures");
        }
      } catch (error) {
        console.log(error);
        setErrorMessage("Ocurrió un error");
      }
    },
  });

  return (
    <Layout>
      <div className="container-sm pt-3 mt-3">
        <div className="row px-2">
          <div className="col col-sm-9 col-md-8 col-lg-6 my-3 card mx-auto p-4">
            <h2 className="text-center font-weight-bold mt-1 mb-3">Procedimiento</h2>
            <form>
              <div className="form-group">
                <label htmlFor="name">Nombre</label>
                <input
                  type="text"
                  id="name"
                  placeholder="Nombre"
                  className={`form-control form-control-lg ${formik.errors.name && formik.touched.name
                      ? "is-invalid"
                      : ""
                    }`}
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.name && formik.touched.name && (
                  <div className="invalid-feedback">{formik.errors.name}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="description">Descripción</label>
                <textarea
                  type="text"
                  id="description"
                  placeholder="Descripción"
                  className={`form-control form-control-lg ${formik.errors.description && formik.touched.description
                      ? "is-invalid"
                      : ""
                    }`}
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.description && formik.touched.description && (
                  <div className="invalid-feedback">{formik.errors.description}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="cost">Costo</label>
                <input
                  type="cost"
                  id="cost"
                  placeholder="Costo"
                  className={`form-control form-control-lg ${formik.errors.cost && formik.touched.cost
                      ? "is-invalid"
                      : ""
                    }`}
                  name="cost"
                  value={formik.values.cost}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.cost && formik.touched.cost && (
                  <div className="invalid-feedback">{formik.errors.cost}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="duration">Duración (minutos)</label>
                <input
                  type="duration"
                  id="duration"
                  placeholder="Duración (minutos)"
                  className={`form-control form-control-lg ${formik.errors.duration && formik.touched.duration
                      ? "is-invalid"
                      : ""
                    }`}
                  name="duration"
                  value={formik.values.duration}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.duration && formik.touched.duration && (
                  <div className="invalid-feedback">{formik.errors.duration}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="category">Categoría del procedimiento</label>
                <select
                  name="category"
                  id="category"
                  className={`form-control form-control-lg ${formik.errors.category && formik.touched.category
                      ? "is-invalid"
                      : ""
                    }`}
                  onChange={formik.handleChange}
                  value={formik.values.category ? formik.values.category : "default"}
                >
                  <option
                    value="default"
                    key="default"
                    disabled
                  >
                    Selecciona una categoría
                  </option>
                  { categories.map((category) => {
                      return !isLoading ? (
                        <option value={category} key={category}>
                          {category}
                        </option>
                      ): null; }
                  )}


                </select>

                {formik.errors.category && formik.touched.category && (
                  <div className="invalid-feedback">{formik.errors.duration}</div>
                )}
              </div>

              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-primary w-100 m-0"
                  onClick={formik.handleSubmit}
                >
                  Guardar
                </button>
              </div>
              {errorMessage && (
                <div className="text-danger">{errorMessage}</div>
              )}
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProcedureForm;
