import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import EvolutionCards from "./MedicalRecordsComponents/EvolutionCards";
import FastTextInput from "./MedicalRecordsComponents/FastTextInput";
import FastDatePicker from "./MedicalRecordsComponents/FastDatePicker";
import ModalSign from "./MedicalRecordsComponents/ModalSign";
import EvolutionNotesValidation from "./Validation/EvolutionNotesValidation";
import FastTextArea from "./MedicalRecordsComponents/FastTextArea";

import flat from 'flat'

const EvolutionNotes = (props, ref) => {
  const evolutionNotesValidation = EvolutionNotesValidation();

  const [doctorSignature, setDoctorSignature] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const signatureCanvas = useRef({});

  const [numNotes, setNumNote] = useState(0);
  const [notes, setNotes] = useState([]);

  const clear = () => {
    signatureCanvas.current.clear();
  };
  const save = () => {
    setImageUrl(signatureCanvas.current.toDataURL());
    setDoctorSignature(true);
    setValue(signatureCanvas.current.toDataURL(), 'doctor.signature');
  };

  const isBlocked = props.blockForm;

  function addNote(e) {
    e.preventDefault();
    props.setValue(values, `evolutionNotes.${numNotes}`);
    notes.push(values);
    setValues({});
    setNumNote(numNotes + 1);
  }

  const [values, setValues] = useState({});
  function setValue(value, id) {
    values[id] = value;
  }

  const [initialVals, setInitialVal] = useState({});

  useImperativeHandle(
    ref,
    () => ({
      onSubmit() {
      },
      setInitialValues(values) {
        setInitialVal(values);
      }
    }),
    []
  );

  useEffect(() => {
    let count = 0;
    const note = {};
    while (initialVals[`evolutionNotes.${count}.date`]) {
      note[`evolutionNotes.${count}.date`] = initialVals[`evolutionNotes.${count}.date`];
      note[`evolutionNotes.${count}.description`] = initialVals[`evolutionNotes.${count}.description`];
      note[`evolutionNotes.${count}.doctor.name`] = initialVals[`evolutionNotes.${count}.doctor.name`];
      note[`evolutionNotes.${count}.doctor.signature`] = initialVals[`evolutionNotes.${count}.doctor.signature`];
      note[`evolutionNotes.${count}.fileNumber`] = initialVals[`evolutionNotes.${count}.fileNumber`];
      note[`evolutionNotes.${count}.procedure`] = initialVals[`evolutionNotes.${count}.procedure`];
      note[`evolutionNotes.${count}.student.name`] = initialVals[`evolutionNotes.${count}.student.name`];

      count = count + 1;
    }

    setNotes((flat.unflatten(note))['evolutionNotes'] ?? []);


  }, [initialVals]);

  return (
    <div className="container">
      <div className={`form-row ${isBlocked ? "disable" : ""}`} >
        <div className={`form-group col-md-8`}>
          <label htmlFor="procedimiento">Procedimiento</label>
          <FastTextInput
            validation={evolutionNotesValidation.procedure}
            setValue={setValue}
            id="procedure"
          />
        </div>
        <div className="form-group col-md-4">
          <label htmlFor="fechaProcedimiento">Fecha</label>
          <FastDatePicker
            id="date"
            setValue={setValue}
          />
        </div>
      </div>
      <div className={`form-row ${isBlocked ? "disable" : ""}`} >
        <div className="form-group col-md-8">
          <label htmlFor="doctor">Doctor</label>
          <FastTextInput
            validation={evolutionNotesValidation.doctor}
            setValue={setValue}
            id="doctor.name"
          />
        </div>
        <div className="form-group col-md-4">
          <label htmlFor="doctor">Firma</label>
          {!doctorSignature ? (
            <>
              <ModalSign
                refer={signatureCanvas}
                clear={clear}
                save={save}
                id="doctorSignature"
              />
            </>
          ) : (
            <img src={imageUrl} alt="evolution notes" style={{ width: "200px" }} />
          )}
        </div>
      </div>
      <div className={`form-row ${isBlocked ? "disable" : ""}`} >
        <div className="form-group col-md-8">
          <label htmlFor="student">Alumno</label>
          <FastTextInput
            validation={evolutionNotesValidation.student}
            setValue={setValue}
            id="student.name"
          />
        </div>
        <div className="form-group col-md-4">
          <label htmlFor="folioProcedimiento">Folio</label>
          <FastTextInput
            validation={evolutionNotesValidation.fileNumber}
            setValue={setValue}
            id="fileNumber"
          />
        </div>
      </div>
      <div className={`form-group flex flex-column marbottom1 ${isBlocked ? "disable" : ""}`}>
        <label>Descripción</label>
        <FastTextArea setValue={setValue} id="description" rows={3} />
      </div>
      <button type='button' className={`btn btn-primary  ${isBlocked ? "disable" : ""}`} onClick={addNote}>
        Agregar
      </button>
      <div className="container">
        {notes?.map(note => {
          return (
            <EvolutionCards
              key={Math.random()}
              procedureName={note.procedure}
              date={new Date(note.date)}
              docName={note['doctor.name']}
              studentName={note['student.name']}
              note={note.description}
            />
          );
        })}
      </div>
    </div>
  );
};

export default forwardRef(EvolutionNotes);
