import React, { useState } from "react";
import '../Styles/forms.css'
import { useHistory, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";

import AUTH_SERVICE from "../services/auth";
import PATIENT_SERVICE from "../services/patient";
import TutorRegister from './RegisterPatient/TutorRegister/TutorRegister';
import Layout from "../components/Layout/Layout";

registerLocale("es", es);

const countryCodes = [
  {
    value: "+52",
    label: "+52 (México)",
  },
  {
    value: "+1",
    label: "+1 (EUA)",
  },
];

const Register = () => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [date, setDate] = useState("");
  const [checkedState, setCheckedState] = useState(
    [
      {
        method: "Email",
        label: "Correo electrónico",
        state: true,
      }, {
        method: "SMS",
        label: "Mensaje de texto",
        state: false,
      }, {
        method: "WhatsApp",
        label: "WhatsApp",
        state: false,
      }]);

  const [contactMethods,setContactMethods] = useState({
    email:true,
    sms:false,
    whatsapp:false,

  });


  const getAge = (birthdayValue) => {
    const now = new Date();
    const birthDate = new Date(birthdayValue);
    let age = now.getFullYear() - birthDate.getFullYear();
    const month = now.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && now.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }


  //Handle contact methods
  const handleInputChange = (event) => {
    let value = (event.target.value).toLowerCase();

    if (value === 'sms') {
      contactMethods.sms = true
      contactMethods.whatsapp = false
    } else {
      contactMethods.whatsapp = true
      contactMethods.sms = false
    }

    setContactMethods(contactMethods);
    setCheckedState(checkedState);
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      birthday: " ",
      countryCode: "+52",
      cellphone: "",
      email: "",
      password: "",
      confirmPassword: "",
      //ContactMethod
      contactMethods,
      // Tutor values
      hasTutor: false,
      tutorName: '',
      tutorPhone: '',
      tutorEmail: ''
      // End tutor values
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Nombre(s) es requerido."),
      surname: Yup.string().required("Apellido(s) es requerido."),
      birthday: Yup.string()
        .test("req-birthday", "Una fecha de nacimiento es requerida.", val => val !== " "),
      countryCode: Yup.string().required("Un codigo de país es requerido."),
      cellphone: Yup.string()
        .required("Un teléfono es requerido.")
        .matches(/^[0-9]{10}$/gi, "Teléfeno debe ser 10 digitos de largo."),
      email: Yup.string()
        .email("Correo electrónico invalido.")
        .required("Un correo electrónico es requerido."),
      password: Yup.string()
        .required("Una contraseña es requerida.")
        .min(6, "La contraseña debe tener minimo 6 caracteres.")
        .matches(/[A-Z]+/g, "La contraseña debe tener una letra mayúscula.")
        .matches(/[a-z]+/g, "La contraseña debe tener una letra minúscula.")
        .matches(/[0-9]+/g, "La contraseña debe contener mínimo un número.")
        .matches(
          /(\W|_)+/g,
          "La contraseña debe contener mínimo un carácter especial."
        ),
      confirmPassword: Yup.string()
        .required("Este campo es requerido.")
        .oneOf([Yup.ref("password"), ""], "Las contraseñas no son idénticas"),

      // Tutor fields validation
      hasTutor: Yup.boolean()
        .when('birthday', {
          is: (birthday) => { return getAge(birthday) < 18 },
          then: Yup.boolean().oneOf([true], "Menores deben tener un tutor.")
        }),

      tutorName: Yup.string()
        .when('hasTutor', {
          is: true,
          then: Yup.string().required("Nombre(s) es requerido.")
        }),

      tutorPhone: Yup.string()
        .matches(/^[0-9]{10}$/gi, "Teléfeno debe ser 10 digitos de largo.")
        .when('hasTutor', {
          is: true,
          then: Yup.string().required("Un teléfono es requerido.")
        }),

      tutorEmail: Yup.string()
        .email("Correo electrónico invalido.")
        .when('hasTutor', {
          is: true,
          then: Yup.string().required("Un correo electrónico es requerido.")
        }),
      // End tutor fields validation
    }),
    onSubmit: async (values) => {
      values.phone = values.countryCode + '1' + values.cellphone;
      values.age = getAge(values.birthday);
      try {
        setErrorMessage("");
        const { data } = await AUTH_SERVICE.CREATE_PATIENT(values);
        const profileData = {
          idUser: data._id,
          reasonDentisVisit: "",
          generalInfo: {
            name: data.name,
            paternalLastName: data.surname,
            maternalLastName: "",
            birthday: data.birthday,
            age: data.age,
            gender: "",
            birthplace: {
              city: "",
              state: ""
            },
            contactMethods,
            mothersName: "",
            fathersName: "",
            address: {
              street: "",
              number: "",
              neighborhood: "",
              postalCode: "",
            },
            phoneNumber: {
              main: data.phone,
              cellphone: "",
              other: ""
            },
          },
          medicalAlert: "",
          medicalHistory: {
          },
          dentalHistory: {}
        }
        await PATIENT_SERVICE.CREATE(profileData);
        history.push("/login");
      } catch (error) {
        console.log(error);
        setErrorMessage("Este correo electrónico ya existe");
      }
    },

  });
  return (
    <Layout>
      <div className="container-fluid">
        <div className="row px-2">
          <div className="col col-sm-9 col-md-8 col-lg-6 my-3 card mx-auto pt-4 px-4 pb-2">
            <h2 className="text-center font-weight-bold mt-1 mb-3">¡Registrarse!</h2>
            <form>
              <div className="form-group">
                <label htmlFor="name">* Nombre(s)</label>
                <input
                  type="text"
                  className={`form-control ${formik.errors.name && formik.touched.name
                    ? "is-invalid"
                    : ""
                    }`}
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Nombre"
                />
                {formik.errors.name && formik.touched.name && (
                  <div className="invalid-feedback">{formik.errors.name}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="surname">*Apellido(s)</label>
                <input
                  type="text"
                  className={`form-control ${formik.errors.surname && formik.touched.surname
                    ? "is-invalid"
                    : ""
                    }`}
                  id="surname"
                  name="surname"
                  value={formik.values.surname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Apellido(s)"
                />
                {formik.errors.surname && formik.touched.surname && (
                  <div className="invalid-feedback">
                    {formik.errors.surname}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="birthday">*Fecha de nacimiento</label>
                <br />
                <DatePicker
                  autoComplete={"off"}
                  customInput={
                    <input className="form-control" name="birthday" />
                  }
                  className={`${formik.errors.birthday && formik.touched.birthday
                    ? "is-invalid"
                    : ""
                    }`}
                  locale="es"
                  maxDate={new Date()}
                  showYearDropdown
                  yearDropdownItemNumber={50}
                  scrollableYearDropdown
                  id="birthday"
                  name="birthday"
                  placeholderText="dd/mm/aaaa"
                  dateFormat="dd/MM/yyyy"
                  selected={date}
                  onChange={(rawDate) => {
                    formik.values.birthday = rawDate;
                    setDate(rawDate);
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.birthday && formik.touched.birthday && (
                  <div
                    className=""
                    style={{ color: "#e74a3b", fontSize: "13px" }}
                  >
                    {formik.errors.birthday}
                  </div>
                )}
              </div>
              <div>
                <label htmlFor="cellphone">*Número de teléfono</label>
              </div>
              <div className="form-row">
                <div className="form-group col-md-3">
                  <select
                    className={`form-control custom-select ${formik.errors.countryCode && formik.touched.countryCode
                      ? "is-invalid"
                      : ""
                      }`}
                    name="countryCode"
                    value={formik.values.countryCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {countryCodes.map((cc) => {
                      return (
                        <option key={cc.value} value={cc.value}>
                          {cc.label}
                        </option>
                      );
                    })}
                    <option>...</option>
                  </select>
                  {formik.errors.countryCode && formik.touched.countryCode && (
                    <div className="invalid-feedback">
                      {formik.errors.countryCode}
                    </div>
                  )}
                </div>
                <div className="form-group col-md-9">
                  <input
                    type="tel"
                    className={`form-control cellphone ${formik.errors.cellphone && formik.touched.cellphone
                      ? "is-invalid"
                      : ""
                      }`}
                    id="cellphone"
                    name="cellphone"
                    value={formik.values.cellphone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Número telefónico"
                  />
                  {formik.errors.cellphone && formik.touched.cellphone && (
                    <div className="invalid-feedback">
                      {formik.errors.cellphone}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="inputEmail">*Correo electrónico</label>
                <input
                  type="email"
                  className={`form-control ${formik.errors.email && formik.touched.email
                    ? "is-invalid"
                    : ""
                    }`}
                  placeholder="ejemplo@gmail.com"
                  id="inputEmail"
                  aria-describedby="emailHelp"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.email && (
                  <div className="invalid-feedback">{formik.errors.email}</div>
                )}
                {errorMessage && (
                  <div className="text-danger">{errorMessage}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="password">*Contraseña</label>
                <input
                  type="password"
                  className={`form-control ${formik.errors.password && formik.touched.password
                    ? "is-invalid"
                    : ""
                    }`}
                  id="password"
                  aria-describedby="passwordHelp"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Contraseña"
                />
                {formik.errors.password && formik.touched.password && (
                  <div className="invalid-feedback">
                    {formik.errors.password}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">*Confirmar contraseña</label>
                <input
                  type="password"
                  className={`form-control ${formik.errors.confirmPassword &&
                    formik.touched.confirmPassword
                    ? "is-invalid"
                    : ""
                    }`}
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Confirmar contraseña"
                />
                {formik.errors.confirmPassword &&
                  formik.touched.confirmPassword && (
                    <div className="invalid-feedback">
                      {formik.errors.confirmPassword}
                    </div>
                  )}
              </div>
              <div className="form-group">
                <p>*Métodos de contacto</p>
                <div className="row text-center">
                  {
                    Object.keys(checkedState).map((item, index) => {
                      return index >= 1 && (
                        <div className="form-check col" key={index}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="contactMethods"
                            id={checkedState[item].method}
                            value={checkedState[item].method}
                            onChange={handleInputChange}
                          />
                          <label className="form-check-label" htmlFor={checkedState[item].method}>
                            {checkedState[item].label}
                          </label>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              {/* Tutor section (toogle) */}
              <TutorRegister
                formik={formik}
              />
              {/* End of tutor section */}
              <button
                type="submit"
                className="btn btn-primary btn-block mx-0"
                onClick={formik.handleSubmit}
              >
                Registrarse
              </button>
              <div className="text-left mt-2 mr-2">
                <p>¿Ya tienes una cuenta? <Link to="/login" className="m-0">Inicia sesión aquí!</Link></p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Register;
