import React from "react";
import Layout from "../../components/Layout/Layout";

// import NavBar from "../../components/NavBar/NavBar";
import Vectors from "../../components/Vectors/Vectors";

const UnAuthorized = () => {
  return (
    <Layout>
      <div
        className="d-flex align-items-center justify-content-center p-3 pt-5 mb-2"
        style={{ fontSize: 200 }}
      >
        <Vectors draw="sadFace" width="1em" height="1em" color="currentColor" />
      </div>
      <div className="d-flex align-items-center justify-content-center px-3 pt-5 mb-0 rounded">
        <h1>
          <strong>403: Sin autorización</strong>
        </h1>
      </div>
      <div className=" d-flex align-items-center justify-content-center p-3 pt- rounded">
        <p>
          <strong>
            Su cuenta no tiene los permisos necesarios para acceder a la
            página.
          </strong>
        </p>
      </div>
    </Layout>
  );
};

export default UnAuthorized;
