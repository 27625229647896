// import { Formik } from 'formik';
import React from 'react';
// import {useHistory} from 'react-router-dom';
import * as Yup from "yup";
import { useFormik } from "formik";

// const SearchBar = ({name, baseUrl, onChange, onSubmitParent}) => {
const SearchBar = ({ name, onChange }) => {
  // const history = useHistory();

  const formik = useFormik({
    initialValues: {
      search: '',
    },
    validationSchema: Yup.object({
      search: Yup.string(),
    }),
    onSubmit: async (values) => {
      /* try {
       history.push(`${baseUrl}?search=${values.search}`);
       window.location.reload();
       } catch (error) {
         console.log(error);
       }*/
    },
  });

  return (
    <div className="marsides5 martop2 flex-grow-1">
      <div>{name}</div>
      <input
        className="form-control"
        type="text"
        value={formik.values.searcg}
        name="search"
        onChange={onChange}
        //onSubmit={onSubmitParent}
        placeholder="Buscar"
      />
    </div>
  );
};

export default SearchBar;
