import React from 'react';
import ProcedureForm from '../components/Procedures/ProcedureForm/ProcedureForm';

const EditProcedure = () => {
  return (
    <>
      <ProcedureForm />
    </>
  );
};

export default EditProcedure;
