import axios from '../config/axios';

const PATIENT_SERVICE = {
  GET_ONE: async (id) => {
    return await axios.get(`/patient-profile/${id}`)
  },
  GET_ALL: async (page, limit) => {
    return await axios.get(
      `/patient-profile`,
      {
        params: {
          page,
          limit
        }
      }
    )
  },
  FIND: async (query, page, limit) => {
    return await axios.get(
      `/patient-profile`,
      {
        params: {
          page,
          limit,
          searchParam: query,
        }
      }
    )
  },

  CREATE: async (data) => {
    return await axios.post("/patient-profile", data)
  },

  MODIFY: async (id, data) => {
    return await axios.patch(`/patient-profile/${id}`, data)
  },

  EDIT: async(id,data) =>{
    return await axios.put(`/patient-profile/${id}`, data);
  }
  
}

export default PATIENT_SERVICE;
