import React, { useContext } from "react";
import Modal from "react-modal";
import { useHistory } from "react-router";

import APPOINTMENT_SERVICE from "../../services/appointment";
import { UserContext } from "../../context/context";

const DifferAppointmentModal = (props) => {
  const userContext = useContext(UserContext);
  const user = userContext.loggedUser;
  const role = user ? user.role : "";
  const history = useHistory();

  const handleDifferAppointment = async () => {
    await APPOINTMENT_SERVICE.DIFFER_APPOINTMENT(props.idAppointment);
    props.onRequestClose();
    userContext.changeSate();
    role === "admin" ? history.push("/appointments") : history.go(0);
  };

  Modal.setAppElement("body");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "0",
      boxShadow: "0 1rem 3rem rgba(0,0,0,.175)",
      padding: "4%",
    },
  };

  function afterOpenModal() {}
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      onAfterOpen={afterOpenModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="row">
        <p>¿Estas seguro que quieres diferir esta cita?</p>
      </div>
      <div className="text-right row">
        <button
          type="button"
          className="col btn btn-secondary btn-block mt-4"
          onClick={props.onRequestClose}
        >
          Cerrar
        </button>
        <button
          type="button"
          className="col btn btn-danger  btn-block mt-4"
          onClick={handleDifferAppointment}
        >
          Re-agendar cita
        </button>
      </div>
    </Modal>
  );
};
export default DifferAppointmentModal;
