import "../../Styles/ClinicForm.css";
import React, { useState, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import CLINIC_SERVICE from "../../services/clinic";
import DENTIST_SERVICE from "../../services/dentist";
import { UserContext } from "../../context/context";
import Layout from "../Layout/Layout";
import { MultiSelect } from "react-multi-select-component";
import PROCEDURE_SERVICE from "../../services/procedure";

const ProcedureForm = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();
  const userContext = useContext(UserContext);
  const [clinic, setClinic] = useState({});
  const [dentists, setDentists] = useState([]);
  const [categories, setCategories] = useState([]);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedDentists, setSelectedDentists] = useState([]);
  const [initialDentists, setInitialDentists] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      if (id && id !== "new") {
        try {
          const { data } = await CLINIC_SERVICE.GET_ONE(id);

          data.start = data.start.toString().padStart(4,'0');
          data.end = data.end.toString().padStart(4,'0');
          let start = `${data.start.toString().slice(0, 2)}:${data.start
            .toString()
            .slice(2, 4)}`;
          let end = `${data.end.toString().slice(0, 2)}:${data.end
            .toString()
            .slice(2, 4)}`;
          data.start = start;
          data.end = end;

          setSelectedCategories(
            data.categories.map((cat) => {
              return { label: cat, value: cat };
            })
          );

          setSelectedDentists(
            data.dentists.map((dent) => {
              return {label: dent.idUser.name, value: dent.id};
            })
          );

          setInitialDentists(
            data.dentists.map((dent) => {
              return dent.id;
            })
          )
          setClinic(data);
        } catch (error) {
          console.error(error);
        }
      }
    };
    getData();
  }, [id]);


  useEffect(() => {
    const getDentists = async () => {
      const { data: dentistsData } = await DENTIST_SERVICE.GET_ALL();
      setDentists(dentistsData);
      const { data: dataCategories } = await PROCEDURE_SERVICE.GET_CATEGORIES();
      setCategories(dataCategories);
    };

    getDentists();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: clinic.name || "",
      address: clinic.address || "",
      phone: clinic.phone || "",
      categories: clinic.categories || [],
      dentists: initialDentists || [],
      start: clinic.start || "",
      end: clinic.end || "",
    },
    validationSchema: Yup.object({
      start: Yup.string().required("Hora de inicio requerida."),
      end: Yup.string().required("Hora de finalización requerida."),
      name: Yup.string().required("Nombre requerido."),
      address: Yup.string().required("Ubicación requerida."),
      phone: Yup.string()
        .required("Teléfono requerido.")
        .matches(
          /^[0-9]{10}$/gi,
          "Teléfeno debe ser 10 digitos de largo y solo números."
        ),
      categories: Yup.array()
        .min(1, "Categorías es requerido")
        .required("Categorías es requerido"),
    }),
    onSubmit: async (values) => {
      values.end = values.end.replace(":", "");
      values.start = values.start.replace(":", "");
      if (clinic._id) {
        // EDIT
        try {
          await CLINIC_SERVICE.UPDATE(id, values);
          userContext.changeSate();
          history.push("/clinics");
        } catch (error) {
          setErrorMessage("Ocurrió un error");
        }
      } else {
        // CREATE
        try {
          await CLINIC_SERVICE.CREATE(values);
          userContext.changeSate();
          history.push("/clinics");
        } catch (error) {
          setErrorMessage("Ocurrió un error");
        }
      }
    },
  });

  const options = dentists.map(function (dentist) {
    return { value: dentist.id, label: `${dentist.idUser.name} ${dentist.idUser.surname}` };
  });

  const optionsCategory = categories.map(function (procedure) {
    return { value: procedure, label: procedure };
  });

  const handleCategories = (event) => {
    setSelectedCategories(event);
    let temp = event.map((cat) => {
      return cat.value;
    });
    formik.setFieldValue("categories", temp);
  };
  const handleDentists = (event) => {
    setSelectedDentists(event);
    let temp = event.map((dent) => {
      return dent.value;
    });
    formik.setFieldValue("dentists", temp);
  };

  return (
    <Layout>
      <div className="container-sm pt-3 mt-3">
        <div className="row px-2">
          <div className="col col-sm-9 col-md-8 col-lg-6 my-3 card mx-auto p-4">
            <h2 className="text-center font-weight-bold mt-1 mb-3">Clínica</h2>
            <form>
              <div className="form-group">
                <label htmlFor="name">Nombre</label>
                <input
                  type="text"
                  id="name"
                  placeholder="Nombre"
                  className={`form-control form-control-lg ${
                    formik.errors.name && formik.touched.name
                      ? "is-invalid"
                      : ""
                  }`}
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.name && formik.touched.name && (
                  <div className="invalid-feedback">{formik.errors.name}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="address">Ubicación</label>
                <textarea
                  type="text"
                  id="address"
                  placeholder="Descripción"
                  className={`form-control form-control-lg ${
                    formik.errors.address && formik.touched.address
                      ? "is-invalid"
                      : ""
                  }`}
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.address && formik.touched.address && (
                  <div className="invalid-feedback">
                    {formik.errors.address}
                  </div>
                )}
              </div>

              <div className="form-row">
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="start">Hora de apertura </label>
                    <input
                      type="time"
                      id="start"
                      placeholder="Hora de apertura"
                      className={`form-control form-control-sm ${
                        formik.errors.start && formik.touched.start
                          ? "is-invalid"
                          : ""
                      }`}
                      name="start"
                      value={formik.values.start}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.start && formik.touched.start && (
                      <div className="invalid-feedback">
                        {formik.errors.start}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="end">Hora de cierre </label>
                    <input
                      type="time"
                      id="end"
                      placeholder="Hora de cierre"
                      className={`form-control form-control-sm ${
                        formik.errors.end && formik.touched.end
                          ? "is-invalid"
                          : ""
                      }`}
                      name="end"
                      value={formik.values.end}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.end && formik.touched.end && (
                      <div className="invalid-feedback">
                        {formik.errors.end}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="phone">Teléfono</label>
                <input
                  type="phone"
                  id="phone"
                  placeholder="Teléfono"
                  className={`form-control form-control-lg ${
                    formik.errors.phone && formik.touched.phone
                      ? "is-invalid"
                      : ""
                  }`}
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.phone && formik.touched.phone && (
                  <div className="invalid-feedback">{formik.errors.phone}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="dentists">Dentistas</label>
                <MultiSelect
                  id="dentists"
                  name="dentists"
                  className={`${
                    formik.errors.categories && formik.touched.categories
                    ? "is-invalid"
                    : ""
                  }`}
                  options={options}
                  value={selectedDentists}
                  onChange={handleDentists}
                  labelledBy="Select"
                />
                {formik.errors.dentists && formik.touched.dentists && (
                  <div className="invalid-feedback">
                    {formik.errors.dentists}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="categories">
                  Categorías
                  <br />
                  <small>
                    Categorías de servicios que se ofrecen en la clínica
                  </small>
                </label>
              <MultiSelect
                id="categories"
                name="categories"
                className={`${
                  formik.errors.categories && formik.touched.categories
                  ? "is-invalid"
                  : ""
                }`}
                options={optionsCategory}
                value={selectedCategories}
                onChange={handleCategories}
                labelledBy="Select"
                />
              {formik.errors.categories && formik.touched.categories && (
                <div className="invalid-feedback">
                  {formik.errors.categories}
                </div>
              )}
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-primary w-100 m-0"
                  onClick={formik.handleSubmit}
                >
                  Guardar
                </button>
              </div>
              {errorMessage && (
                <div className="text-danger">{errorMessage}</div>
              )}
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ProcedureForm;
