import React, { useRef, useState } from 'react';
import Layout from '../components/Layout/Layout';
import History from '../components/MedicalRecords/History';
import Identification from '../components/MedicalRecords/Identification';
import DentalHistory from '../components/MedicalRecords/DentalHistory';
import PATIENT_SERVICE from '../services/patient';

import flat from 'flat';
import { Redirect } from 'react-router';

const NewPatientProfile = () => {
    const [values] = useState({});
    function setValue(value, id) {
        values[id] = value;
    }

    const [createdId, setIdCreated] = useState(null);

    const refs = {
        identification: useRef(null),
        history: useRef(null),
        dentalHistory: useRef(null),
    };

    async function onSubmit(e) {
        // Prevent reload
        e.preventDefault();
        const jsonValues = flat.unflatten(values);

        // Check for errors
        const errors = [];
        for (var ref in refs) {
            try {
                const eachErrors = await refs[ref].current.onSubmit();
                for (var err in eachErrors)
                    errors.push(eachErrors[err]);
            } catch (error) {
                console.log(ref);
            }
        }

        console.log(errors);
        if (!errors.length) {
            // If there is no errors
            PATIENT_SERVICE.CREATE(jsonValues)
                .then(res => {
                    console.log(res);
                    setIdCreated(res.data.id);
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    return (
        createdId ?
            <Redirect to={`/patients/${createdId}`} />
            :
            <Layout>
                <form onSubmit={onSubmit}>
                    <div className="container card py-4">
                        <div className="endRow">
                            <button type="submit" className="btn btn-primary">
                                Agregar Paciente
                            </button>
                        </div>
                        <ul className="nav nav-tabs mb-4" id="myTab" role="tablist" >
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link active"
                                    id="identificacion-tab"
                                    data-toggle="tab"
                                    href="#identification"
                                    role="tab"
                                >
                                    Ficha de Identificación
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link"
                                    id="identificacion-tab"
                                    data-toggle="tab"
                                    href="#history"
                                    role="tab"
                                >
                                    Historia Médica
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link"
                                    id="historia-dental-tab"
                                    data-toggle="tab"
                                    href="#historia-dental"
                                    role="tab"
                                >
                                    Historia Dental
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div
                                className="tab-pane fade show active"
                                id="identification"
                                role="tabpanel"
                                aria-labelledby="home-tab"
                            >
                                <Identification
                                    setValue={setValue}
                                    ref={refs["identification"]}
                                    block={false}
                                />
                            </div>
                            <div
                                className="tab-pane fade"
                                id="history"
                                role="tabpanel"
                                aria-labelledby="profile-tab"
                            >
                                <History
                                    setValue={setValue}
                                    ref={refs["history"]}
                                    block={false}
                                />
                            </div>
                            <div
                                className="tab-pane fade"
                                id="historia-dental"
                                role="tabpanel"
                                aria-labelledby="profile-tab"
                            >
                                <DentalHistory
                                    setValue={setValue}
                                    ref={refs["dentalHistory"]}
                                    block={false}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </Layout >

    );
}

export default NewPatientProfile;