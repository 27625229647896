import "../../Styles/DentistForm.css";
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MultiSelect } from "react-multi-select-component";


import CLINIC_SERVICE from "../../services/clinic";
import PROCEDURE_SERVICE from "../../services/procedure";
import DENTIST_SERVICE from "../../services/dentist";
import AUTH_SERVICE from "../../services/auth";
import { UserContext } from "../../context/context";

import Layout from "../Layout/Layout";

const DentistForm = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [, setIsLoading] = useState(false);
  const [, setProcedures] = useState([]);
  const [, setClinics] = useState([]);
  const [dentist, setDentist] = useState({});
  const history = useHistory();
  const userContext = useContext(UserContext);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const { id } = useParams();

  const handleCategories = (event) => {
    setSelectedCategories(event);
    let temp = event.map((cat) => {
      return cat.value;
    });
    formik.setFieldValue("categories", temp);
  };

  useEffect(() => {
    setIsLoading(true);
    CLINIC_SERVICE.GET_ALL()
      .then((res) => {
        setClinics(res.data);
      })
      .then(() => {
        setIsLoading(false);
      })
      .catch();
    PROCEDURE_SERVICE.GET_ALL()
      .then((res) => {
        setProcedures(res.data);
      })
      .then(() => {
        setIsLoading(false);
      })
    PROCEDURE_SERVICE.GET_CATEGORIES()
      .then((res) => {
        setCategories(res.data);
      })
      .then(() => {
        setIsLoading(false);
      })
      .catch();

    if (id && id !== "new") {
      DENTIST_SERVICE.GET_ONE(id).then((response) =>
        setDentist(response.data)
      );
    }
    return () => { };
  }, [setDentist, id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: dentist.name || "",
      surname: dentist.surname || "",
      email: dentist.email || "",
      office: dentist.office || 0,
      especialty: dentist.especialty || "",
      categories: dentist.categories || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Nombre requerido."),
      surname: Yup.string().required("Apellido requerido."),
      email: Yup.string()
        .email("Email inválido.")
        .required("Email es requerido."),
      office: Yup.number().typeError("El no. de clínica debe ser un numero")
        .positive("Debe ser mayor a cero").required("No. de clínica es requerido."),
      especialty: Yup.string().required("Especialidad es requerido."),
      categories: Yup.array()
        .min(1, "Categorías es requerido")
        .required("Categorías es requerido"),
    }),
    onSubmit: async (values) => {
      try {
        setErrorMessage("");
        let response;
        response = await AUTH_SERVICE.CREATE_DENTIST({
          name: values.name,
          surname: values.surname,
          email: values.email,
          office: values.office,
          especialty: values.especialty,
          categories: values.categories,
        });

        if (response?.status === 200) {
          userContext.changeSate();
          history.push("/admin/dentists");
        }
      } catch (error) {
        console.log(error);
        setErrorMessage("Ocurrió un error");
      }
    }
  });

  const options = categories.map(function (procedure) {
    return { value: procedure, label: procedure };
  });

  return (
    <Layout>
      <div className="container-sm pt-3 mt-3">
        <div className="row px-2">
          <div className="col col-sm-9 col-md-8 col-lg-6 my-3 card mx-auto p-4">
            <h2 className="text-center font-weight-bold mt-1 mb-3">Dentista</h2>
            <form>
              <div className="form-group">
                <label htmlFor="name">Nombre</label>
                <input
                  type="text"
                  id="name"
                  placeholder="Nombre"
                  className={`form-control form-control-lg ${formik.errors.name && formik.touched.name
                    ? "is-invalid"
                    : ""
                    }`}
                  name="name"
                  value={dentist.name || formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.name && formik.touched.name && (
                  <div className="invalid-feedback">{formik.errors.name}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="surname">Apellidos</label>
                <input
                  type="text"
                  id="surname"
                  placeholder="Apellidos"
                  className={`form-control form-control-lg ${formik.errors.surname && formik.touched.surname
                    ? "is-invalid"
                    : ""
                    }`}
                  name="surname"
                  value={formik.values.surname || dentist.surname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.surname && formik.touched.surname && (
                  <div className="invalid-feedback">
                    {formik.errors.surname}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="cost">Email</label>
                <input
                  type="email"
                  id="email"
                  placeholder="Email"
                  className={`form-control form-control-lg ${formik.errors.email && formik.touched.email
                    ? "is-invalid"
                    : ""
                    }`}
                  name="email"
                  value={formik.values.email || dentist.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.email && (
                  <div className="invalid-feedback">{formik.errors.email}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="office">No. de Unidad</label>
                <input
                  type="number"
                  id="office"
                  placeholder="No. de Unidad"
                  className={`form-control form-control-lg ${formik.errors.office && formik.touched.office
                    ? "is-invalid"
                    : ""
                    }`}
                  name="office"
                  value={formik.values.office || dentist.office}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.office && formik.touched.office && (
                  <div className="invalid-feedback">
                    {formik.errors.office}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="especialty">Especialidad</label>
                <select
                  name="especialty"
                  id="especialty"
                  className={`form-control form-control-lg ${formik.errors.especialty && formik.touched.especialty
                    ? "is-invalid"
                    : ""
                    }`}
                  onChange={formik.handleChange}
                  value={formik.values.especialty ? formik.values.especialty : ""}
                >
                  <option value="">Seleccione Especialidad</option>
                  <option value="odontopediatria">Odontopediatría</option>
                  <option value="endodoncia">Endodoncia</option>
                  <option value="ortodoncia">Ortodoncia</option>
                  <option value="servicios-generales">
                    Servicios Generales
                  </option>
                </select>
                {formik.errors.especialty && formik.touched.especialty && (
                  <div className="invalid-feedback">
                    {formik.errors.especialty}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="categories">
                  Categorías
                <br />
                <small>Categorías de servicios que ofrece el dentista</small>
                  </label>
                  <MultiSelect
                  id="categories"
                  name="categories"
                  className={`${
                    formik.errors.categories && formik.touched.categories
                    ? "is-invalid"
                    : ""
                  }`}
                  options={options}
                  value={selectedCategories}
                  onChange={handleCategories}
                  labelledBy="Categorías"
                />
                
                {formik.errors.categories && formik.touched.categories && (
                  <div className="invalid-feedback">{formik.errors.categories}</div>
                )}
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-primary w-100 m-0"
                  onClick={formik.handleSubmit}
                >
                  Guardar
                </button>
              </div>
              {errorMessage && (
                <div className="text-danger">{errorMessage}</div>
              )}
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default DentistForm;
