import React from "react"

const Row = (props) => {
    const {children} = props;
    return(
        <div className='row padall1'>
            {children}
        </div>
    );
}

export default Row ;