import React from 'react';
import ReceptionistForm from '../components/Recepcionist/ReceptionistForm'

const EditReceptionist = () => {
  return (
    <>
      <ReceptionistForm />
    </>
  );
};

export default EditReceptionist;
