import React, { useState, useContext } from "react";
import Modal from "react-modal";
import { useFormik } from "formik";
import * as Yup from "yup";

import APPOINTMENT_SERVICE from "../../services/appointment";
import { UserContext } from "../../context/context";

const PatientWithAccount = (props) => {
  const userContext = useContext(UserContext);
  const id = props.idAppointment;
  Modal.setAppElement("body");
  const [errorMessage, setErrorMessage] = useState("");
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Correo electrónico invalido.")
        .required("Un correo electrónico es requerido."),
    }),
    onSubmit: async (values) => {
      try {
        setErrorMessage("");
        await APPOINTMENT_SERVICE.GET_ID(id, values);
        //alert("Se ha registrado al paciente y su cuenta se ha creado.");

        props.onRequestClose();
        userContext.changeSate();
        //window.location.reload();
      } catch (error) {
        console.log(error);
        setErrorMessage("El usuario no existe");
      }
    },
  });

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "0",
      boxShadow: "0 1rem 3rem rgba(0,0,0,.175)",
      padding: "4%",
    },
  };

  function afterOpenModal() {}
  return (
    <Modal
      isOpen={props.isOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={props.onRequestClose}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="">
        <button type="button" className="close" onClick={props.onRequestClose}>
          x
        </button>
        <h1>Registro del Paciente</h1>
        <form>
          <div className="form-group">
            <label htmlFor="exampleInputPassword1">Correo electrónico</label>
            <input
              type="email"
              className={`form-control ${
                formik.errors.email && formik.touched.email ? "is-invalid" : ""
              }`}
              placeholder="ejemplo@gmail.com"
              id="inputEmail"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.email && formik.touched.email && (
              <div className="invalid-feedback">{formik.errors.email}</div>
            )}
            {errorMessage && <div className="text-danger">{errorMessage}</div>}
          </div>
          <div className="text-right">
            <button
              type="submit"
              className="btn btn-primary btn-lg btn-block mt-4"
              onClick={formik.handleSubmit}
            >
              Registrar paciente
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default PatientWithAccount;
