import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderPlus, faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import "../../../Styles/AddRecordModal.css";

import UploadImage from "../Digitalization/UploadImage";

const AddRecordModal = ({ id }) => {
  return (
    <>
      <div
        className="modal fade"
        id="AddRecordModal"
        tabIndex="-1"
        aria-labelledby="AddRecordModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Añadir expediente</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body d-flex justify-content-center">
              <span
                className="mx-4 d-flex flex-column align-items-center modal-link"
                style={{ cursor: "pointer" }}
                data-toggle="modal"
                data-target="#photoModal"
              >
                <FontAwesomeIcon icon={faFolderOpen} size="5x" color="grey" />
                <p>Subir existente</p>
              </span>
              <a
                href={`/patients/profile/${id}/create/medicalrecord`}
                className="mx-4 d-flex flex-column align-items-center modal-link"
              >
                <FontAwesomeIcon icon={faFolderPlus} size="5x" />
                <p>Crear nuevo</p>
              </a>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        className="btn btn-primary mb-2"
        data-toggle="modal"
        data-target="#AddRecordModal"
      >
        <i className="bi bi-file-plus"></i> Añadir
      </button>

      <UploadImage />
    </>
  );
};

export default AddRecordModal;
