import * as Yup from 'yup';

export default function ValidationDentalHistory() {
    const dentalHistoryValidation = {
        primerVisita: Yup.string().required(' '),
        fechaÚltimaVisita: Yup.date(),
        motivoÚltimaVisita: Yup.string().max(50, 'Muy largo'),
        dolorDental: Yup.string().required(' '),
        descripciónDolorDental: Yup.string().max(100, 'Muy largo'),
        dietaBalanceada: Yup.string().required(' '),
        descripciónDietaBalanceada: Yup.string().max(100, 'Muy largo'),
        alimentadoSenoMaterno: Yup.string().required(' '),
        descripciónSenoMaterno: Yup.string().max(100, 'Muy largo'),
        usaBiberón: Yup.string().required(' '),
        descripciónUsaBiberón: Yup.string().max(100, 'Muy largo'),
        usaChupón: Yup.string().required(' '),
        descripciónUsaChupón: Yup.string().max(100, 'Muy largo'),
        tieneHabito: Yup.string().required(' '),
        habitoFrecuencia: Yup.string().max(40, ' '),
        habitoDuración: Yup.string().max(40, 'Muy largo'),
        habitoIntensidad: Yup.string().max(40, 'Muy largo'),
        cepilladoDientes: Yup.string().required(' '),
        pastaDental: Yup.string().required(' '),
        complementosCepilladoDental: Yup.string().max(100, 'Muy largo'),
    }

    return dentalHistoryValidation;
}
