
import '../../Styles/ReceptionistForm.css'
import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import USER_SERVICE from '../../services/user';
import { UserContext } from '../../context/context';
import Layout from '../Layout/Layout';

const ProcedureForm = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const userContext = useContext(UserContext);
  const [receptionist, setReceptionist] = useState({});

  const { id } = useParams();

  const userServiceGetOne = async(id) =>{
    const {data} = await USER_SERVICE.GET_ONE(id);
    setReceptionist(data)
  }

  useEffect(() => {
    if (id) {
      try {
        userServiceGetOne(id);
      } catch (error) {
        console.log(error);
      }
    }
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: receptionist.name || '',
      surname: receptionist.surname || '',
      email: receptionist.email || '',
      password: '',
      confirmPassword: '',
      role: 'receptionist'
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Nombre requerido.'),
      surname: Yup.string().required('Apellido requerido'),
      email: Yup.string()
        .email("Correo electrónico invalido.")
        .required("Un correo electrónico es requerido."),
      password: Yup.string()
        .required("Una contraseña es requerida.")
        .min(6, "La contraseña debe tener minimo 6 caracteres.")
        .matches(/[A-Z]+/g, "La contraseña debe tener una letra mayúscula.")
        .matches(/[a-z]+/g, "La contraseña debe tener una letra minúscula.")
        .matches(/[0-9]+/g, "La contraseña debe contener mínimo un número.")
        .matches(
          /(\W|_)+/g,
          "La contraseña debe contener mínimo un carácter especial."
        ),
      confirmPassword: Yup.string()
        .required("Este campo es requerido.")
        .oneOf([Yup.ref("password"), ""], "Las contraseñas no son idénticas"),
    }),
    onSubmit: async (values) => {
      try {
        setErrorMessage('');
        let response;
        if (receptionist.name && receptionist.surname && receptionist.email) {
          response = await USER_SERVICE.UPDATE(id, values);
        } else {
          response = await USER_SERVICE.CREATE(values);
        }
        if (response?.status === 200) {
          userContext.changeSate();
          history.push('/receptionists');
        }
      } catch (error) {
        console.log(error.message);
        setErrorMessage('Ocurrió un error');
      }
    },
  });

  return (
    <Layout>
      <div className="container-sm pt-3 mt-3">
        <div className="row px-2">
          <div className="col col-sm-9 col-md-8 col-lg-6 my-3 card mx-auto p-4">
            <h2 className="text-center font-weight-bold mt-1 mb-3">Recepcionista</h2>
            <form>
              <div className="form-group">
                <label htmlFor="name">Nombre</label>
                <input
                  type="text"
                  id="name"
                  placeholder="Nombre"
                  className={`form-control form-control-lg ${formik.errors.name && formik.touched.name
                      ? 'is-invalid'
                      : ''
                    }`}
                  name="name"
                  value={formik.values.name || receptionist.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.name && formik.touched.name && (
                  <div className="invalid-feedback">{formik.errors.name}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="name">Apellido</label>
                <input
                  type="text"
                  id="surname"
                  placeholder="Apellido"
                  className={`form-control form-control-lg ${formik.errors.surname && formik.touched.surname
                      ? 'is-invalid'
                      : ''
                    }`}
                  name="surname"
                  value={formik.values.surname || receptionist.surname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.surname && formik.touched.surname && (
                  <div className="invalid-feedback">{formik.errors.surname}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="inputEmail">Correo electrónico</label>
                <input
                  type="email"
                  className={`form-control ${formik.errors.email && formik.touched.email
                      ? "is-invalid"
                      : ""
                    }`}
                  placeholder="ejemplo@gmail.com"
                  id="inputEmail"
                  aria-describedby="emailHelp"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.email && (
                  <div className="invalid-feedback">{formik.errors.email}</div>
                )}
                {errorMessage && (
                  <div className="text-danger">{errorMessage}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="password">Contraseña</label>
                <input
                  type="password"
                  className={`form-control ${formik.errors.password && formik.touched.password
                      ? "is-invalid"
                      : ""
                    }`}
                  id="password"
                  aria-describedby="passwordHelp"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Contraseña"
                />
                {formik.errors.password && formik.touched.password && (
                  <div className="invalid-feedback">
                    {formik.errors.password}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">Confirmar contraseña</label>
                <input
                  type="password"
                  className={`form-control ${formik.errors.confirmPassword &&
                      formik.touched.confirmPassword
                      ? "is-invalid"
                      : ""
                    }`}
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Confirmar contraseña"
                />
                {formik.errors.confirmPassword &&
                  formik.touched.confirmPassword && (
                    <div className="invalid-feedback">
                      {formik.errors.confirmPassword}
                    </div>
                  )}
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-primary w-100 m-0"
                  onClick={formik.handleSubmit}
                >
                  Guardar
                </button>
              </div>
              {errorMessage && (
                <div className="text-danger">{errorMessage}</div>
              )}
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ProcedureForm;
