import React from "react";
import Layout from "../../components/Layout/Layout";

// import NavBar from "../../components/NavBar/NavBar";
import Vectors from "../../components/Vectors/Vectors";
import colors from "../../utils/colors";

const NoDirection = () => {
  return (
    <Layout>
      <div
        className="d-flex align-items-center justify-content-center p-3 pt-5 mb-2"
        style={{ fontSize: 150 }}
      >
        <Vectors
          draw="cone"
          width="2em"
          height="2em"
          color={colors.secondary.darkBlue}
        />
      </div>
      <div className="d-flex align-items-center justify-content-center px-3 pt-5 mb-0 rounded">
        <h1>
          <strong>404: Página no encontrada </strong>
        </h1>
      </div>
      <div className=" d-flex align-items-center justify-content-center p-3 pt- rounded">
        <p>
          <strong>La dirección que intenta acceder no existe.</strong>
        </p>
      </div>
    </Layout>
  );
};
export default NoDirection;
