import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useHistory } from "react-router";

const QRStatusModal = ({ children }) => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push("/receptionist/appointments/");
    }, 5000);
  }, [history]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "0",
      boxShadow: "0 1rem 3rem rgba(0,0,0,.175)",
      padding: "3%",
      borderRadius: "30px",
    },
  };
  const [openModal, setOpenModal] = useState(true);

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="QR Modal"
      >
        {children}
      </Modal>
    </div>
  );
}

export default QRStatusModal;
