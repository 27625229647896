import React, { useState, useEffect } from 'react';
import { parse } from 'query-string';
import PatientCard from './PatientCard';
import PATIENT_SERVICE from '../../services/patient';

const PatientsList = (props) => {
  const [patients, setPatients] = useState([]);

  const searchQuery = parse(window.location.search).search;

  const patientServiceGetAll = async () => {
    const { data } = await PATIENT_SERVICE.GET_ALL(1, 10);
    setPatients(data.foundProfiles);
  }

  useEffect(() => {
    try {
      patientServiceGetAll();
    } catch (error) {
      console.log(error);
    }
  }, [searchQuery]);

  return (
    <>
      <div className="row mx-2 mx-md-5">
        {patients?.map((patient) => {
          return (
            <PatientCard
              id={patient.id}
              name={patient.generalInfo.name}
              surname={patient.generalInfo.paternalLastName + " " + patient.generalInfo.maternalLastName}
              email={patient.email}
              phone={patient.generalInfo.phoneNumber.main}
              age={patient.generalInfo.age}
              key={patient.id}
              link={true}
              url="/patients"
            />
          );
        })}
      </div>
    </>
  );
}

export default PatientsList;
