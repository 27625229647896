import axios from "axios";
import * as converter from "./utils/UrlToUint"

const PUBLIC_VAPID_KEY = process.env.REACT_APP_PUBLIC_VAPID_KEY;
const convertedKey = converter.urlBase64ToUint8Array(PUBLIC_VAPID_KEY)
const service = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
  withCredentials: true
});

let swRegistration = null;


export async function new_register() {

  const allowServiceWorker = 'serviceWorker' in navigator &&
  'PushManager' in window &&
  process.env.NODE_ENV === "production"

  if (allowServiceWorker) {
    window.addEventListener('load', () => {
      console.log('Service Worker and Push is supported');

      navigator.serviceWorker.register('/service-worker.js')
        .then(function (swReg) {
          console.log('Service worker is registered', swReg);

          swRegistration = swReg;
        })
        .catch(function (error) {
          console.error('Service Worker Error', error);
        });
    });
  } else {
    console.info('Service worker not available');
  }
}


export async function subscribe(idDentist, token) {
  if ('serviceWorker' in navigator && 'PushManager' in window
    && process.env.NODE_ENV === "production") {

    const subscription = await swRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: convertedKey
    });

    console.log("Subscription: " + JSON.stringify(subscription));

    service.post("/subscription", {
      data: JSON.stringify(subscription),
      id: idDentist
    }, {
      headers: {
        Authorization: token
      }
    });
  }
}

export async function unsubscribe(idDentist, token) {
  if ('serviceWorker' in navigator && 'PushManager' in window
    && process.env.NODE_ENV === "production") {
    service.delete("/subscription", {
      headers: {
        Authorization: token
      },
      data: {
        source: { id: idDentist }
      }
    });
  }
}


export async function clientArrived(data, token) {
  if ('serviceWorker' in navigator && process.env.NODE_ENV === "production") {
    service.post("/webpush", {
      body: data,
    }, {
      headers: {
        Authorization: token
      }
    });
  }
}


export async function unregister() {
  if ('serviceWorker' in navigator && process.env.NODE_ENV === "production") {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}