import React, { useState } from "react";
import QrReader from "react-qr-reader";
import QRStatusModal from "./QRStatusModal";
import APPOINTMENT_SERVICE from "../../services/appointment";
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';
/**
 * Component to scan a QR
 * @component
 *
 */
const QRScanner = () => {
  const [isResult, setIsResult] = useState(false);
  const [dataAppointment, setDataAppointment] = useState({});

  const appointmentServiceGetData = async (id) => {
    const { data } = await APPOINTMENT_SERVICE.GET_DATA_SCAN(id);
    setDataAppointment(data);
    const info = data;
    const newData = {
      "dentist_id": info.dentist_id,
      "dentist_name": info.dentist,
      "patient_id": info.patient_id,
      "patient_name": info.patient
    }
    serviceWorkerRegistration.clientArrived(newData);
    APPOINTMENT_SERVICE.UPDATE(id, { status: 2 });
  }

  const handleScan = async (id) => {
    if (id) {
      try {
        setIsResult(true);
        appointmentServiceGetData(id);
      } catch (error) {
        console.log(error);
      };

    };
  };

  const handleError = (err) => {
    console.error(err);
  };
  if (isResult) {
    return (
      <div width={100} height={"50vh"}>
        <QRStatusModal>
          <h1>Unidad: {dataAppointment.office}</h1>
          <h2>Hora: {dataAppointment.hour}</h2>
          <h3>Paciente: {dataAppointment.patient}</h3>
          <h3>Dentista: {dataAppointment.dentist}</h3>
          <h4>Clínica: {dataAppointment.clinic}</h4>
          <p>Servicio: {dataAppointment.procedure}</p>
        </QRStatusModal>
      </div>
    );
  }
  return (
    <div width={100} height={"50vh"}>
      <QrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: "100%" }}
      />
    </div>
  );
}

export default QRScanner;
