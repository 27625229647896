import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout/Layout';
import APPOINTMENT_SERVICE from '../services/appointment';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const Reports = () => {
  const [appointments, setAppointments] = useState({ appointments: [], total: 0 });
  const [reportData, setReportData] = useState({ appointments: [], total: 0 });
  const [month, setMonth] = useState(false);
  const [week, setWeek] = useState(false);
  const [year, setYear] = useState(false);

  const date = ({ month, week, year }) => {
    let from = moment().startOf('day')
    let to = moment().endOf('day')
    if (week) {
      from = moment().startOf('week')
      to = moment().endOf('week')
    } else if (month) {
      from = moment().startOf('month')
      to = moment().endOf('month')
    } else if (year) {
      from = moment().startOf('year')
      to = moment().endOf('year')
    }
    return { from, to };
  }

  const appointmentServiceGetAttendedAppointments = async (from, to) => {
    const { data } = await APPOINTMENT_SERVICE.GET_ATTENDED_APPOINTMENTS(from, to);
    setAppointments(data);
  }

  useEffect(() => {
    const { from, to } = date({ week, month, year });
    try {
      appointmentServiceGetAttendedAppointments(from, to);

    } catch (error) {
      console.log(error);
    }
  }, [month, week, year]);

  useEffect(() => {
    if (!!appointments.appointments && appointments.appointments.length > 0)
    {
      let categoryCount = {};
      appointments.appointments.forEach(appointment =>{
        if (categoryCount[appointment.category] !== undefined)
        {
          categoryCount[appointment.category]++;
        }
        else
        {
          categoryCount[appointment.category] = 1;
        }
      }) 

      let reportArray = [];
      for (const [key, value] of Object.entries(categoryCount)) {
        reportArray.push({
          category: key,
          count: value
        })
      }
      const reportObject = {
        appointments: reportArray,
        total: appointments.length
      }
      setReportData(reportObject);
    }
    else
    {
      const reportObject = {
        appointments: [],
        total: 0
      }
      setReportData(reportObject);
    }
  }, [appointments]);

  const handleTodayClick = () => {
    setMonth(false);
    setWeek(false);
    setYear(false);
    return null;
  }

  const handleWeekClick = () => {
    setWeek(true);
    setMonth(false);
    setYear(false);
    return null;
  }

  const handleMonthClick = () => {
    setWeek(false);
    setMonth(true);
    setYear(false);
    return null;
  }

  const handleYearClick = () => {
    setWeek(false);
    setMonth(false);
    setYear(true);
    return null;
  }
  return (
    <Layout>
      <div className="row mr-0 justify-content-center align-items-center">
        <div className="col col-md-6 d-flex flex-direction-column justify-content-center align-items-center">
          {
            !month && !week && !year ?
              <button className="btn btn-primary disabled" onClick={handleTodayClick}>Hoy</button>
            :
              <button className="btn btn-primary" onClick={handleTodayClick}>Hoy</button>
          }
          <button className="btn btn-primary" onClick={handleWeekClick}>Esta semana</button>
          <button className="btn btn-primary" onClick={handleMonthClick}>Este mes</button>
          <button className="btn btn-primary" onClick={handleYearClick}>Este año</button>
        </div>
        <div className="col-12 p-4">
          <table className="table" id="reportes">
            <thead>
              <tr>
                <th scope="col">Categoria</th>
                <th scope="col">Veces realizado</th>
              </tr>
            </thead>
            <tbody>
              {reportData.appointments.map((procedure) => (
                <tr>
                  <td> 
                    {procedure.category }
                  </td>
                  <td>
                    {procedure.count}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <td className="table-primary">
                <p className="font-weight-bold m-0">Total: </p>
              </td>
              <td className="table-primary">
                {appointments.total}
              </td>
            </tfoot>
          </table>
        </div>
      </div>
      <div className="">
        <ReactHTMLTableToExcel
          id="btn btn-primary"
          className="btn btn-primary"
          table="reportes"
          filename="reportes"
          sheet="Tabla de Reportes"
          buttonText="Descargar archivo de Excel"
        />
      </div>
    </Layout>
  );
};

export default Reports;
