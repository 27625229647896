import React, {
  useState, useEffect, useContext,
} from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import AppointmentModal from '../../components/Appointment/AppointmentModal';
import AppointmentCard from '../../components/Appointment/AppointmentCard';
import { UserContext } from '../../context/context';

import APPOINTMENT_SERVICE from '../../services/appointment';
import Layout from '../../components/Layout/Layout';

const Patient = () => {
  const userContext = useContext(UserContext);
  const { role } = userContext.loggedUser;
  const { id } = useParams();

  const history = useHistory();

  const [appointments, setAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const appointmentServiceGetAllUser = async (id, isMounted) => {
    try {
      const { data } = await APPOINTMENT_SERVICE.GET_ALL_USER(id);
      if (data && isMounted) {
        setAppointments(data);
      }
    } catch (e) {
      console.error('e')
    }

    setIsLoading(false);
  }
  // Fetch all user appointments
  useEffect(() => {
    let isMounted = true;
    if (id === userContext.loggedUser.id) {
      setIsLoading(true);
      appointmentServiceGetAllUser(id, isMounted);
    } else {
      history.push('/403');
      return [history];
    }
    return () => { isMounted = false; };
  }, [userContext.reload, id, history, userContext.loggedUser.id]);

  // States
  const [open, setOpen] = useState(false);

  // Open New Appointment modal
  const showModal = () => {
    setOpen(true);
  };
  // Close New Appointment modal
  const closeModal = () => {
    setOpen(false);
  };

  const modalStyle = open ? { display: 'block' } : { display: 'none' };
  const modalClass = open ? 'show' : '';

  return (
    <Layout showModal={showModal}>
      {!isLoading
        ? (
          <div className="">
            <AppointmentModal
              modalStyle={modalStyle}
              close={closeModal}
              modalClass={modalClass}
              userId={id}
              role={role}
            />

            <div className="row mx-0 px-0">
              <div className="col-12 d-flex justify-content-center mb-4">
                <h2 className="font-weight-bold">Próximas Citas</h2>
              </div>
              {appointments.map((appointment) => {
                if (appointment.status === 0 || appointment.status === 1) {
                  if (appointment.category) {
                    const date = new Date(appointment.date);
                    let today = new Date();
                    today.setUTCHours(0,0,0,0);
                    if ((appointment.status === 0
                      || appointment.status === 2
                      || appointment.status === 5)
                      && (date.getTime()
                        >= today.getTime() )) {
                      return (
                        <AppointmentCard
                          procedure={appointment.category}
                          doc={
                            `${appointment.dentist.name} ${appointment.dentist.surname}`
                          }
                          date={date}
                          time={appointment.starts}
                          key={appointment.id}
                          keydata={appointment.id}
                          role={role}
                          status={appointment.status}
                          patient={appointment.patient}
                        />
                      );
                    }
                    return (null);
                  } else { return null; }
                }
                else { return null }
              })}
              <div className="col-12 d-flex justify-content-center mb-4 font-weight-bold">
                <h2 className="font-weight-bold">Citas Anteriores</h2>
              </div>
              {appointments.map((appointment) => {
                if (appointment.status === 0 || appointment.status === 1 || appointment.status === 2) {
                  if (appointment.category) {
                    const date = new Date(appointment.date);
                    let today = new Date();
                    today.setUTCHours(0,0,0,0);
                    if (((appointment.status === 0
                      || appointment.status === 2
                      || appointment.status === 1)
                      && (date.getTime()
                        < today.getTime() )) 
                      || ((appointment.status === 2 
                      && date.getUTCDate() === today.getUTCDate() 
                      && date.getUTCMonth() === today.getUTCMonth()
                      && date.getUTCFullYear() === today.getUTCFullYear()))) {
                      return (
                        <AppointmentCard
                          procedure={appointment.category}
                          doc={
                            `${appointment.dentist.name} ${appointment.dentist.surname}`
                          }
                          date={date}
                          time={appointment.starts}
                          key={appointment.id}
                          keydata={appointment.id}
                          role={role}
                          status={appointment.status}
                          patient={appointment.patient}
                        />
                      );
                    }
                    return (null);
                  } else { return null; }
                }
                else { return null }
              })}
            </div>
          </div>
        )
        : null}
    </Layout>
  );
};

export default Patient;
