import React from 'react';
import '../../Styles/ClinicCard.css'
import { Link } from 'react-router-dom';
import CLINIC_SERVICE from '../../services/clinic';

const ClinicCard = ({
  id, name, address, phone,
}) => {
  const handleDelete = async () => {
    const response = await CLINIC_SERVICE.DELETE(id);
    if (response.status === 200) {
      window.location.reload();
    }
    return response;
  };
  return (
    <>
      <div className="col col-12 col-md-6 col-lg-4 my-2 pt-3">
        <div className="card">
          <Link to={`/clinics/${id}`}>
            <div className="card-header">
              <h4 className="card-title">{name}</h4>
            </div>
          </Link>
          <div className="card-body">
            <p>
              <strong>Nombre:</strong>
              {' '}
              {name}
            </p>
            <p>
              <strong>ubicación:</strong>
              {' '}
              {address}
            </p>
            <p>
              <strong>Teléfono:</strong>
              {' '}
              {phone}
            </p>
            <div className="d-flex justify-content-end">
              <button type="button" className="btn btn-danger align-left" onClick={handleDelete}>
                Eliminar
              </button>
              <Link to={`/clinics/${id}`} className="btn btn-primary modify">
                Modificar
              </Link>
            </div>
          </div>
        </div>
        <div />
      </div>
    </>
  );
};

export default ClinicCard;
