import React, { useState } from 'react';
// import PatientList from '../components/Patient/PatientsList';
import SearchBar from '../components/SearchBar/SearchBar';
import Layout from '../components/Layout/Layout';
import { Link } from 'react-router-dom';
// import DentistsList from "../components/Dentist/DentistsList";
import PATIENT_SERVICE from '../services/patient';
import PatientCard from '../components/Patient/PatientCard';

import { calculateAge } from '../utils/calculateAge';

const Patients = () => {
    const [patients, setPatients] = useState([]);
    const [initialSearch, setIntialSearch] = useState(false);
    const [query, setQuery] = useState({
        generalInfo: {
            name: '',
            paternalLastName: '',
            maternalLastName: '',
        }
    });

    if (!initialSearch) {
        PATIENT_SERVICE.GET_ALL(1, 10)
            .then((response) => {
                setPatients(response.data.foundProfiles)
                setIntialSearch(true);
            })
            .catch();
    }


    const onChangePatient = (e) => {
        setQuery({
            generalInfo: {
                name: e.target.value,
                paternalLastName: e.target.value,
                maternalLastName: e.target.value,
            }
        });
    }

    const onSubmit = () => {
        PATIENT_SERVICE.FIND(query, 1, 0)
            .then(res => {
                setPatients(res.data.foundProfiles);
            })
            .catch(err => {
                console.log(err);
            });
    }

    return (
        <>
            <Layout>
                <div className="flex flex-start container-fluid h-100" style={{ backgroundColor: "#063348" }}>
                    <h2 className="page-title marsides2">Pacientes</h2>
                </div>
                <div className='flex flex-row flex-just-space-between flex-wrap' style={{ backgroundColor: "white" }}>
                    <div className='flex flex-row flex-just-space-start'>
                        <SearchBar baseUrl={`/patients`} onChange={onChangePatient}
                        />
                        <button
                            type="submit"
                            className="btn btn-primary martop2 marbottom2"
                            onClick={onSubmit}
                        >
                            Buscar
                        </button>
                    </div>
                    <Link to='/patients/profile/create/new'>
                        <button className='btn btn-primary marsides5 martop2 marbottom2'>
                            Agregar Paciente
                        </button>
                    </Link>
                </div>
                <div className="flex flex-start container-fluid px-5" style={{ backgroundColor: "white" }}>
                    <div className="row mx-2 mx-md-5 flex-grow">
                        {patients?.map((patient) => {
                            return (
                                <PatientCard
                                    id={patient.id}
                                    name={patient.generalInfo.name +  " " + patient.generalInfo.paternalLastName + " " + patient.generalInfo.maternalLastName}
                                    birthday={new Date(patient.generalInfo.birthday).toLocaleString('es-MX',{ year: 'numeric', month: '2-digit', day: '2-digit' })}
                                    phone={patient.generalInfo.phoneNumber.main}
                                    age={calculateAge(new Date(patient.generalInfo.birthday))}
                                    key={patient.id}
                                    link={true}
                                    url="/patients"
                                />
                            );
                        })}
                    </div>
                </div>


            </Layout>
        </>
    );
};

export default Patients;
