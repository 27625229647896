import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import PATIENT_SERVICE from '../services/patient';
import USER_SERVICE from '../services/user';
import RECORDS_SERVICE from '../services/records';
import PatientTreatmentCard from '../components/Patient/PatientTreatmentCard';
import PatientCard from '../components/Patient/PatientCard';
import AddRecordModal from '../components/Patient/AddRecordModal/AddRecordModal';
import PatientHistory from "../components/Patient/Digitalization/PatientHistory";
import "../Styles/MedicalRecordsHistory.css";

import { useFormik } from 'formik';
// import * as Yup from 'yup';

import { calculateAge } from "../utils/calculateAge";

const PatientProfile = () => {
  const { id } = useParams();
  const [, setUser] = useState({ files: [] });
  const [patient, setPatient] = useState({});
  const [records, setRecords] = useState([]);
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const dateValue = year+"-"+month+"-"+day;




  const formik = useFormik({
    initialValues: {
      dentist: "",
      treatment: "",
      clinic: "",
      initDate: dateValue,
      endDate: dateValue
    },
    // validationSchema: Yup.object({
    // TODO - Validation form
    // }),
    onSubmit: async ({ treatment, dentist, clinic, initDate, endDate }) => {
      const query = {
        treatment: {
          specialty: treatment,
          clinic,
          doctor: {
            name: dentist
          }
        }
      }

      try {
        const { data } = await RECORDS_SERVICE.SEARCH_RECORDS(id, query, new Date(initDate), new Date(endDate));
        setRecords(data);
      } catch (error) {
        console.error(error)
      }

    }
  })

  const userServiceGetOne = async (id) => {
    const { data } = await USER_SERVICE.GET_ONE(id);
    setUser(data);
  };

  const patientServiceGetOne = async (id) => {
    const { data } = await PATIENT_SERVICE.GET_ONE(id);
    setPatient(data);
  };

  useEffect(() => {
    try {
      userServiceGetOne(id);
      patientServiceGetOne(id);
    } catch (error) {
      console.log(error);
    }
    const getAllRecordsAtRender = async () => {
      const { data } = await RECORDS_SERVICE.GET_ALL_FROM_PATIENT_SIMPLE(id);
      setRecords(data);
    }

    getAllRecordsAtRender();
  }, [id]);

  const clinicsName = ['Clínica Dental Especializada', 'Clínica Dental de Posgrado', 'Clinica Dental Universitaria'];

  return (
    <Layout>
      <div className="row mx-2 mx-md-5">
        <PatientCard
          id={patient.id}
          name={patient.generalInfo?.name + " " + patient.generalInfo?.paternalLastName + " " + patient.generalInfo?.maternalLastName}
          birthday={new Date(patient.generalInfo?.birthday).toLocaleString('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' })}
          phone={patient.generalInfo?.phoneNumber?.main}
          age={calculateAge(new Date(patient.generalInfo?.birthday))}
          key={patient.id}
          link={true}
          url="/patients/profile"
        />
      </div>
      <hr />
      <div className="row mx-2 mx-md-5 mt-3">
        <div className="col-12 d-flex justify-content-between align-items-center">
          <h2>Historial del paciente</h2>
          <AddRecordModal id={id} />
        </div>

        <div className="row">
          {/* TODO - I think is a better idea to have form in a component */}
          <form className='p-3 border my-3'>
            <h4 className='mb-5 text-center'>Filtros de expedientes</h4>
            <div className="form-row">
              <div className="form-group col-4">
                <label htmlFor="">Dentista</label>
                <input
                  name="dentist"
                  className='form-control'
                  type="text"
                  id="dentist"
                  value={formik.values.dentist}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="form-group col-4">
                <label htmlFor="">Tratamiento</label>
                <input
                  name="treatment"
                  className='form-control'
                  type="text"
                  id="treatment"
                  value={formik.values.treatment}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="form-group col-4">
                <label htmlFor="clinic">Clinica</label>
                <select
                  className="form-control"
                  name="clinic"
                  id="clinic"
                  value={formik.clinic}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {clinicsName.map((clinicName, index) => {
                    return <option key={index}>{clinicName}</option>
                  })}
                </select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-6">
                <label htmlFor="">Fecha de inicio</label>
                <input
                  name="initDate"
                  className='form-control'
                  type="date"
                  id="initDate"
                  value={formik.values.initDate}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="">Fecha de fin</label>
                <input
                  name="endDate"
                  className='form-control'
                  type="date"
                  id="endDate"
                  value={formik.values.endDate}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <button className='form-control btn btn-primary' type="button" style={{ width: '80%' }} onClick={formik.handleSubmit}> Aplicar filtros </button>
            </div>
          </form>
          <PatientHistory
            id={id}
            fotos={patient.files}
          />
          {/*  */}
          <div className="row justify-content-start">
            {
              records?.map(record => {
                return (
                  <div key={record.id} className="col-lg-4 col-12">
                    <PatientTreatmentCard
                      id={id}
                      recordId={record.id}
                      dentist={record.treatment.doctor.name}
                      clinic={record.treatment.clinic}
                      date={record.createdAt.substring(0, 10)}
                      procedure={record.treatment.specialty}
                      childs={record.relatedMedicalRecords}
                    />
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PatientProfile;
