import React, { useState, useEffect, useContext } from 'react';
import CLINIC_SERVICE from '../services/clinic';
import { UserContext } from "../context/context";
import ClinicCard from '../components/Clinic/ClinicCard';
import Layout from '../components/Layout/Layout';

const Clinics = () => {
    const [clinics, setClinics] = useState([])
    const userContext = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);

    const clinicServiceGetAll = async () => {
        const { data } = await CLINIC_SERVICE.GET_ALL();
        setClinics(data);
        setIsLoading(false);
    }

    useEffect(() => {
        setIsLoading(true);
        clinicServiceGetAll();
    }, [userContext]);

    return (
        <Layout>
            <div className="flex flex-start container-fluid h-100" style={{ backgroundColor: "#063348" }}>
                <h2 className="page-title marsides2">Clínicas</h2>
            </div>
            <div className="flex flex-start container-fluid px-5" style={{ backgroundColor: "white" }}>
                <div className="row">
                    {clinics.map((clinic) => {
                        return !isLoading ? (
                            <ClinicCard key={clinic._id}
                                id={clinic._id}
                                name={clinic.name}
                                address={clinic.address}
                                phone={clinic.phone}
                            />
                        )
                            : null
                    }
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default Clinics;
