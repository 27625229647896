import React, { useState } from "react";
import Modal from "react-modal";

import RegisterPatient from "./RegisterPatientModal";
import PatientWithAccount from "./PatientWithAccountModal";

const IsPatientRegistered = (props) => {
  const [isRegisterPatientModalOpen, setisRegisterPatientModalOpen] = useState(
    false
  );
  const [
    isPatientWithAccountModalOpen,
    setIsPatientWithAccountModalOpen,
  ] = useState(false);
  const id = props.idAppointment;
  Modal.setAppElement("body");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "0",
      boxShadow: "0 1rem 3rem rgba(0,0,0,.175)",
      padding: "4%",
    },
  };

  const showRegisterPatientModal = () => {
    props.onRequestClose();
    setisRegisterPatientModalOpen(true);
  };
  const closeRegisterPatientModal = () => {
    setisRegisterPatientModalOpen(false);
  };

  const showPatientWithAccountModal = () => {
    props.onRequestClose();
    setIsPatientWithAccountModalOpen(true);
  };
  const closePatientWithAccountModal = () => {
    setIsPatientWithAccountModalOpen(false);
  };

  function afterOpenModal() {}
  return (
    <>
      <PatientWithAccount
        isOpen={isPatientWithAccountModalOpen}
        onRequestClose={closePatientWithAccountModal}
        idAppointment={id}
      ></PatientWithAccount>

      <RegisterPatient
        isOpen={isRegisterPatientModalOpen}
        onRequestClose={closeRegisterPatientModal}
        idAppointment={id}
      ></RegisterPatient>

      <Modal
        isOpen={props.isOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={props.onRequestClose}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="">
          <button
            type="button"
            className="close"
            onClick={props.onRequestClose}
          >
            x
          </button>
          <h1>Registro del paciente</h1>
          <form>
            <div className="form-group">
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-primary btn-lg btn-block mt-4"
                  onClick={showPatientWithAccountModal}
                >
                  Paciente con cuenta
                </button>
              </div>

              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-primary btn-lg btn-block mt-4"
                  onClick={showRegisterPatientModal}
                >
                  Paciente sin cuenta
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};
export default IsPatientRegistered;
