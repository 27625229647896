import React from 'react';
import {Link} from 'react-router-dom';
import USER_SERVICE from '../../services/user'

const PatientCard = ({id, name, surname, email, link, url}) => {

    function deleteReceptionist() {
        USER_SERVICE.DELETE(id);
        window.location.reload();
    }

    return (
        <>
            <div className="col-12 px-0 pb-4 pt-4">
                <div className="card">
                    <div className="card-header" >
                        <h4 className="card-title">{name} {surname}</h4>
                    </div>
                    <div className="card-body d-flex justify-content-between">
                        <div className="">
                            <p><strong>Correo electrónico:</strong> {email}</p>
                        </div>
                        <div className="d-flex justify-content-end align-items-end">
                            <Link
                                to={`${url}/reset/${id}`}
                                className="btn btn-primary ml-0"
                                style={{maxHeight: '40px', borderRadius: '15px'}}
                            >
                                Modificar Contraseña
                            </Link>
                            <Link
                                to={`${url}`}
                                onClick={deleteReceptionist}
                                className="btn btn-secondary ml-3"
                                style={{maxHeight: '40px', borderRadius: '15px'}}
                            >
                                Eliminar Recepcionista
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PatientCard;
