import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

import { UserContext } from "../../context/context";

const ProtectedRoute = ({ children, roles = [], path, exact, ...rest }) => {
  const userContext = useContext(UserContext);
  const { loggedUser } = userContext;
  if (!userContext.isInitialized) {
    return null;
  } else if (!userContext.loggedUser) {
    return <Redirect to="/login" />;
  } else if (
    roles.length > 0 &&
    !roles.find((role) => role === loggedUser.role)
  ) {
    return <Redirect to="/403" />;
  }
  return (
    <Route path={path} exact={exact} {...rest}>
      {children}
    </Route>
  );
};

export default ProtectedRoute;
