import React from 'react';
import "../../../Styles/ProcedureCard.css";
import { Link } from 'react-router-dom';
import PROCEDURE_SERVICE from '../../../services/procedure';


const ProcedureCard = ({ id, name, description, cost, duration, category }) => {
  const handleDelete = async () => {
    const response = await PROCEDURE_SERVICE.DELETE(id);
    if (response.status === 200) {
      window.location.reload();
    }
    
    return response;
  };

  return (
    <>
      <div className="col col-12 col-md-6 col-lg-4 my-2 pt-3">
        <div className="card">
          <Link to={`/procedures/${id}`}>
            <div className="card-header">
              <h4 className="card-title">{name}</h4>
            </div>
          </Link>
          <div className="card-body">
            <p><strong>Descripción:</strong> {description}</p>
            <p><strong>Costo:</strong> ${cost}</p>
            <p><strong>Duración:</strong> {duration}min</p>
            <p><strong>Categoría:</strong> {category}</p>
            <div className="d-flex justify-content-end">
              <button className="btn btn-danger align-left" onClick={handleDelete}>
                Eliminar
              </button>
              <Link to={`/procedures/${id}`} className="btn btn-primary modify">
                Modificar
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProcedureCard;
