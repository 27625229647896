import React, { useRef } from "react";

const CapturePhoto = () => {
  const videoRef = useRef(null);
  const photoRef = useRef(null);
  const stripRef = useRef(null);


  const getVideo = async () => {
    try {
      const { data } = await navigator.mediaDevices.getUserMedia({ video: { height: 1, width: 1 } });
      let video = videoRef.current;
      video.srcObject = data;
      video.play();
    } catch (error) {
      console.log(error);
    };
  };

  const paintToCanvas = () => {
    let video = videoRef.current;
    let photo = photoRef.current;
    let ctx = photo.getContext("2d");

    const width = 400;
    const height = "auto";
    photo.width = width;
    photo.height = height;

    return setInterval(() => {
      ctx.drawImage(video, 0, 0, width, height);
    }, 50);
  };

  const takePhoto = () => {
    let photo = photoRef.current;
    let strip = stripRef.current;

    const data = photo.toDataURL("image/jpeg");

    const link = document.createElement("a");
    link.href = data;
    link.setAttribute("download", "myWebcam");
    link.innerHTML = `<img src='${data}' alt='thumbnail'/>`;
    strip.insertBefore(link, strip.firstChild);
  };

  const stop = () => {
    let video = videoRef.current;
    if (video.srcObject != null) {

      const stream = video.srcObject;
      const tracks = stream.getTracks();

      for (let i = 0; i < tracks.length; i++) {
        let track = tracks[i];
        track.stop();
      }
      video.srcObject = null;
    }
  }

  return (
    <div>
      <br></br>
      <button onClick={() => getVideo()}>Activar Camara</button>
      <button onClick={() => stop()}>Detener Camara</button>
      <br></br>
      <button onClick={() => takePhoto()}>Tomar Foto</button>
      <br></br>
      <video onCanPlay={() => paintToCanvas()} ref={videoRef} />
      <canvas ref={photoRef} />
      <div>
        <div ref={stripRef} />
      </div>
    </div>
  );
};

export default CapturePhoto;
