/* eslint-disable no-undef */
import axios from '../config/axios';

const APPOINTMENT_SERVICE = {
  GET_ONE: async (id) => {
    return await axios
      .get(`/appointments/${id}`)
      .catch((e) => {
        console.log('Error', e.message);
      });
  },

  GET_ALL: async () => {
    return await axios.get('/appointments/');
  },

  GET_BY_DATE: async (from, to) => {
    return await axios.get(`/appointments/date?from=${from}&to=${to}`);
  },

  GET_ALL_USER: async (id) => {
    return await axios
      .get(`/appointments/patient/${id}`)
      .catch((e) => {
        console.log('Error', e.message);
      });
  },

  GET_DATA_SCAN: async (id) => {
    return await axios.get(`/appointments/scan/${id}`);
  },

  GET_EXPRESS: async (date, clinicId, category) => {
    return await axios.get(`/appointments/express/${date}/${clinicId}/${category}`);
  },

  GET_ALL_DENTIST: async (id) => {
    return await axios.get(`/appointments/dentist/${id}`);
  },

  GET_ALL_DENTIST_DATE: async (id, date) => {
    return await axios.get(`/appointments/date/${id}/${date}`);
  },

  GET_ATTENDED_APPOINTMENTS: async (from, to) => {
    return await axios.get(`/appointments/date/?attended=true&from=${from}${to ? `&to=${to}` : ""}`);
  },

  CREATE: async (data) => {
    return await axios.post('/appointments/', data);
  },

  DELETE: async (id) => {
    return await axios.delete(`/appointments/${id}`);
  },

  UPDATE: async (id, data) => {
    return await axios.put(`/appointments/${id}`, data);
  },

  GET_ID: async (id, data) => {
    return await axios.put(`/appointments/push/${id}`, data);
  },

  CANCEL_DAY: async (dentistId, date) => {
    return await axios.delete(`/appointments/cancelDay/${dentistId}/${date}`);
  },

  DIFFER_APPOINTMENT: async (appointmentId) => {
    return await axios.delete(`/appointments/differ/${appointmentId}`);
  },
};

export default APPOINTMENT_SERVICE;
