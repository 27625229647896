import React, { useEffect, useState, useContext } from "react";
import USER_SERVICE from "../../services/user";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context/context";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { useFormik } from "formik";
import * as Yup from "yup";

registerLocale("es", es);

const EditPatientForm = ({ id }) => {
  const [patient, setPatient] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const userContext = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [date, setDate] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const countryCodes = [
    {
      value: "+52",
      label: "+52 (México)",
    },
    {
      value: "+1",
      label: "+1 (EUA)",
    },
  ];

  const [checkedState, setCheckedState] = useState([
    {
      method: "Email",
      label: "Correo electrónico",
      state: true,
    },
    {
      method: "SMS",
      label: "Mensaje de texto",
      state: false,
    },
    {
      method: "WhatsApp",
      label: "WhatsApp",
      state: false,
    },
  ]);
  const [contactMethods, setContactMethods] = useState({
    email: true,
    sms: false,
    whatsapp: false,
  });
  //Handle contact methods
  const handleInputChange = (event) => {
    let value = event.target.value.toLowerCase();

    if (value === "sms") {
      contactMethods.sms = true;
      contactMethods.whatsapp = false;
    } else {
      contactMethods.whatsapp = true;
      contactMethods.sms = false;
    }
    setContactMethods(contactMethods);
    setCheckedState(checkedState);
  };

  useEffect(() => {
    const userServiceGetOne = async () => {
      let { data } = await USER_SERVICE.GET_ONE(id);
      setPatient(data);
      setDate(new Date(data.birthday));
      setContactMethods(data.contactMethods);
      let phone = data.phone.slice(data.phone.length - 10, data.phone.length);
      let code = data.phone.slice(0, data.phone.length - 11);
      setCellphone(phone);
      setCountryCode(code);
    };
    userServiceGetOne(id);
  }, [id]);

  useEffect(() => {
    const setInitialMethods = () => {
      let check;
      check = checkedState;
      for (let opt of checkedState) {
        if (contactMethods[opt.method.toLowerCase()] === true) opt.state = true;
      }
      setCheckedState(check);
    };
    setIsLoading(true);
    setInitialMethods();
    setIsLoading(false);
  }, [contactMethods, checkedState]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: patient.name || "",
      surname: patient.surname || "",
      birthday: patient.birthday || "",
      countryCode: countryCode || "+52",
      cellphone: cellphone || "",
      contactMethods: patient.contactMethods || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Nombre requerido."),
      surname: Yup.string().required("Apellido requerido."),
      birthday: Yup.string().test(
        "req-birthday",
        "Una fecha de nacimiento es requerida.",
        (val) => val !== ""
      ),
      cellphone: Yup.string()
        .required("Un teléfono es requerido.")
        .matches(/^[0-9]{10}$/gi, "Teléfeno debe ser 10 digitos de largo."),
    }),
    onSubmit: async (values) => {
      values.phone = values.countryCode + "1" + values.cellphone;
      values.birthday = date;
      if (patient.id) {
        // EDIT
        try {
          await USER_SERVICE.UPDATE(id, values);
          userContext.changeSate();
          history.push(`/patient/myprofile/${id}`);
        } catch (error) {
          setErrorMessage("Ocurrió un error");
        }
      }
    },
  });

  return (
    <>
      <div className="container-sm pt-3 mt-3">
        <div className="row px-2">
          <div className="col col-sm-9 col-md-8 col-lg-6 my-3 card mx-auto p-4">
            <h2 className="text-center font-weight-bold mt-1 mb-3">
              Editar mi perfil
            </h2>
            <form>
              <div className="form-group">
                <label htmlFor="name">Nombre</label>
                <input
                  type="text"
                  id="name"
                  placeholder="Nombre"
                  className={`form-control form-control-lg ${
                    formik.errors.name && formik.touched.name
                      ? "is-invalid"
                      : ""
                  }`}
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.name && formik.touched.name && (
                  <div className="invalid-feedback">{formik.errors.name}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="surname">Apellido</label>
                <input
                  type="text"
                  id="surname"
                  placeholder="Apellido"
                  className={`form-control form-control-lg ${
                    formik.errors.surname && formik.touched.surname
                      ? "is-invalid"
                      : ""
                  }`}
                  name="surname"
                  value={formik.values.surname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.surname && formik.touched.surname && (
                  <div className="invalid-feedback">
                    {formik.errors.surname}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="birthday">*Fecha de nacimiento</label>
                <br />
                <DatePicker
                  autoComplete={"off"}
                  customInput={
                    <input className="form-control" name="birthday" />
                  }
                  className={`${
                    formik.errors.birthday && formik.touched.birthday
                      ? "is-invalid"
                      : ""
                  }`}
                  locale="es"
                  maxDate={new Date()}
                  showYearDropdown
                  yearDropdownItemNumber={50}
                  scrollableYearDropdown
                  id="birthday"
                  name="birthday"
                  placeholderText="dd/mm/aaaa"
                  selected={date}
                  onChange={(rawDate) => {
                    formik.values.birthday = rawDate;
                    setDate(rawDate);
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.birthday && formik.touched.birthday && (
                  <div
                    className=""
                    style={{ color: "#e74a3b", fontSize: "13px" }}
                  >
                    {formik.errors.birthday}
                  </div>
                )}
              </div>
              <div>
                <label htmlFor="cellphone">*Número de teléfono</label>
              </div>
              <div className="form-row">
                <div className="form-group col-md-3">
                  <select
                    className={`form-control custom-select ${
                      formik.errors.countryCode && formik.touched.countryCode
                        ? "is-invalid"
                        : ""
                    }`}
                    name="countryCode"
                    value={formik.values.countryCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {countryCodes.map((cc) => {
                      return (
                        <option key={cc.value} value={cc.value}>
                          {cc.label}
                        </option>
                      );
                    })}
                    <option>...</option>
                  </select>
                  {formik.errors.countryCode && formik.touched.countryCode && (
                    <div className="invalid-feedback">
                      {formik.errors.countryCode}
                    </div>
                  )}
                </div>
                <div className="form-group col-md-9">
                  <input
                    type="tel"
                    className={`form-control cellphone ${
                      formik.errors.cellphone && formik.touched.cellphone
                        ? "is-invalid"
                        : ""
                    }`}
                    id="cellphone"
                    name="cellphone"
                    value={formik.values.cellphone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Número telefónico"
                  />
                  {formik.errors.cellphone && formik.touched.cellphone && (
                    <div className="invalid-feedback">
                      {formik.errors.cellphone}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <p>*Métodos de contacto</p>
                <div className="row text-center">
                  {Object.keys(checkedState).map((item, index) => {
                    if (checkedState[item].state === true) {
                      return (
                        !isLoading &&
                        index >= 1 && (
                          <div className="form-check col" key={index}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="contactMethods"
                              id={checkedState[item].method}
                              value={checkedState[item].method}
                              onChange={handleInputChange}
                              defaultChecked
                            />
                            <label
                              className="form-check-label"
                              htmlFor={checkedState[item].method}
                            >
                              {checkedState[item].label}
                            </label>
                          </div>
                        )
                      );
                    } else {
                      return (
                        !isLoading &&
                        index >= 1 && (
                          <div className="form-check col" key={index}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="contactMethods"
                              id={checkedState[item].method}
                              value={checkedState[item].method}
                              onChange={handleInputChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={checkedState[item].method}
                            >
                              {checkedState[item].label}
                            </label>
                          </div>
                        )
                      );
                    }
                  })}
                </div>
              </div>

              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-primary w-100 m-0"
                  onClick={formik.handleSubmit}
                >
                  Guardar
                </button>
              </div>
              {errorMessage && (
                <div className="text-danger">{errorMessage}</div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPatientForm;
