import React from 'react';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

const TakePhoto = ({ setPhoto }) => {
  const handleTakePhoto = (dataUri) => {
    setPhoto(dataUri);
  }
  return (
    <>
      <Camera
        onTakePhoto={(dataUri) => { handleTakePhoto(dataUri)}}
      />
    </>
  );
};

export default TakePhoto;
