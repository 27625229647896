import axios from '../config/axios';

const RECORDS_SERVICE = {



    SEARCH_RECORDS: async (idProfile, query, initDate, finalDate) => {
        return await axios.get(
            `/patient-profiles/search/${idProfile}/medicalrecords`,
            {
                params: {
                    dentist: query.treatment.doctor.name,
                    treatment: query.treatment.specialty,
                    clinic: query.treatment.clinic,
                    startDate: initDate,
                    endDate: finalDate,
                },
            }
        );
    },

    GET_PARENT_RECORDS: async (idProfile) => {
        //console.log('parent records')
        return await axios.get(
            `/patient-profiles/parents/${idProfile}/medicalrecords`,
        );
    },


    GET_ALL_FROM_PATIENT: async (idPatient, page, limit, searchParam, date) => {

        return await axios.get(
            `/patient-profiles/${idPatient}/medicalrecords`,
            {
                params: {
                    page,
                    limit,
                    searchParam: searchParam,
                    createdAt: date
                },
            }
        );
    },
    GET_ALL_FROM_PATIENT_SIMPLE: async (idPatient) => {

        return await axios.get(
            `/patient-profiles/${idPatient}/medicalrecords`,
            {
                params: {
                    simple: "simple",
                },
            }
        );
    },
    CREATE_WITH_PARENT: async (idPatient, data,parentId) => {
        console.log(parentId);
        return await axios.post(`/patient-profiles/${idPatient}/medicalrecords`, data,
            {
                params: {
                    parentId
                }
            });
    }
    ,
    CREATE: async (idPatient, data) => {
        return await axios.post(`/patient-profiles/${idPatient}/medicalrecords`, data,
            {
                params: {
                    parentId: data.id
                }
            });
    },

    GET_ONE_FROM_PATIENT: async (idPatient, idRecord) => {
        return await axios.get(`/patient-profiles/${idPatient}/medicalrecords/${idRecord}`);
    },

    MODIFY: async (idPatient, idRecord, data) => {
        return await axios.patch(`/patient-profiles/${idPatient}/medicalrecords/${idRecord}`, data);
    }
}

export default RECORDS_SERVICE;