import TakePhoto from "./TakePhoto"
import { useState, useEffect } from "react";
import PROFILE_SERVICE from "../../../services/profile";
import { useFormik } from "formik";
import * as Yup from 'yup'
import CLINIC_SERVICE from "../../../services/clinic";
import { faFileImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router";

const UploadImage = () => {

    const [cameraActive, setCameraActive] = useState(false);
    const [selectFile, setSelectFile] = useState(false);
    const [photo, setPhoto] = useState('');
    const [message, setMessage] = useState('');
    const [clinics, setClinics] = useState([]);
    const [dentists, setDentists] = useState([]);
    const [procedures, setProcedures] = useState([]);
    const [extension, setExtension] = useState('');

    const history = useHistory();

    useEffect(() => {
        let mounted = true;

        const retrieveClinics = async () => {
            const { data } = await CLINIC_SERVICE.GET_ALL();
            setClinics(data);
        }

        if (mounted) retrieveClinics();

        return () => mounted = false;
    }, []);

    const handleChange = (e) => {
        const name = e.target.name;
        const id = e.target.value;

        if (name === 'clinic') {
            formik.setFieldValue('clinic', id);
            const clinic = clinics.find(el => el._id === id)
            setDentists(clinic.dentists)
        } else if (name === 'dentist') {
            formik.setFieldValue('dentist', id);
            const dentist = dentists.find(el => el.idUser.id === id)
            setProcedures(dentist.categories)
        }
    }

    const handleUploadFile = async (e) => {
        let file = e.target.files[0];
        const type = file.name.substr(file.name.lastIndexOf('.') + 1, file.name.length);
        file = await toBase64(file)

        setExtension(type)
        setPhoto(file)
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const formik = useFormik({
        initialValues: {
          clinic: "",
          dentist: "",
          procedure: "",
          date: ''
        },
        validationSchema: Yup.object({
            clinic: Yup.string().required("Clinica es requerido."),
            dentist: Yup.string().required("Dentista es requerido."),
            procedure: Yup.string().required("Procedimiento es requerida."),
            date: Yup.string().required("Fecha es requerida."),
        }),
        onSubmit: async ({ clinic, dentist, procedure, date }) => {

            let data = new FormData();
            const blob = await fetch(photo).then((res) => res.blob());
            data.append('file', blob, `documento.${extension}`);
            data.append('clinic', clinic);
            data.append('dentist', dentist);
            data.append('category', procedure);
            data.append('date', new Date(date));

            let rawUrl = window.location.href;
            rawUrl = rawUrl.split('/')
            const urlSize = rawUrl.length;
            const id = rawUrl[urlSize - 1];

            try {
              await PROFILE_SERVICE.SAVE_FILE({ id, data });
              setMessage('Imagen guardada con éxito!');

              setTimeout(() => {
                setMessage('');
                history.go(0);
              }, 2000);
            } catch (error) {
              console.log('error', error)
            }
        }
    });

    const handleCameraActive = () => {
        setCameraActive(!cameraActive)
    }

    const handlePhotoOnDelete = () => {
    // setUploaded(false)
        return setPhoto("");
    }

    const handleSelectFile = () => {
        setSelectFile(true)
    }

    return (
        <>
            <div className="modal fade" id="photoModal" tabIndex="-1" aria-labelledby="photoModal" aria-hidden="true">
                <div className={ `modal-dialog modal-${ selectFile ? 'lg' : 'xl' }` } style={{ height: '80vh' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Expediente digital</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12 text-center my-4">
                                { selectFile &&
                                    <div className="d-flex flex-column justify-content-center">
                                        <label htmlFor="file">Documento</label>
                                        <input
                                            className="form-control"
                                            type="file"
                                            name="file"
                                            id="file"
                                            onChange={handleUploadFile}
                                        />
                                    </div>
                                }
                            </div>
                            <div className="col-12 text-center">
                                { (!photo && cameraActive) ?
                                    <TakePhoto setPhoto={setPhoto} />
                                    : <img src={photo} alt="" width="70%"/>
                                }
                            </div>
                            <div className="col-12 text-center">
                                { (!photo && !cameraActive && !selectFile) &&
                                    <div className="border d-flex justify-content-around align-items-center" style={{ minHeight: '50vh' }}>
                                        <div className="border p-4" style={{ cursor: 'pointer' }}>
                                            <FontAwesomeIcon icon={faFileImage} onClick={handleSelectFile} size="5x" />
                                            <p className="mt-2">Subir existente</p>
                                        </div>
                                        <div className="border p-4" style={{ cursor: 'pointer' }}>
                                            <FontAwesomeIcon icon={faFileImage} onClick={handleCameraActive} size="5x" />
                                            <p className="mt-2">Capturar fotografía</p>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="row">
                                <div className="col-10">
                                    <form action="">
                                        <div className="form-row d-flex align-items-center justify-content-between mt-3">
                                            <div className="form-group">
                                                <label htmlFor="">Clinica</label>
                                                <select
                                                    className="form-control"
                                                    name="clinic"
                                                    id="clinc"
                                                    value={formik.values.clinic}
                                                    onChange={(handleChange)}
                                                    onBlur={formik.handleBlur}
                                                >
                                                    <option defaultValue>Elige...</option>
                                                    { clinics.map((cli, index) =>
                                                        <option value={cli._id} key={index}>{cli.name}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="">Dentista</label>
                                                <select
                                                    className="form-control"
                                                    name="dentist"
                                                    id="denstis"
                                                    value={formik.values.dentist}
                                                    onChange={(handleChange)}
                                                    onBlur={formik.handleBlur}
                                                >
                                                    <option defaultValue>Elige...</option>
                                                    { dentists.map((den, index) =>
                                                        <option value={den.idUser.id} key={index}>{den.idUser.name}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="">Procedimiento</label>
                                                <select
                                                    className="form-control"
                                                    name="procedure"
                                                    id="procedure"
                                                    value={formik.values.procedure}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                >
                                                    <option defaultValue>Elige...</option>
                                                    { procedures.map((pro, index) =>
                                                        <option value={pro} key={index}>{pro}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="">Fecha</label>
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    name="date"
                                                    value={formik.values.date}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </div>

                                        </div>
                                    </form>
                                    { message && (
                                        <div className="row mt-4">
                                            <div className="alert alert-success" role="alert">
                                                { message }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={handleCameraActive} data-dismiss="modal">Regresar</button>
                            { photo &&  <button type="button" className="btn btn-secondary" onClick={handlePhotoOnDelete}>Borrar imagen</button>}
                            {/* { !cameraActive &&  <button type="button" className="btn btn-secondary" onClick={handleCameraActive}>Activar camara</button>} */}
                            <button type="button" className="btn btn-primary" onClick={formik.handleSubmit} >Guardar documento</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default UploadImage;