import axios from '../config/axios';

const SCHEDULE_SERVICE = {
  GET_ALL: async (procedure, day, idClinic) => {
    return await axios.get(`/schedules/match/${procedure}/${day}`,
      {
        params: {
          idClinic
        }
      });
  },
  GET_ALL_DENTIST: async (idDentist, day) => {
    return await axios.get(`/schedules/match/recurrent/${idDentist}/${day}`);
  },
};

export default SCHEDULE_SERVICE;
