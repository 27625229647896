import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from "../context/context";
import Layout from '../components/Layout/Layout';
import PriceTable from '../components/Prices/PriceTable';
import PROCEDURE_SERVICE from '../services/procedure';


const Prices = () => {
    const [procedures, setProcedures] = useState([]);
    const userContext = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);

    const procedureServiceGetCategories = async () => {
      const { data } = await PROCEDURE_SERVICE.GET_CATEGORIES();
      setProcedures(data);
      setIsLoading(false);
    }

    useEffect(() => {
        setIsLoading(true);
        procedureServiceGetCategories();
    }, [userContext]);

    return (
        <Layout>
            <div className="flex flex-start container-fluid h-100" style={{backgroundColor:"#063348"}}>
                <h2 className="page-title marsides2">Servicios</h2>
            </div>
            <div className="container" style={{"textAlign": "center", "marginTop" : "30px"}}>
              <p>* Los precios están sujetos a evaluación del dentista.</p>
            </div>
            <div className="container">

              {procedures.map((category, idx) => {
                return !isLoading ? (
                  <PriceTable category={category} key={idx} />
                  ) : null ; }
              )}

            </div>
          </Layout>
      );
};

export default Prices;
