import axios from '../config/axios';

const MINOR_SERVICE = {
  GET_ONE: async (id) => {
    return await axios.get(`/minors/${id}`);
  },

  GET_ALL: async () => {
    return await axios.get('/minors/');
  },

  CREATE: async (data) => {
    return await axios.post('/minors/', data);
  },

  DELETE: async (id) => {
    return await axios.delete(`/minors/${id}`);
  },

  UPDATE: async (id, data) => {
    return await axios.put(`/minors/${id}`, data);
  },
};

export default MINOR_SERVICE;
