import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/ion-logo.png";
import scan from "../../assets/svg/scan.svg";
import teeth from "../../assets/svg/teeth.svg";

const PacientArrival = () => {
  return (
    <Fragment>
      <Link to="/receptionist">
        <div className="container-fluid d-flex align-items-center justify-content-around">
          <img
            className="d-none d-md-block "
            src={logo}
            alt=""
            style={{ height: "200px" }}
          />
        </div>
      </Link>

      <br />
      <br />

      <div className="container-fluid d-flex align-items-center justify-content-around">
        <div className="align-items-center">
          <Link to="/receptionist/appointments/qr">
            <div className="text-center">
              <button type="button" className="btn btn-secondary btn-lg">
                Escanear QR
              </button>
            </div>

            <img
              className="d-none d-md-block "
              src={scan}
              alt=""
              style={{ height: "400px" }}
            />
          </Link>
        </div>
        <div>
          <Link to="/receptionist/appointments/new">
            <div className="text-center">
              <button type="button" className="btn btn-secondary btn-lg">
                No tengo cita
              </button>
            </div>

            <img
              className="d-none d-md-block "
              src={teeth}
              alt=""
              style={{ height: "420px" }}
            />
          </Link>
        </div>
      </div>
    </Fragment>
  );
}

export default PacientArrival;
