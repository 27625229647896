import React, { useState, useEffect, useContext } from "react";
import Layout from "../components/Layout/Layout";
import { UserContext } from "../context/context";
import AppointmentTable from "../components/Appointment/AppointmentTable";
import APPOINTMENT_SERVICE from "../services/appointment";
import CLINIC_SERVICE from "../services/clinic";
import DENTIST_SERVICE from "../services/dentist";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import "../Styles/AppointmentsTable.css";

const Appointments = () => {
  const [clinics, setClinics] = useState([]);
  const userContext = useContext(UserContext);
  const [appointments, setAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [dayCount, setDayCount] = useState(0);
  const [selectedClinic, setSelectedClinic] = useState("");
  const [selectedDentist, setSelectedDentist] = useState("");
  const [dentistsOptions, setDentistsOptions] = useState([]);

  // Get clinics max and min to display in table
  const [minHour, setMinHour] = useState(800);
  const [maxHour, setMaxHour] = useState(1700);

  // Get filtered appointments
  const [filteredAppointments, setFilteredAppointments] = useState([]);

  const getClinicHours = async () => {
    // Get for all clinics
    if (selectedClinic === "") {
      let minHours = [];
      let maxHours = [];
      for (let clinic of clinics) {
        minHours.push(clinic.start);
        maxHours.push(clinic.end);
      }
      setMaxHour(Math.ceil(Math.max(...maxHours) / 100) * 100);
      setMinHour(Math.floor(Math.min(...minHours) / 100) * 100);
    } else {
      // Get hours for specific clinic
      const { data } = await CLINIC_SERVICE.GET_ONE(selectedClinic);
      setMaxHour(Math.ceil(data.end / 100) * 100);
      setMinHour(Math.floor(data.start / 100) * 100);
    }
  };

  const clinicServiceGetAll = async () => {
    const { data } = await CLINIC_SERVICE.GET_ALL();
    setClinics(data);
  };

  const dentistServiceGetAll = async () => {
    const { data } = await DENTIST_SERVICE.GET_ALL();
    setDentistsOptions(data);
  };

  const changedValue = async (values) => {
    try {
      setFrom(new Date(values.value[0]));
      setTo(new Date(values.value[1]));
    } catch {
      setFrom(new Date());
      setTo(new Date());
    }
  };

  const handleClinicChange = (event) => {
    setSelectedClinic(event.target.value);
  };

  const handleDentistChange = (event) => {
    setSelectedDentist(event.target.value);
  };

  useEffect(() => {
    const getDenstistsByClinic = async () => {
      try {
        const { data } = await CLINIC_SERVICE.GET_ONE(selectedClinic);
        setDentistsOptions(data.dentists);
      } catch (error) {
        console.error(error);
      }
    };

    // Set dentists acording to clinics
    if (selectedClinic !== "") {
      getDenstistsByClinic(selectedClinic);
    } else {
      dentistServiceGetAll();
    }
  }, [selectedClinic]);

  const filterAppointments = () => {
    if (selectedDentist === "" && selectedClinic === "") {
      setFilteredAppointments(appointments);
    } else if (selectedDentist !== "" && selectedClinic === "") {
      let tempAppointments = [];
      for (let app of appointments) {
        if (app.dentist.id === selectedDentist) tempAppointments.push(app);
      }
      setFilteredAppointments(tempAppointments);
    } else if (selectedDentist === "" && selectedClinic !== "") {
      let tempAppointments = [];
      for (let app of appointments) {
        if (app.clinic._id === selectedClinic) tempAppointments.push(app);
      }
      setFilteredAppointments(tempAppointments);
    } else {
      let tempAppointments = [];
      for (let app of appointments) {
        if (app.clinic._id === selectedClinic && app.dentist.id === selectedDentist) tempAppointments.push(app);
      }
      setFilteredAppointments(tempAppointments);
    }
  }

  useEffect(() => {
    const appointmentServiceGetByDate = async () => {
      var newFrom =
        from.getMonth() + 1 + "-" + from.getDate() + "-" + from.getFullYear();
      var newTo =
        to.getMonth() + 1 + "-" + to.getDate() + "-" + to.getFullYear();
      try {
        const { data } = await APPOINTMENT_SERVICE.GET_BY_DATE(newFrom, newTo);
        setAppointments(data.appointments);
      } catch (error) {
        console.error(error);
      }
    };
    setIsLoading(true);
    appointmentServiceGetByDate();
    clinicServiceGetAll();
    dentistServiceGetAll();
    filterAppointments();
    setDayCount(Math.floor((to.getTime() - from.getTime()) / (1000 * 3600 * 24) + 1));
    setStartDate(
      new Date(from.getFullYear(), from.getMonth(), from.getDate()
      )
      );

    getClinicHours();
    setIsLoading(false);
  }, [from, to, userContext]);
  /*eslint react-hooks/exhaustive-deps: */
  useEffect(() => {
    filterAppointments();
  }, [selectedClinic, selectedDentist, appointments]);

  useEffect(() => {
    getClinicHours();
  }, [selectedClinic, clinics]);

  return (
    <>
      <Layout>
        <div
          className="flex flex-start container-fluid h-100"
          style={{ backgroundColor: "#063348" }}
        >
          <h2 className="page-title marsides2">Citas</h2>
        </div>
        <div className="container">
          <div className="flex flex-row flex-just-space-between flex-wrap">
            <div></div>
            <div className="martop2">
              <label>Elija un rango</label>
              <DateRangePickerComponent
                placeholder="Elija un rango"
                startDate={from}
                endDate={to}
                format="dd-MM-yyyy"
                onChange={changedValue}
              ></DateRangePickerComponent>
            </div>
            <select
              className="martop2 marbottom2"
              id="clinic"
              name="clinic"
              onChange={handleClinicChange}
            >
              <option value="">Todas las clínicas</option>
              {clinics.map((clinic) => {
                return !isLoading ? (
                  <option value={clinic._id}>{clinic.name}</option>
                ) : null;
              })}
            </select>
            <select
              className="martop2 marbottom2"
              id="dentists"
              name="dentists"
              onChange={handleDentistChange}
            >
              <option value="">Todos los dentistas</option>
              {dentistsOptions.map((dentist) => {
                return !isLoading && dentistsOptions.length !== 0 ? (
                  <option value={dentist.idUser.id}>
                    {dentist.idUser.name} {dentist.idUser.surname}
                  </option>
                ) : null;
              })}
            </select>
          </div>
        </div>
        <div className="container-fluid">
          <AppointmentTable
            appointments={filteredAppointments}
            startDate={startDate}
            dayCount={dayCount}
            startHour={minHour}
            endHour={maxHour}
          />
        </div>
      </Layout>
    </>
  );
};

export default Appointments;
