import React from "react";

const NoAppointment = (props) => {

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "0",
      boxShadow: "0 1rem 3rem rgba(0,0,0,.175)",
      padding: "3%",
      borderRadius: "30px",
    },
  };

  if (typeof props.data === "string") {
    console.log("No hay dentistas desde no appointment");
    return <h1>No hay dentistas disponibles en esta clínica para este día</h1>;
  } else {
    return (
      <div style={customStyles}>
        <h1>En un momento te atienden</h1>
        <h3>Hora: {props.data.hour}</h3>
        <h3>Dentista: {props.data.dentist}</h3>
        <h3>Unidad: {props.data.office}</h3>
      </div>
    );
  }
};

export default NoAppointment;
