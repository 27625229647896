import React, { useState, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';

import AppointmentFormStep2 from './AppointmentFormStep2';
import AppointmentConfirmation from './AppointmentConfirmation'
import { UserContext } from "../../context/context";

import APPOINTMENT_SERVICE from '../../services/appointment'

const ReScheduleAppointmentModal = (props) => {

  const userContext = useContext(UserContext);

  const procedures = props.procedures;
  const [schedules, setSchedules] = useState([]);

  // States for pages
  const [pageState, setPage] = useState(0);

  // Change page
  const nextPage = () => { setPage(pageState + 1); }

  // Show pages
  let showPage1 = (pageState === 0) ? { display: "block" } : { display: "none" };
  let showPage2 = (pageState === 1) ? { display: "block" } : { display: "none" };

  //Modify the buttons according to the page
  let nextButtonText = (pageState === 0) ? 'Guardar' : '';
  let styleButton = (pageState === 1) ? { display: "none" } : { display: "" };

  // Handles the form in step 2
  const formikStep2 = useFormik({
    initialValues: {
      date: "",
      hour: "",
      dentists: ""
    },
    validationSchema: Yup.object({
      date: Yup.date()
        .required('Requerido'),
      hour: Yup.string()
        .required('Requerido'),
    }),
  });

  const formik = useFormik({
    initialValues: {
      date: "",
      starts: "",
      ends: "",
      patient: "",
      dentistsArray: "",
      clinic: "",
      procedure: "Elige...",
      procedureInfo: "",
      clinicInfo: "",
      dateString: "",
    },
    onSubmit: (values) => {
      if (pageState === 0) {
        values.procedureInfo = props.appointment;
        values.dateString = formikStep2.values.date;
        values.clinicInfo = props.appointment.clinic;

        const startHour = schedules[parseInt(formikStep2.values.hour)].hour;
        
        values.date = formikStep2.values.date;
        values.starts = startHour;
        const end = (parseInt(startHour) + 100);
        values.ends = end;

        values.patient = props.patientId;
        values.dentistsArray = formikStep2.values.dentists;
        values.clinic = props.appointment.clinic._id;

        values.procedure = values.procedureInfo.id;
        nextPage();
      }
    },
  });

  // Reset the state of the component
  const resetState = () => {
    formikStep2.handleReset();
    formik.handleReset();
    props.close();
    setPage(0);
    setSchedules([]);
  };

  // Makes the request to reschedule the appointment
  const rescheduleAppointment = () => {
    const { date, starts, ends, dentistsArray } = formik.values;
    const data = { date, starts, ends, dentistsArray };
    APPOINTMENT_SERVICE.UPDATE(props.dataid, data).then();
    userContext.changeSate();
    resetState();
  }

  return (
    <div className={`modal ${props.modalClass}`} style={props.modalStyle}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <form onSubmit={formik.handleSubmit}>
            {/* Form content */}
            <div className="modal-body">
              <div>Reagendar Cita</div>
              <AppointmentFormStep2
                procedure={props.appointment.category}
                procedures={procedures}
                edit={true}
                schedules={schedules}
                setSchedules={setSchedules}
                formik={formikStep2}
                style={showPage1}
                dentist={null}
              />
              <AppointmentConfirmation
                value={formik.values}
                style={showPage2}
              />
            </div>

            <div className="modal-footer">
              {/* Page one buttons */}
              <div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={pageState === 0 ? resetState : rescheduleAppointment}
                >
                  Cerrar
                </button>
                <button
                  type={"submit"}
                  style={styleButton}
                  className="btn btn-primary"
                  disabled={!(formikStep2.isValid && formikStep2.dirty)}
                >
                  {nextButtonText}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ReScheduleAppointmentModal;
