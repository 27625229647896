import React, { useState, useEffect } from 'react';
import { parse } from 'query-string';
import ReceptionistCard from './ReceptionistCard';
import USER_SERVICE from '../../services/user'
// import Layout from "../Layout/Layout";
// import ClinicCard from "../Clinic/ClinicCard";

const PatientsList = () => {
    const [receptionists, setReceptionists] = useState([]);

    const searchQuery = parse(window.location.search).search;

    const userServiceGetByRole = async () => {
        const { data } = await USER_SERVICE.GET_BY_ROLE('receptionist');
        setReceptionists(data);
    }

    useEffect(() => {
        try {
            userServiceGetByRole();
        } catch (error) {
            console.log(error);
        }
    }, [searchQuery]);

    return (
        <div className="container dentist-list">
            <div className="row">
                {receptionists?.map((receptionist) => {
                    return (
                        <ReceptionistCard
                            id={receptionist.id}
                            name={receptionist.name}
                            surname={receptionist.surname}
                            email={receptionist.email}
                            key={receptionist.id}
                            link={true}
                            url="/receptionists"
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default PatientsList;
