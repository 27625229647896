import React from 'react';
import { Link } from 'react-router-dom';

const DentistCard = ({ id, name, surname, email, especialty, office, link, url }) => {
    return (
        <>
            <div className="col-12 px-0 pb-4">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">{name} {surname}</h4>
                    </div>
                    <div className="card-body d-flex justify-content-between">
                        <div className="">
                            <p><strong>Especialidad:</strong> {especialty}</p>
                            <p><strong>Correo electrónico:</strong> {email}</p>
                            <p><strong>Unidad: </strong> {office}</p>
                        </div>
                        {link
                            ?
                            <div className="d-flex justify-content-end align-items-end">
                                <Link
                                    to={id ? `${url}/${id}` : `/dentitst/profile/new`}
                                    className="btn btn-primary ml-0"
                                    style={{ maxHeight: '40px', borderRadius: '15px' }}
                                >
                                    Ver dentista
                                </Link>
                            </div>
                            : ""
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default DentistCard;
