import React, { useState, useEffect } from 'react';
import { parse } from 'query-string';
import DentistCard from './DentistCard';
import DENTIST_SERVICE from '../../services/dentist';

const DentistsList = () => {
  const [dentists, setDentists] = useState([]);

  const searchQuery = parse(window.location.search).search;
  useEffect(() => {
    DENTIST_SERVICE.GET_ALL()
      .then((response) => setDentists(response.data))
      .catch();
  }, [searchQuery]);

  return (
    <>
      <div className="row mx-2 mx-md-5">
        {dentists?.map((dentist, idx) => {
          return (
            <DentistCard
              key={idx}
              id={dentist.idUser.id}
              name={dentist.idUser.name}
              surname={dentist.idUser.surname}
              email={dentist.idUser.email}
              especialty={dentist.especialty}
              office={dentist.office}
              link={true}
              url="/admin/dentists"

            />
          );
        })}
      </div>
    </>
  );
}

export default DentistsList;
