import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Field, Formik } from "formik";
import * as Yup from "yup";

const FastRadialButtons = (props, ref) => {

    const { validation, setValue, id, options, name } = props;
    const [initialValue, setInitialValue] = useState(null);
    const [touched, setTouched] = useState(false);


    useImperativeHandle(ref, () => ({
        onSubmit() {
            fastField.current.handleSubmit();
            if (!touched) {
                // return an error
                return ([id]);
            }

            return ([]);

        },
        setInitialValue(values) {
            setInitialValue(values[id]);
        }
    }));

    const fastField = useRef(null);

    const formikInitial = {};
    formikInitial[name] = initialValue ?? '';

    const formikValidation = {};
    formikValidation[name] = validation ?? Yup.string();

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={formikInitial}
                validationSchema={Yup.object().shape(formikValidation)}
                innerRef={fastField}
                onSubmit={() => { }}
            >
                {/* {({ errors, touched }) => { */}
                {({ errors }) => {
                    return (
                        <>
                            <div role="group"
                                aria-labelledby="my-radio-group"
                                className='padtop05 padbottom05'
                            >
                                {options?.map(option => {
                                    return (<label key={Math.random()} >
                                        <Field type="radio" name={name} value={option}
                                            className='marsides03'
                                            onClick={e => {
                                                setTouched(true);
                                                setValue(e.target.value, id);
                                            }}
                                        />
                                        {option}
                                    </label>);
                                })}
                            </div>
                            {errors[name]
                                && <div className="invalid-feedback">{errors[name]}</div>}
                        </>
                    );
                }}

            </Formik>
        </>
    );
}

export default forwardRef(FastRadialButtons);
