import React from 'react';
import '../../Styles/Footer.css';

const Footer = () => {
  return (
    <>
        <div className="row m-0 mt-3" style={{maxWidth: "100vw"}}>
          <footer className="col-12 d-flex align-items-center justify-content-center m-0 p-0">
            Derechos Reservados Universidad ION
          </footer>
        </div>
    </>
  );
};

export default Footer;
