import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import USER_SERVICE from "../../services/user";
import Layout from "../Layout/Layout";

const ResetPassword = () => {
  const { id } = useParams();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required(
        "La contraseña anterior es requerida."
      ),
      newPassword: Yup.string()
        .required("Una contraseña es requerida.")
        .min(6, "La contraseña debe tener minimo 6 caracteres.")
        .matches(/[A-Z]+/g, "La contraseña debe tener una letra mayúscula.")
        .matches(/[a-z]+/g, "La contraseña debe tener una letra minúscula.")
        .matches(/[0-9]+/g, "La contraseña debe contener mínimo un número.")
        .matches(
          /(\W|_)+/g,
          "La contraseña debe contener mínimo un carácter especial."
        ),
      confirmPassword: Yup.string()
        .required("Este campo es requerido.")
        .oneOf(
          [Yup.ref("newPassword"), ""],
          "Las contraseñas no son idénticas"
        ),
    }),
    onSubmit: async (values) => {
      try {
        setErrorMessage("");
        const user = await USER_SERVICE.GET_ONE(id);
        values.email = user.data.email;
        await USER_SERVICE.UPDATE_PASSWORD(values);
        if (user.data.role === "receptionist") {
          history.push("/receptionists")
        } else {
          history.push("/completeregistration");
        }
      } catch (error) {
        console.log(error);
        setErrorMessage("Contraseña anterior incorrecta");
      }
    },
  });

  return (
    <Layout>
      <div className="container pt-0 mt-1">
        <div className="row">
          <div className="col-md-6 mx-auto mb-md-5">
            <form>
              <div className="form-group">
                <label htmlFor="oldPassword">*Anterior contraseña</label>
                <input
                  type="password"
                  id="oldPassword"
                  className={`form-control  ${formik.errors.oldPassword && formik.touched.oldPassword
                    ? "is-invalid"
                    : ""
                    }`}
                  name="oldPassword"
                  value={formik.values.oldPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.oldPassword && formik.touched.oldPassword && (
                  <div className="invalid-feedback">
                    {formik.errors.oldPassword}
                  </div>
                )}
                {errorMessage && (
                  <div className="text-danger">{errorMessage}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="newPassword">
                  <strong>*Nueva contraseña</strong>
                </label>
                <input
                  type="password"
                  id="newPassword"
                  className={`form-control  ${formik.errors.newPassword && formik.touched.newPassword
                    ? "is-invalid"
                    : ""
                    }`}
                  name="newPassword"
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.newPassword && formik.touched.newPassword && (
                  <div className="invalid-feedback">
                    {formik.errors.newPassword}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">
                  <strong>*Confirmar contraseña</strong>
                </label>
                <input
                  type="password"
                  className={`form-control  ${formik.errors.confirmPassword &&
                    formik.touched.confirmPassword
                    ? "is-invalid"
                    : ""
                    }`}
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.confirmPassword &&
                  formik.touched.confirmPassword && (
                    <div className="invalid-feedback">
                      {formik.errors.confirmPassword}
                    </div>
                  )}
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-secondary btn-lg w-50"
                  onClick={formik.handleSubmit}
                >
                  Crear Contraseña
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ResetPassword;
