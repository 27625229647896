import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { useFormik } from "formik";
import * as Yup from "yup";

import AUTH_SERVICE from '../../services/auth'

const ResetPassword = () => {
    const history = useHistory();
    const { token, userId } = useParams();

    const [isLoading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const handleGoHome = () => {
        history.push("/login");
    }

    const formik = useFormik({
        initialValues: {
          newPass: "",
          confirmPass: "",
        },
        validationSchema: Yup.object({
            newPass: Yup.string()
            .required("Una contraseña es requerida.")
            .min(6, "La contraseña debe tener minimo 6 caracteres.")
            .matches(/[A-Z]+/g, "La contraseña debe tener una letra mayúscula.")
            .matches(/[a-z]+/g, "La contraseña debe tener una letra minúscula.")
            .matches(/[0-9]+/g, "La contraseña debe contener mínimo un número.")
            .matches(
              /(\W|_)+/g,
              "La contraseña debe contener mínimo un carácter especial."
            ),
          confirmPass: Yup.string()
            .required("Este campo es requerido.")
            .oneOf([Yup.ref("newPass"), ""], "Las contraseñas no son idénticas"),
        }),
        onSubmit: async ({ newPass, confirmPass }) => {
            setLoading(true);

            try {
                await AUTH_SERVICE.UPDATE_PASSWORD({
                    userId,
                    token,
                    newPass
                });

                setSuccessMsg('¡Contraseña cambiada con éxito!');
                setTimeout(() => { setSuccessMsg('') }, 3000);

                formik.values.newPass = ''
                formik.values.confirmPass = ''
            } catch (error) {
                setErrorMsg(error.response.data.errors[0].msg);
                setTimeout(() => { setErrorMsg('') }, 3000);
            } finally {
                setLoading(false);
            }
        },
    });

    var newPassError = formik.errors.newPass && formik.touched.newPass;
    var confirmPassError = formik.errors.confirmPass && formik.touched.confirmPass;

    return (
        <Layout>
            <div className="col-10 col-lg-6 mx-auto">
                <div className="container border mt-5 py-3 card">
                    <h2 className="text-center font-weight-bold mb-3">Cambiar contraseña</h2>
                    <form>
                        <div className="form-group">
                            <label htmlFor="newPass">Nueva contraseña</label>
                            <input
                                placeholder="Nueva contraseña"
                                value={formik.values.newPass}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                type="password"
                                className={`form-control form-control-lg ${
                                    newPassError ? "is-invalid" : ""
                                }`}
                                name="newPass"
                                id="newPass"
                            />
                            { newPassError && (
                                <div className="invalid-feedback">{formik.errors.newPass}</div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="confirmPass">Confirmar contraseña</label>
                            <input
                                placeholder="Confirmar contraseña"
                                value={formik.values.confirmPass}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                type="password"
                                className={`form-control form-control-lg ${
                                    confirmPassError ? "is-invalid" : ""
                                }`}
                                name="confirmPass"
                                id="confirmPass"
                            />
                            { confirmPassError && (
                                <div className="invalid-feedback">{formik.errors.confirmPass}</div>
                            )}
                        </div>
                        <div className="row d-flex text-center mt-4">
                            <div className="col-6">
                                <button type="button" className="btn btn-outline-secondary" onClick={handleGoHome}>
                                    <i className="bi bi-house"></i> Regresar al inicio
                                </button>
                            </div>
                            <div className="col-6">
                                <button type="submit" className="btn btn-primary" onClick={formik.handleSubmit}>Cambiar contraseña</button>
                            </div>
                        </div>
                        <div className="container">
                            { isLoading && (
                                <div className="row mt-4">
                                    <div className="col-12 text-center">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Cargando...</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            { successMsg && (
                                <div className="row mt-4">
                                    <div className="alert alert-success" role="alert">
                                        { successMsg }
                                    </div>
                                </div>
                            )}
                            { errorMsg && (
                                <div className="row mt-4">
                                    <div className="alert alert-danger" role="alert">
                                        { errorMsg }
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    )
}

export default ResetPassword;
