import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import { useParams } from 'react-router-dom';
import AUTH_SERVICE from "../../services/auth";
import { useHistory } from "react-router-dom";

const ConfirmEmail = () => {
    const { id } = useParams();
    const history = useHistory();

    const [message, setMessage] = useState("Verificando su Email...");
    const [redirectMessage, setRedirectMessage] = useState("");

    useEffect(() => {
      const verifyEmail = async (id) => {
        try {
          const { data } = await AUTH_SERVICE.CONFIRM_EMAIL({ _id: id });
          if(data){
            setMessage("¡Su cuenta ha sido confirmada!");
            setRedirectMessage("Redirigiendo al Inicio de Sesión...");
          }
          else{
            setMessage("Hubo un problema verificando su cuenta");
          }
        } catch (error) {
          console.error(error)
        } finally{
          setTimeout(() => {
            history.push("/login");
          }, 2000);
        }
      }

      verifyEmail(id);
    }, [id, history]);

    return (
      <Layout>
          <div className="row mx-0 px-0">
            <div className="col-12 d-flex justify-content-center mb-4">
              <h2 className="font-weight-bold">{message}</h2>
            </div>
            <p>{redirectMessage}</p>
          </div>
      </Layout>
    );
};

export default ConfirmEmail;
