import React from "react";

const UploadFile = () => {

  return (
    <form action="">
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label htmlFor=""></label>
            <input type="file" name="" id="" />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label htmlFor="">Clinica</label>
            <select className="form-control" name="" id="">
              OPT
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="">Dentista</label>
            <select className="form-control" name="" id="">
              OPT
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="">Procedimiento</label>
            <select className="form-control" name="" id="">
              OPT
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="">Procedimiento</label>
            <input className="form-control" type="date" name="" id="" />
          </div>
        </div>
      </div>
    </form>
  );
}
export default UploadFile;


