import React, { useState, useEffect, useContext } from 'react';
import PROCEDURE_SERVICE from '../services/procedure';
import { UserContext } from "../context/context";
import ProcedureCard from '../components/Procedures/ProcedureCard/ProcedureCard';
import Layout from '../components/Layout/Layout';

const Procedures = () => {
  const [procedures, setProcedures] = useState([]);
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const procedureServiceGetAll = async () => {
    let { data } = await PROCEDURE_SERVICE.GET_ALL();
    // for(let i = 0; i < data.length; i++){
    //   if(!data[i].locationId[0]) {
    //     data[i].locationId[0] = {};
    //     data[i].locationId[0].name = "Sin definir";
    //   }
    // };
    setProcedures(data);
    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true);
    try {
      procedureServiceGetAll();
    } catch (error) {
      console.log(error)
    }
  }, [userContext]);


  return (
    <Layout>
      <div className="flex flex-start container-fluid h-100" style={{ backgroundColor: "#063348" }}>
        <h2 className="page-title marsides2">Procedimientos</h2>
      </div>
      <div className="container-fluid" style={{ backgroundColor: "white" }}>
        <div className="row">
          {procedures.map((procedure) => {
            return !isLoading ? (
              <ProcedureCard key={procedure.id}
                id={procedure.id}
                name={procedure.name}
                description={procedure.description}
                cost={procedure.cost}
                duration={procedure.duration}
                category={procedure.category}
              />
            )
              : null;
          }
          )}
        </div>
      </div>


    </Layout>
  );
};

export default Procedures;

