import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import AUTH_SERVICE from '../../services/auth'

const ResetPassword = () => {
    const [isLoading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const formik = useFormik({
        initialValues: {
          email: ""
        },
        validationSchema: Yup.object({
            email: Yup.string()
            .required("El correo electrónico es requerido.")
            .email()
        }),
        onSubmit: async ({ email}) => {
            setLoading(true);

            try {
                await AUTH_SERVICE.RESET_PASSWORD({ email });

                setSuccessMsg('¡Se ha enviado un correo electrónico para reestrablecer tu contraseña!');
                setTimeout(() => { setSuccessMsg('') }, 3000);

                formik.values.email = ''
            } catch (error) {
                setErrorMsg(error.response.data.errors[0].msg);
                setTimeout(() => { setErrorMsg('') }, 3000);
            } finally {
                setLoading(false);
            }
        },
    });

    var emailError = formik.errors.email && formik.touched.email;

    return (
        <div className="modal fade" id="forgotPasswordModal" tabIndex="-1" aria-labelledby="forgotPasswordModal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="restorePassword">Reestablecer de contraseña</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form>
                        <div className="modal-body py-4">
                            <div className="form-group">
                                <label htmlFor="email">Correo electrónico</label>
                                <input
                                    placeholder="Correo electrónico"
                                    value={formik.values.newPass}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    type="email"
                                    className={`form-control form-control-lg ${
                                        emailError ? "is-invalid" : ""
                                    }`}
                                    name="email"
                                    id="email"
                                />
                                { emailError && (
                                    <div className="invalid-feedback">{formik.errors.email}</div>
                                )}
                            </div>
                            <div className="container">
                                { isLoading && (
                                    <div className="row mt-4">
                                        <div className="col-12 text-center">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Cargando...</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                { successMsg && (
                                    <div className="row mt-4">
                                        <div className="alert alert-success" role="alert">
                                            { successMsg }
                                        </div>
                                    </div>
                                )}
                                { errorMsg && (
                                    <div className="row mt-4">
                                        <div className="alert alert-danger" role="alert">
                                            { errorMsg }
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary mx-auto" onClick={formik.handleSubmit}>Reestablecer contraseña</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;
