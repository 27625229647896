import React from "react";
import QRCode from "qrcode";

/**
 * Generates a QR Code
 * @param {string} idAppointment - The id of the appointment
 */

const QRGenerator = ({ idAppointment }) => {
  const generateQR = () => {
    QRCode.toCanvas(document.getElementById("canvas"), idAppointment, function (
      error
    ) {
      if (error) console.error(error);
    });
  };

  const downloadQR = () => {
    const canvas = document.getElementById("canvas");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "QR_CITA_DENTAL_ION.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  return (
    <div className="d-flex align-items-center flex-column">
      <div className="d-flex flex-row">
        <canvas id="canvas"></canvas>
      </div>
      <div className="d-flex flex-row">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            generateQR();
            downloadQR();
          }}
        >
          Descargar QR
        </button>
      </div>
    </div>
  );
}

export default QRGenerator;
