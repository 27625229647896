import axios from '../config/axios';

const AUTH_SERVICE = {
  REGISTER: async (data) => {
    return await axios.post('/users/register', data);
  },

  CREATE_DENTIST: async (data) => {
    return await axios.post(
      '/users/register/dentist', data);
  },

  CREATE_PATIENT: async (data) => {
    return await axios.post('/users/register/patient', data);
  },

  LOGIN: async (data) => {
    return await axios.post('/users/login', data);
  },

  CURRENT_USER: async () => {
    return await axios.get('/currentUser');
  },

  LOGOUT: async () => {
    return await axios.get('/logout');
  },

  CONFIRM_EMAIL: async (data) => {
    return await axios.post('/users/confirm-email', data);
  },

  RESET_PASSWORD: async ({ email }) => {
    return await axios.post('/reset-password', { email });
  },

  UPDATE_PASSWORD: async ({ userId, token, newPass }) => {
    return await axios.post('/update-password', { userId, token, newPass });
  },
};

export default AUTH_SERVICE;
