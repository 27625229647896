import React from "react";
import "../../Styles/AppointmentsTable.css"

const AppointmentHeadTable = ({ item }) => {
    return (
        <td title={item}>
            {item}
        </td>
    );
};

export default AppointmentHeadTable;