const colors = {
    primary: '#5AC0CC',
    secondary: {
        gray: '#b9bec1',
        darkBlue: '#063348',
        blue: '#03579a'
    }
}

export default colors;
