import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../../context/context";

const UserInfoCard = ({
  name,
  surname,
  birthday,
  email,
  phone,
  id,
  contactMethods,
}) => {
  const [contactMethod, setContactMethod] = useState("");

  const userContext = useContext(UserContext);

  useEffect(() => {
    if (contactMethods) {
      let data = userContext.loggedUser;
      data.phone = phone;
      data.contactMethods.whatsapp = contactMethods["whatsapp"];
      data.contactMethods.sms = contactMethods["sms"];
      userContext.setLoggedUser(data);
 
      let msg;
      if (contactMethods["whatsapp"]) msg = " y Whatsapp";
      else msg = "y mensaje de texto";

      setContactMethod(msg);
    }
  }, [contactMethods, phone, userContext]);

  return (
    <>
      <div className="col-md-6 px-0 pb-4 martop1">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Información de registro</h4>
          </div>
          <div className="card-body d-flex justify-content-between">
            <div className="">
              <p>
                <strong>Nombre(s):</strong> {name}{" "}
              </p>
              <p>
                <strong>Apellido(s):</strong> {surname}{" "}
              </p>
              <p>
                <strong>Fecha nacimiento:</strong> {birthday}{" "}
              </p>
              <p>
                <strong>Correo electrónico:</strong> {email}{" "}
              </p>
              <p>
                <strong>Teléfono:</strong> {phone}{" "}
              </p>
              <p>
                <strong>Métodos de contactos:</strong> Correo electrónico{" "}
                {contactMethod}{" "}
              </p>
            </div>
          </div>
        </div>

        <br />
        <div>
          <a href={`/patient/editprofile/${id}`}>
            <button className="btn btn-primary">Editar mi perfil</button>
          </a>
        </div>
      </div>
    </>
  );
};

export default UserInfoCard;
