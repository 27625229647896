import React, { useState } from 'react';
// import axios from "axios";

import ImageModal from '../ImageModal'

const PatientHistory = ({ id, fotos }) => {
    const [state, setState] = useState(false);
    const [selectedImage, setImage] = useState(null);

    var dateOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };

    const handleClick = ({ target }) => {
        setImage(target.src)
    }

    if (!fotos) fotos = { length: 0 };

    return (
        <>
            <div className="col-12 padall1 px-0">
                <div className="card">
                    <h4 className="card-header card-title"> Expediente digitalizado</h4>
                    <div className="card-body ">
                        <div>
                            <div className="flex flex-just-space-between">
                                <p className="mb-0 "><strong>Imagenes: {fotos.length}</strong></p>
                                <button
                                    className="btn btn-primary m-0 "
                                    onClick={() => setState(!state)}
                                >Ver/Ocultar Imagenes
                                </button>
                            </div >

                            { state ?
                                <div className="flex flex-wrap overflow-auto mt-4" style={{height: '320px'}}>
                                    { fotos.map((foto, idx) =>
                                        <div className="col-4" key={idx}>
                                            <div className="border px-4 py-4">
                                                <img
                                                    src={`${process.env.REACT_APP_BACKEND_URL}/profiles/images/${foto.filename}`}
                                                    alt="Documento PDF"
                                                    // className="img-thumbnail"
                                                    style={{height: '200px', marginTop: '10px', marginBottom: '20px', cursor: 'pointer'}}
                                                    data-toggle="modal" data-target="#imageModal"
                                                    onClick={ handleClick }
                                                />
                                                <div>Tratamiento: { foto.category }</div>
                                                <div>Dentista: { foto.dentist.name }</div>
                                                <div>{ foto.clinic.name }</div>
                                                <div>Fecha: { new Date(foto.date).toLocaleDateString('es-ES', dateOptions) }</div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                : null
                            }

                            <ImageModal image={ selectedImage }/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PatientHistory;
