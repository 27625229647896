import React, { createContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import AUTH_SERVICE from '../services/auth';
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';

export const UserContext = createContext();

export const ContextProvider = ({ children }) => {
  const history = useHistory();

  const [loggedUser, setLoggedUser] = useState(null);
  const [isInitialized, setInitialized] = useState(false);
  const [dateAppointment, setDateAppointment] = useState(null);
  const [reload, setReload] = useState(false);

  const logout = async () => {
    const token = localStorage.getItem('token');
    if(loggedUser && loggedUser.id)
      serviceWorkerRegistration.unsubscribe(loggedUser.id, token);

    setLoggedUser(null);
    localStorage.removeItem('token');
    history.push(0);
  }

  const changeSate = () => {
    reload ? setReload(false) : setReload(true)
  }

  const calendarDate = (date) => {
    setDateAppointment(date)
  }

  useEffect(() => {
    const getAuthUser = async () => {
      const token = localStorage.getItem('token');

      validateToken(token);
      if (!loggedUser && token) {
        try {
          const { data: userName } = await AUTH_SERVICE.CURRENT_USER();

          setLoggedUser(userName)
        } catch (error) {
          console.error(error)
        }
      } else { setInitialized(true) }
    }

    getAuthUser();
  });

  const validateToken = (token) => {
    const decodedJwt = parseJwt(token);
    if(decodedJwt){
      try{
        if (decodedJwt.exp * 1000 < Date.now()) {
          logout();
        }
      } catch (error) {
        logout();
        console.error(error)
      }
    }

  };

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  return (
    <UserContext.Provider
      value={{
        loggedUser,
        // logUser,
        setLoggedUser,
        logout,
        calendarDate,
        changeSate,
        isInitialized,
        dateAppointment,
        reload,
      }}
    >
      {isInitialized ? children : null}
    </UserContext.Provider>
  )
}
