import React, { useEffect, useRef, useState } from "react";
import { Redirect, useParams, useHistory } from "react-router";
// Components
import Layout from "../components/Layout/Layout";
import Identification from "../components/MedicalRecords/Identification";
import History from "../components/MedicalRecords/History";
import DentalHistory from "../components/MedicalRecords/DentalHistory";
import ClinicalExam from "../components/MedicalRecords/ClinicalExam";
import Treatment from "../components/MedicalRecords/Treatment";
import EvolutionNotes from "../components/MedicalRecords/EvolutionNotes";
import '../Styles/MedicalRecordsHistory.css'

// Services
import PATIENT_SERVICE from '../services/patient';
import RECORDS_SERVICE from '../services/records'

// Used to convert to a json object from id strings.
import flat from "flat";


const NewMedicalRecord = () => {
    const { id } = useParams();
    const [createdId,] = useState();
    const [parents, setParents] = useState();
    const [selectedParent, setSelectedParent] = useState();
    const [statusRecord, setStatusRecord] = useState(false);
    const [, setMedicalRecordsArray] = useState([]);
    const history = useHistory();

    //This creates a medicalRecord with a child
    const recordsServiceCreate = async (id, jsonValues) => {
        if (statusRecord && selectedParent !== "") {
            await RECORDS_SERVICE.CREATE_WITH_PARENT(id, jsonValues, selectedParent);
        } else {
            await RECORDS_SERVICE.CREATE(id, jsonValues);
        }
    }

    const [values] = useState({});
    function setValue(value, id) {
        values[id] = value;
    }
    const onSubmit = async (e) => {
        // Prevent reload
        e.preventDefault();

        const jsonValues = flat.unflatten(values);
        //Here we add the isChild value to all the other elements in the object.
        if (statusRecord) {
            Object.assign(jsonValues, { isChild: true })
        }

        // delete unused values
        delete jsonValues['generalInfo'];
        delete jsonValues['medicalHistory'];
        delete jsonValues['dentalHistory'];

        // Check for errors
        const errors = [];
        for (let ref in refs) {
            try {
                const eachErrors = await refs[ref].current.onSubmit();
                for (let err in eachErrors)
                    errors.push(eachErrors[err]);
            } catch (error) {
                console.log(ref);
            }
        }

        if (!errors.length) {
            // If there is no errors
            try {
                await recordsServiceCreate(id, jsonValues);
                history.push('/patients/' + id);

            } catch (error) {
                console.log(error);
            }
        }
    }

    // Values that already exist and don't change.
    const [patientName, setName] = useState("");
    const [blockForm, setBlockForm] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const unchangingRefs = {
        identification: useRef(null),
        history: useRef(null),
        dentalHistory: useRef(null),
    }

    useEffect(() => {
        const getInitialData = async () => {
            const response = await PATIENT_SERVICE.GET_ONE(id);

            const data = response.data;

            // if has_data then blockForm = true
            data ? setBlockForm(true) : setBlockForm(false);

            for (let ref in unchangingRefs) {
                try {
                    unchangingRefs[ref].current.setInitialValues(flat.flatten(data));
                } catch (error) {
                    console.log(error);
                }
            }

            const generalInfo = data.generalInfo;
            setName(
                `${generalInfo.name} ${generalInfo.paternalLastName} ${generalInfo.maternalLastName}`
            );
        }

        //Here we fetch the medical records that are parents of other submedical records.
        const getParents = async () => {
            const { data } = await RECORDS_SERVICE.GET_PARENT_RECORDS(id);
            setParents(data);
            setMedicalRecordsArray(data.relatedMedicalRecords || []);
        };

        getInitialData();
        getParents();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refs = {
        clinicalExam: useRef(null),
        treatment: useRef(null),
        evolution: useRef(null),
    };

    const handleChange = (e) => {
        setSelectedParent(e.target.value);
    }
    const handleClick = (e) => {
        setStatusRecord(!statusRecord);
    }

    return (
        createdId ?
            <Redirect to={`/patients/${id}`} />
            :
            <Layout>
                <form onSubmit={onSubmit}>
                    <div className="container card py-4" style={{ backgroundColor: "white" }}>
                        <div className="container">
                            {/* In reverse */}
                            <div className="row">
                                <div className="col-6">
                                    <button type="submit" className="btn btn-primary mr-5">
                                        Guardar
                                    </button>
                                </div>
                                <div className="col-6">
                                    <input className="form-check-input"
                                        type="checkbox"
                                        checked={statusRecord}
                                        onClick={handleClick} />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        ¿Es subexpediente?
                                    </label>
                                </div>
                                {
                                    statusRecord ?
                                        parents ?

                                            <>


                                                <select

                                                    onChange={(e) => handleChange(e)}
                                                    className={`dropdown-newMedicalRecord`}

                                                >
                                                    <option defaultChecked value={""}>Seleccione un expediente si  el nuevo pertenece a otro</option>
                                                    {
                                                        parents.map((parent) => {
                                                            const date = new Date(parent.treatment.date);
                                                            let day = date.getDate();
                                                            let month = date.getMonth() + 1;
                                                            //Format for day adding a 0 before the day if needed
                                                            if (day <= 9) {
                                                                day = "0" + day;
                                                            }
                                                            //Format for month adding a 0 before the month if needed
                                                            if (month <= 9) {
                                                                month = "0" + month;
                                                            }

                                                            return <option key={parent.id} value={parent.id}>{parent.treatment.specialty + " - Dr. " + parent.treatment.doctor.name + " " + month + "-" + day + "-" + date.getFullYear()}</option>
                                                        })}
                                                </select>
                                            </>
                                            :
                                            <p>Cargando...</p>
                                        :
                                        <p></p>

                                }

                            </div>
                        </div>
                        <div>
                            <h3 className="pl-2 font-weight-bold">
                                {patientName.toUpperCase()}
                            </h3>
                        </div>
                        <div className="">
                            <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a
                                        className="nav-link active"
                                        id="identificacion-tab"
                                        data-toggle="tab"
                                        href="#identificacion"
                                        role="tab"
                                    >
                                        Ficha de Identificación
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a
                                        className="nav-link"
                                        id="historia-medica-tab"
                                        data-toggle="tab"
                                        href="#historia-medica"
                                        role="tab"
                                    >
                                        Historia Médica
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a
                                        className="nav-link"
                                        id="historia-dental-tab"
                                        data-toggle="tab"
                                        href="#historia-dental"
                                        role="tab"
                                    >
                                        Historia Dental
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a
                                        className="nav-link"
                                        id="examen-clinico-tab"
                                        data-toggle="tab"
                                        href="#examen-clinico"
                                        role="tab"
                                    >
                                        Examen Clínico
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a
                                        className="nav-link"
                                        id="tratamiento-tab"
                                        data-toggle="tab"
                                        href="#tratamiento"
                                        role="tab"
                                    >
                                        Plan de Tratamiento
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a
                                        className="nav-link"
                                        id="contact-tab"
                                        data-toggle="tab"
                                        href="#notas-evolucion"
                                        role="tab"
                                    >
                                        Notas de Evolución
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="identificacion"
                                    role="tabpanel"
                                    aria-labelledby="home-tab"
                                >
                                    <Identification
                                        setValue={setValue}
                                        ref={unchangingRefs["identification"]}
                                        blockForm={blockForm}
                                    />
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="historia-medica"
                                    role="tabpanel"
                                    aria-labelledby="profile-tab"
                                >
                                    <History
                                        setValue={setValue}
                                        ref={unchangingRefs["history"]}
                                        blockForm={blockForm}
                                    />
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="historia-dental"
                                    role="tabpanel"
                                    aria-labelledby="profile-tab"
                                >
                                    <DentalHistory
                                        setValue={setValue}
                                        ref={unchangingRefs["dentalHistory"]}
                                        blockForm={blockForm}
                                    />
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="examen-clinico"
                                    role="tabpanel"
                                    aria-labelledby="contact-tab"
                                >
                                    <ClinicalExam
                                        setValue={setValue}
                                        ref={refs["clinicalExam"]}
                                    />
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="tratamiento"
                                    role="tabpanel"
                                    aria-labelledby="contact-tab"
                                >
                                    <Treatment
                                        setValue={setValue}
                                        ref={refs["treatment"]}
                                    />
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="notas-evolucion"
                                    role="tabpanel"
                                    aria-labelledby="contact-tab"
                                >
                                    <EvolutionNotes setValue={setValue}
                                        ref={refs["evolution"]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Layout >
    );
};

export default NewMedicalRecord;
