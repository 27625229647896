import * as Yup from "yup";

export default function ConsentmentValidation() {
    const consentmentValidation = {
        patientName: Yup.string().matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ][a-zA-ZÀ-ÖØ-öø-ÿ\s]*$/, "Contiene caracteres no permitidos").min(1).max(99, "Demasiado largo"),
        patientAge: Yup.number().typeError("Debe ser un número").min(3, "Debe ser mayor a 3").max(120, "Edad no válida"),
        tutorName: Yup.string().matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ][a-zA-ZÀ-ÖØ-öø-ÿ\s]*$/, "Contiene caracteres no permitidos").min(1).max(99, "Demasiado largo"),
        tutorAge: Yup.number().typeError("Debe ser un número").min(3, "Debe ser mayor a 3").max(120, "Edad no válida"),
        dentistName: Yup.string().matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ][a-zA-ZÀ-ÖØ-öø-ÿ\s]*$/, "Contiene caracteres no permitidos").min(1).max(99, "Demasiado largo"),
        relationship: Yup.string().matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ][a-zA-ZÀ-ÖØ-öø-ÿ\s]*$/, "Contiene caracteres no permitidos").min(1).max(20, "Demasiado largo"),
    }

    return consentmentValidation;
}
