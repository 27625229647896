import React from 'react';
import ReceptionistsList from '../components/Recepcionist/ReceptionistsList';
import Layout from '../components/Layout/Layout';

const Receptionists = () => {
    return (
        <>
        <Layout >
            <div className="flex flex-start container-fluid h-100" style={{backgroundColor: "#063348"}}>
                <h2 className="page-title marsides2">Recepcionistas</h2>
            </div>
            <div className="flex flex-start container-fluid px-5" style={{backgroundColor:"white"}}>
          <ReceptionistsList />
            </div>
        </Layout>
      </>
    );
};

export default Receptionists;
