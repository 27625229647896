//import {useEffect, useState} from 'react';
import * as Yup from "yup";
//import PATIENT_SERVICE from "../../../services/patient";

/* TO-DO: PATIENT SERVICE
useEffect(() => {
    if (id && id !== 'new') {
        const token = localStorage.getItem("token");
        PATIENT_SERVICE.GET_ONE(token, id)
            .then((response) => {
                console.log( response.data );
                if( response.data ) {
                    setIdentificationInfo(response.data);
                    console.log( response.data );
                }
            });
    }
}, [id])
*/

const IdentificationValidation = () => {
    const identificacionValidation = {
        motivoConsulta: Yup.string().required(" ").max(999, "Demasiado largo"),
        name: Yup.string().required(" ").matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ][a-zA-ZÀ-ÖØ-öø-ÿ\s]*$/, "Contiene caracteres no permitidos").min(1).max(99, "Demasiado largo"),
        paternalLastName: Yup.string().required(" ").matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ][a-zA-ZÀ-ÖØ-öø-ÿ\s]*$/, "Contiene caracteres no permitidos").min(1).max(99, "Demasiado largo"),
        maternalLastName: Yup.string().required(" ").matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ][a-zA-ZÀ-ÖØ-öø-ÿ\s]*$/, "Contiene caracteres no permitidos").min(1).max(99, "Demasiado largo"),
        gender: Yup.string().required(" "),
        birthday: Yup.string().required(" "),
        age: Yup.number().typeError("Debe ser un número").required(" ").min(3, "Debe ser mayor a 3").max(120, "Edad no válida"),
        city: Yup.string().required(" ").matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ][a-zA-ZÀ-ÖØ-öø-ÿ\s]*$/, "Contiene caracteres no permitidos").min(1).max(99, "Demasiado largo"),
        state: Yup.string().required(" ").matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ][a-zA-ZÀ-ÖØ-öø-ÿ\s]*$/, "Contiene caracteres no permitidos").min(1).max(99, "Demasiado largo"),
        mothersName: Yup.string().required(" ").matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ][a-zA-ZÀ-ÖØ-öø-ÿ\s]*$/, "Contiene caracteres no permitidos").min(1).max(99, "Demasiado largo"),
        fathersName: Yup.string().matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ][a-zA-ZÀ-ÖØ-öø-ÿ\s]*$/, "Contiene caracteres no permitidos").min(1).max(99, "Demasiado largo"),
        street: Yup.string().required(" ").matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ][a-zA-ZÀ-ÖØ-öø-ÿ\s]*$/, "Contiene caracteres no permitidos").min(1).max(99, "Demasiado largo"),
        number: Yup.string().required(" ").min(1).max(10, "Demasiado largo"),
        neighborhood: Yup.string().required(" ").matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ][a-zA-ZÀ-ÖØ-öø-ÿ\s]*$/, "Contiene caracteres no permitidos").min(1).max(99, "Demasiado largo"),
        postalCode: Yup.number().typeError("Debe ser un número").required(" ").min(0).max(100000, "C.P. no válido"),
        phoneNumber: Yup.string().required(" ").matches(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/, "Teléfono no válido"),
        cellphone: Yup.string().matches(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/, "Teléfono no válido"),
        other: Yup.string().matches(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/, "Teléfono no válido"),
        medicalAlert: Yup.string().max(999, "Demasiado largo")
    }
    return identificacionValidation;
}

export default IdentificationValidation;
