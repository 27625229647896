import '../Styles/forms.css'
import React, { useState, useContext } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import ForgotPasswordModal from './Auth/ForgotPasswordModal'

import AUTH_SERVICE from "../services/auth";
import { UserContext } from "../context/context";
import Layout from '../components/Layout/Layout';
import * as serviceWorkerRegistration from '../serviceWorkerRegistration'

const LogIn = () => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const userContext = useContext(UserContext);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Correo electrónico requerido."),
      password: Yup.string().required("Contraseña requerida."),
    }),
    onSubmit: async (values) => {
      try {
        setErrorMessage("");
        const response = await AUTH_SERVICE.LOGIN(values);
        const { data } = response;
        const { role } = data.user;
        const { phone } = data.user;
        const { token } = data
        localStorage.setItem("token", token)


        if (data.user) {
          userContext.setLoggedUser(data.user);
          if (role === "admin") {
            history.push("/" + role);
          } else if (role === "dentist") {
            history.push("/" + role + "/" + data.user.id);
            serviceWorkerRegistration.subscribe(data.user.id, localStorage.getItem("token"));
          } else if (role === "receptionist") {
            history.push("/" + role);
          } else if (!phone) {
            history.push("/resetpassword");
          } else {
            history.push("/" + role + "/" + data.user.id);
          }
        }
      } catch (error) {
        console.log(error);

        (error.response.data.errors[0].msg === 'You must confirm your email') ?
        setErrorMessage("Confirma tu correo electrónico")
        : setErrorMessage("Usuario o contraseña incorrectos");
      }
    },
  });

  return userContext.loggedUser ? (
    <Redirect to="/" />
  ) : (
    <Layout >
      <div>
        <div className="container-sm pt-3 pt-md-5 mt-md-5 mt-3">
          <div className="row px-2">
            <div className="col col-sm-9 col-md-8 col-lg-6 my-3 card mx-auto p-4">
              <h2 className="text-center font-weight-bold mt-1 mb-3">¡Iniciar Sesión!</h2>
              <form>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Correo Electrónico"
                    className={`form-control form-control-lg ${
                      formik.errors.email && formik.touched.email
                        ? "is-invalid"
                        : ""
                    }`}
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <div className="invalid-feedback">{formik.errors.email}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    id="password"
                    placeholder="Contraseña"
                    className={`form-control form-control-lg ${
                      formik.errors.password && formik.touched.password
                        ? "is-invalid"
                        : ""
                    }`}
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.password && formik.touched.password && (
                    <div className="invalid-feedback">
                      {formik.errors.password}
                    </div>
                  )}
                  {errorMessage && (
                    <div className="text-danger">{errorMessage}</div>
                  )}
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-primary w-100 m-0"
                    onClick={formik.handleSubmit}
                  >
                    Iniciar sesión
                  </button>
                </div>
                <div className="mt-3 text-center">
                  <a className="m-0 mt-5" href="/#" data-toggle="modal" data-target="#forgotPasswordModal"> ¿Olvidaste tu contraseña? </a>
                </div>
                <div className="text-left mt-3 text-center">
                  <p>¿No tienes una cuenta aún? <Link to="/register" className="m-0">¡Regístrate aquí!</Link></p>
                </div>
              </form>
            </div>
          </div>
        </div>

        <ForgotPasswordModal />
      </div>
    </Layout>
  );
};
export default LogIn;
