import React from 'react'
import { useParams } from 'react-router-dom';
import EditPatientForm from '../components/Patient/EditPatientForm'

import Layout from "../components/Layout/Layout";

const EditPatient = (props) => {
  const { id } = useParams() ;
    return (
        <Layout>
            <EditPatientForm
            id={id}
            />
        </Layout>
    )
}

export default EditPatient;
