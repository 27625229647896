import React from "react";
// import NavBar from "../../components/NavBar/NavBar";
import UploadFile from "./Digitalization/UploadFile";
import ExpedientsContainer from './ExpedientsContainer';
import CapturePhoto from "./Digitalization/CapturePhoto";
import Layout from "../Layout/Layout";

const Expedients = () => {
    return (
        <Layout>
            <div className="content">
                <h1 className="pb-3">Expedientes</h1>
                <ExpedientsContainer />
                <h3>Subir expedientes</h3>
                <UploadFile />
                <CapturePhoto />
                <br /><br />
            </div>
        </Layout>
    );
}

export default Expedients;
