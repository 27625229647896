import React, { useRef, useState } from "react";
// Components
import Identification from "./Identification";
import History from "./History";
import DentalHistory from "./DentalHistory";
import ClinicalExam from "./ClinicalExam";
import Treatment from "./Treatment";
import EvolutionNotes from "./EvolutionNotes";
// Validations.
import ConsentmentValidation from "./Validation/ConsentmentValidation";

// Used to convert to a json object from id strings.
import flat from "flat";

const MedicalRecords = () => {
  const formikConsentmentValidation = ConsentmentValidation();

  async function onSubmit(e) {
    // Prevent reload
    e.preventDefault();

    for (var ref in refs) {
      try {
        refs[ref].current.onSubmit();
      } catch (error) {
        console.log(error);
      }
    }

    console.log(flat.unflatten(values));
  }

  // const [values, setValues] = useState({});
  const [values,] = useState({});
  function setValue(value, id) {
      values[id] = value;
  }

  const refs = {
    identification: useRef(null),
    history: useRef(null),
    dentalHistory: useRef(null),
    clinicalExam: useRef(null),
    treatment: useRef(null),
    evolution: useRef(null),
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="container card py-4" style={{ backgroundColor: "white" }}>
        <div className="endRow">
          {/* In reverse */}
          <button type="submit" className="btn btn-primary">
            Guardar
          </button>
        </div>
        <div>
          <h3 className="pl-2 font-weight-bold">
            NOMBRE COMPLETO DEL PACIENTE
          </h3>
        </div>
        <div className="">
          <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                className="nav-link active"
                id="identificacion-tab"
                data-toggle="tab"
                href="#identificacion"
                role="tab"
              >
                Ficha de Identificación
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="nav-link"
                id="historia-medica-tab"
                data-toggle="tab"
                href="#historia-medica"
                role="tab"
              >
                Historia Médica
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="nav-link"
                id="historia-dental-tab"
                data-toggle="tab"
                href="#historia-dental"
                role="tab"
              >
                Historia Dental
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="nav-link"
                id="examen-clinico-tab"
                data-toggle="tab"
                href="#examen-clinico"
                role="tab"
              >
                Examen Clínico
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="nav-link"
                id="tratamiento-tab"
                data-toggle="tab"
                href="#tratamiento"
                role="tab"
              >
                Plan de Tratamiento
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="nav-link"
                id="contact-tab"
                data-toggle="tab"
                href="#notas-evolucion"
                role="tab"
              >
                Notas de Evolución
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="identificacion"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <Identification
                setValue={setValue}
                ref={refs["identification"]}
              />
            </div>
            <div
              className="tab-pane fade"
              id="historia-medica"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <History
                setValue={setValue}
                ref={refs["history"]}
              />
            </div>
            <div
              className="tab-pane fade"
              id="historia-dental"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <DentalHistory
                setValue={setValue}
                ref={refs["dentalHistory"]}
              />
            </div>
            <div
              className="tab-pane fade"
              id="examen-clinico"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <ClinicalExam
                setValue={setValue}
                ref={refs["clinicalExam"]}
              />
            </div>
            <div
              className="tab-pane fade"
              id="tratamiento"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <Treatment
                formikConsentment={formikConsentmentValidation}
                setValue={setValue}
                ref={refs["treatment"]}
              />
            </div>
            <div
              className="tab-pane fade"
              id="notas-evolucion"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <EvolutionNotes setValue={setValue}
                ref={refs["evolution"]}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default MedicalRecords;
