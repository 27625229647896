import axios from '../config/axios';

const DENTIST_SERVICE = {
  GET_ONE: async (id) => {
    return await axios.get(`/dentists/${id}`);
  },
  GET_ALL: async () => {
    return await axios.get("/dentists");
  },
  CREATE: async (data) => {
    return await axios.post('/dentists', data);
  },
  DELETE: async (id) => {
    return await axios.delete(`/dentists/${id}`);
  },

  UPDATE: async (id, data) => {
    return await axios.put(`/${id}`, data);
  },
};

export default DENTIST_SERVICE;
