import React from 'react'
import SignaturePad from 'react-signature-pad-wrapper'

const ModalSign = (props) => {

  const { refer, clear, save, id } = props;
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        data-toggle="modal"
        data-target={`#${id}`}
      >
        Agregar Firma
      </button>
      <div
        className="modal fade"
        id={id}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content bg-light">
            <div className="modal-body mx-1">
              <SignaturePad
                ref={refer}
                options={{
                  backgroundColor: "rgb(255,255,255)",
                  dotSize: 2,
                }}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cerrar
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={clear}
              >
                Limpiar
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={save}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalSign
