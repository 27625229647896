import React, { useEffect, useState } from 'react';
import PROCEDURE_CLINIC from '../../services/clinic';


const AppointmentConfirmation = (props) => {

    //const [adress,setAdress] = useState("Here is the clinic adress");
   const [adress, setAdress ] = useState();
    useEffect(() => {
        
        const obtenerClínica = async () => {
            const {data} = await PROCEDURE_CLINIC.GET_ONE(props.value.clinic);
                setAdress(data.address);
        }
        obtenerClínica();
    }, [props.value.clinic])


    const hours = Math.floor(parseInt(props.value.starts) / 100)
    const mins = ("0" + parseInt(props.value.starts - hours * 100)).slice(-2);
    return (
        <div style={props.style}>
            <p>Se ha guardado tu cita</p>
            <p>Fecha: {props.value.dateString} Hora: {hours}:{mins}</p>
            <p>Dirección: {adress}</p>
        </div>
    );
};

export default AppointmentConfirmation;
