import React from "react";
// import Navbar from "../../components/NavBar/NavBar"

import MedicalRecords from '../../components/MedicalRecords/MedicalRecords'
import Layout from "../../components/Layout/Layout";

const MedicalRecord = () => {
  return (
    <Layout>
      <MedicalRecords />
    </Layout>
  );
};

export default MedicalRecord;
