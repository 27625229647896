import React from 'react';
import Layout from '../../components/Layout/Layout';
import { faUserMd, faUser, faTooth, faClinicMedical, faChartPie, faConciergeBell } from '@fortawesome/free-solid-svg-icons';
import DashboardCard from '../../components/DashboardCard/DashboardCard';

const Admin = () => {
  return (
    <Layout>
      <div className="col pt-4">
        <div className="row no-gutters mx-2">
          <DashboardCard
            icon={faUserMd}
            title="Dentistas"
            links={[
              {
                title: "Ver dentistas",
                url: "/admin/dentists"
              },
              {
                title: "Crear dentista",
                url: "/admin/dentist/new"
              }
            ]}
            backgroundColor="#063348"
          />
          <DashboardCard
            icon={faUser}
            title="Pacientes"
            links={[
              {
                title: "Ver pacientes",
                url: "/patients"
              }
            ]}
            backgroundColor="#063348"
          />
          <DashboardCard
            icon={faConciergeBell}
            title="Recepcionistas"
            links={[
              {
                title: "Ver recepcionistas",
                url: "/receptionists"
              },
              {
                title: "Crear recepcionista",
                url: "/receptionists/new"
              }
            ]}
            backgroundColor="#063348"
          />
          <DashboardCard
            icon={faTooth}
            title="Procedimientos"
            links={[
              {
                title: "Ver procedimientos",
                url: "/procedures"
              },
              {
                title: "Crear procedimiento",
                url: "/procedures/new"
              }
            ]}
            backgroundColor="#063348"
          />
          <DashboardCard
            icon={faClinicMedical}
            title="Clínicas"
            links={[
              {
                title: "Ver clínicas",
                url: "/clinics"
              },
              {
                title: "Crear clínica",
                url: "/clinics/new"
              }
            ]}
            backgroundColor="#063348"
          />
          <DashboardCard
            icon={faChartPie}
            title="Reportes"
            links={[
              {
                title: "Ver reportes",
                url: "/reports"
              }
            ]}
            backgroundColor="#063348"
          />
        </div>
      </div>
    </Layout>
  );
}

export default Admin;
