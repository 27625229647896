import React, { useState, useContext, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import AppointmentFormStep1 from "./AppointmentFormStep1";
import AppointmentFormStep2 from "./AppointmentFormStep2";
import AppointmentConfirmation from "./AppointmentConfirmation";
import { UserContext } from "../../context/context";

import APPOINTMENT_SERVICE from "../../services/appointment";
import MINOR_SERVICE from "../../services/minor";
import PROCEDURE_SERVICE from '../../services/procedure';
import PROCEDURE_CLINIC from '../../services/clinic';
import { useHistory } from 'react-router-dom';

const AppointmentModal = (props) => {

  const userContext = useContext(UserContext);
  const [schedules, setSchedules] = useState([]);
  const [clinics, setClinics] = useState([]);
  const [procedures, setProcedures] = useState([]);

  // Fetch clinics and procedures
  useEffect(() => {
    let isMounted = true;
    const getProcedures = async () => {
      const { data } = await PROCEDURE_SERVICE.GET_ALL();
      if (isMounted)
        setProcedures(data)
    };

    const getClinics = async () => {
      const { data } = await PROCEDURE_CLINIC.GET_ALL();
      if (isMounted)
        setClinics(data)
    };

    getProcedures();
    getClinics();
    return () => { isMounted = false }; // cleanup toggles value, if unmounted
  }, []);

  // States for pages
  const [pageState, setPage] = useState(0);
  let history = useHistory();

  useEffect(() => {
    if(pageState===2){
      setTimeout(()=>{
        saveItAndReload();
      },2000)

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pageState])

  // Change page
  const nextPage = () => {
    setPage(pageState + 1);
  };

  const backPage = () => {
    setPage(pageState - 1);
  };

  // State for minors checkbox
  const [minorsState, setMinorsState] = useState(false);

  // toggle minors checkbox
  const toggleMinorsForms = () => {
    formikStep1.handleReset();
    formikStep1a.handleReset();

    setMinorsState(!minorsState);
  };

  // Hide or show the correct page
  let showPage1 = pageState === 0 ? { display: "block" } : { display: "none" };
  let showPage2 = pageState === 1 ? { display: "block" } : { display: "none" };
  let showPage3 = pageState === 2 ? { display: "block" } : { display: "none" };

  //Modify the buttons according to the page
  let backButtonText =
    pageState === 1 ? (pageState === 2 ? "Ok" : "Volver") : "Cerrar";
  let nextButtonText =
    pageState === 0 ? "Siguiente" : pageState === 1 ? "Guardar" : "";
  let styleButton =
    pageState === 2
      ? { display: "none" }
      : pageState === 0 && !minorsState
        ? { display: "none" }
        : { display: "" };
  let backStyleButton = pageState === 2 ? { display: "none" } : { display: "" };
  let styleButtonMinors =
    pageState === 0 && !minorsState ? { display: "" } : { display: "none" };

  // Handles the form in step 1
  const formikStep1 = useFormik({
    initialValues: {
      service: "Elige...",
      clinic: "Elige..."
    },

    validationSchema: Yup.object({
      service: Yup.string().notOneOf(
        ["Elige...", ""],
        "Elige una opción valida"
      ),
      clinic: Yup.string().notOneOf(
        ["Elige...", ""],
        "Elige una opción valida"
      ),
    }),
  });

  // Handles the form in step 1a
  const formikStep1a = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      age: "",
      clinic: "Elige...",
      service: "Elige...",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("Requerido")
        .max(15, "Debe ser menor a 15 caracteres"),
      lastName: Yup.string()
        .required("Requerido")
        .max(15, "Debe ser menor a 15 caracteres"),
      age: Yup.number()
        .required("Requerido")
        .positive("Debe ser un numero positivo")
        .integer("Debe ser un numero entero")
        .typeError("Debe ser un numero")
        .lessThan(18, "Solo se pueden registrar menores de edad.")
        .moreThan(
          12,
          "Los menores de 12 solo son atendidos por emergencia, por favor acuda a la clínica."
        ),
      service: Yup.string().notOneOf(
        ["Elige...", ""],
        "Elige una opción valida"
      ),
      clinica: Yup.string().notOneOf(
        ["Elige...", ""],
        "Elige una opción valida"
      ),
    }),
  });

  // Handles the form in step 2
  const formikStep2 = useFormik({
    initialValues: {
      date: "",
      hour: "",
      dentists: "",
    },
    validationSchema: Yup.object({
      date: Yup.date().required("Requerido"),
      hour: Yup.string().required("Requerido"),
    }),
  });

  const formik = useFormik({
    initialValues: {
      date: "",
      starts: "",
      ends: "",
      patient: "",
      dentistsArray: "",
      clinic: "",
      procedure: "Elige...",
      category: "fAIK",
      status: "",

      firstName: "",
      lastName: "",
      age: "",

      procedureInfo: "",
      clinicInfo: "",
      dateString: "",
    },
    onSubmit: (values) => {
      if (pageState === 0) {
        nextPage();
      } else if (pageState === 1) {
        values.procedureInfo = minorsState
          ? procedures[formikStep1a.values.service]
          : procedures[formikStep1.values.service];
        values.procedure = minorsState
          ? procedures[formikStep1a.values.service]
          : procedures[formikStep1.values.service];
        values.dateString = formikStep2.values.date;
        values.clinic = minorsState
        ? formikStep1a.values.clinic
        : formikStep1.values.clinic;
        const startHour = schedules[parseInt(formikStep2.values.hour)].hour;
        // const hours = Math.floor(parseInt(values.procedureInfo.duration) / 60);
        // const minutes = parseInt(values.procedureInfo.duration) - hours * 60;
        // const end = parseInt(startHour) + hours * 100 + minutes;

        values.date = formikStep2.values.date;
        values.starts = startHour;
        const end = (parseInt(startHour) + 100);
        values.ends = end.toString();

        values.patient = props.userId;
        values.dentistsArray = formikStep2.values.dentists;
        values.status = 0;

        values.category = minorsState
          ? formikStep1a.values.service
          : formikStep1.values.service;
        values.firstName = formikStep1a.values.firstName;
        values.lastName = formikStep1a.values.lastName;
        values.age = formikStep1a.values.age;
        nextPage();
      }
    },
  });

  const saveAppointment = async () => {
    let {
      date,
      starts,
      ends,
      patient,
      dentistsArray,
      clinic,
      procedure,
      category,
      status,
    } = formik.values;
    const { firstName, lastName, age } = formik.values;

    // Added now
    let hours = "";
    let mins = "";
    if (starts.length === 4)
    {
      hours = starts.substring(0, 2)
      mins = starts.substring(2, 4)
    }
    else
    {
      hours = starts.substring(0, 1)
      mins = starts.substring(1, 3)
    }
    date = date.replaceAll('-', '/')
    const withTimeDate = new Date(date);
    withTimeDate.setHours(parseInt(hours), parseInt(mins), 0)

    const patientData = {
      date: withTimeDate.toISOString(),
      starts,
      ends,
      patient,
      dentistsArray,
      clinic,
      procedure,
      category,
      status,
      minor: undefined,
    };
    const minorData = { name: firstName, lastName, age, tutor: patient };
    if (minorsState) {
      MINOR_SERVICE.CREATE(minorData).then(res => {
        patientData.minor = res.data._id;
        APPOINTMENT_SERVICE.CREATE(patientData).then(userContext.changeSate());
      });
    }
    else {
      APPOINTMENT_SERVICE.CREATE(patientData).then(userContext.changeSate());
    }

    resetState();
  };
  const saveItAndReload = async () => {
      if (pageState === 2) {
        await saveAppointment();
        history.go(0);
      }
    }



  // Reset the state of the component
  const resetState = () => {
    formikStep1.handleReset();
    formikStep1a.handleReset();
    formikStep2.handleReset();
    formik.handleReset();
    props.close();
    setMinorsState(false);
    setPage(0);
    setSchedules([]);
  };

  return (
    <div className={`modal ${props.modalClass}`} style={props.modalStyle}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <form onSubmit={formik.handleSubmit}>
            {/* Form content */}
            <div className="modal-body">
              <div>Nueva Cita</div>
              <AppointmentFormStep1
                formik={formikStep1}
                formik1a={formikStep1a}
                style={showPage1}
                minors={minorsState}
                toggleMinors={toggleMinorsForms}
                procedures={procedures}
                clinics={clinics}
                showMinorsToggle
              />
              <AppointmentFormStep2
                edit={false}
                procedure={
                  minorsState
                    ? formikStep1a.values.service
                    : formikStep1.values.service
                }
                procedures={procedures}
                schedules={schedules}
                setSchedules={setSchedules}
                formik={formikStep2}
                style={showPage2}
                dentist={null}
                clinicId={formik.values}
              />

              <AppointmentConfirmation
                value={formik.values}
                style={showPage3}
              />
            </div>

            <div className="modal-footer">
              <div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  style={backStyleButton}
                  onClick={
                    pageState === 0
                      ? resetState
                      : pageState === 1
                        ? backPage
                        : saveItAndReload
                  }
                >
                  {backButtonText}
                </button>
                <button
                  type={"submit"}
                  style={styleButton}
                  className="btn btn-primary"
                  disabled={
                    pageState === 0
                      ? !(formikStep1a.isValid && formikStep1a.dirty)
                      : !(formikStep2.isValid && formikStep2.dirty)
                  }
                >
                  {nextButtonText}
                </button>
                <button
                  type={"submit"}
                  style={styleButtonMinors}
                  className="btn btn-primary"
                  disabled={
                    pageState === 0
                      ? !(formikStep1.isValid && formikStep1.dirty)
                      : !(formikStep2.isValid && formikStep2.dirty)
                  }
                >
                  Siguiente
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AppointmentModal;
