import React, { useEffect, useState, useContext } from "react";

import SCHEDULE_SERVICE from '../../services/schedule';
import DatePicker from 'react-datepicker';
import { UserContext } from "../../context/context";

import "react-datepicker/dist/react-datepicker.css";

const AppointmentFormStep2 = (props) => {
  const [contactMethod, setContactMethod] = useState('')

  const userContext = useContext(UserContext);

  useEffect( () => {
    let { phone, contactMethods: { sms } } = userContext.loggedUser;

    if (!phone && sms) setContactMethod('')

    const msg =  `y ${ sms ? 'mensaje de texto' : 'Whatsapp' } al número ${ phone }`;

    setContactMethod(msg);

  }, [userContext])

  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 46);
  // Handles the change of hour
  const handleHour = (e) => {
    // Gets the dentist array from that schedule
    const dentists = props.schedules[e.target.value]?.dentists?.map(
      (dentist) => {
        return dentist.id;
      }
    );
    !props.dentist
      ? (props.formik.values.dentists = dentists)
      : (props.formik.values.dentists = [props.dentist.id]);
    props.formik.handleChange(e);
  };

  const idClinic = sessionStorage.getItem('valor');
  const noDentistsFlag = props.schedules.length || !props.formik.values.date ? false : true

  return (
    <div className="col" style={props.style}>
      {/* Date Form  */}
      <div className='row'>Seleccione fecha y hora:</div>
      <div className="form-group row">
        <label htmlFor="date">*Fecha:</label>

        <div className="d-flex flex-row" >
          {/* <input
                type="date" */}
          <DatePicker
            customInput={<input className='form-control' />}
            autoComplete={"off"}
            // selected={props.formik.values.date !== '' ? new Date() : null}
            className={`${props.formik.errors.date && props.formik.touched.date
              ? "is-invalid"
              : ""
              }`}
            dateFormat="yyyy-mm-dd"
            disabledDays={[0, 6]}
            minDate={new Date()}
            maxDate={maxDate}
            filterDate={date => {
              const day = date.getDay();
              return day !== 0 && day !== 6;
            }}
            id="date"
            name="date"
            value={props.formik.values.date}
            onChange={rawDate => {
              const year = rawDate.getFullYear();
              const month = rawDate.getMonth() + 1;
              let day = rawDate.getDate();
              day = day < 10 ? "0" + day : day;
              const date = year + '-' + month + '-' + day;
              props.formik.values.date = date;
              const procedure = props.procedure;
              !props.dentist
                ? SCHEDULE_SERVICE.GET_ALL(procedure, date,idClinic).then(res => {
                  const data = res.data;

                  props.setSchedules(data);
                })
                : SCHEDULE_SERVICE.GET_ALL_DENTIST(props.dentist.id, date).then(res => {
                  const data = res.data;
                  props.setSchedules(data);
                });

            }}
            onBlur={props.formik.handleBlur} />
          {props.formik.errors.date && props.formik.touched.date && (
            <div className="invalid-feedback">{props.formik.errors.date}</div>
          )}

        </div>
        {noDentistsFlag &&
          <div className="text-danger"><small>No hay dentistas disponibles para el dia seleccionado</small></div>
        }
      </div>

      <div className="form-group row">
        <label htmlFor="hour">*Hora: </label>
        <select
          id="hour"
          name="hour"
          className={`form-control ${props.formik.errors.hour && props.formik.touched.hour
            ? "is-invalid"
            : ""
            }`}
          onBlur={props.formik.handleBlur}
          onChange={handleHour}
          value={props.formik.values.hour}>

          <option defaultValue>Elige...</option>
          {props.schedules.map((schedule, index) => {
            const hours = Math.floor(parseInt(schedule.hour) / 100);
            const mins = ("0" + parseInt(schedule.hour - hours * 100)).slice(
              -2
            );
            return (
              <option value={index} key={index}>
                {hours}:{mins}
              </option>
            );
          })}
        </select>
        {props.minors ? (
          props.formik1a.errors.service && props.formik1a.touched.service ? (
            <div className="invalid-feedback">
              {props.formik1a.errors.service}
            </div>
          ) : null
        ) : props.formik.errors.service && props.formik.touched.service ? (
          <div className="invalid-feedback">{props.formik.errors.service}</div>
        ) : null}
        <div className={"mt-2 text-secondary"}>
          La confimación de esta cita se enviará mediante correo electronico { contactMethod }.
        </div>
      </div>
    </div>
  );
};

export default AppointmentFormStep2;
