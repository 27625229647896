import axios from '../config/axios';

const separateCategories = (dict) => {
  let separated = {};
  for (let item of dict) {
    if (item['category'] in separated) {
      separated[item['category']].push(item);
    } else {
      separated[item['category']] = [item];
    }
  }
  return separated;
}

const PROCEDURE_SERVICE = {
  GET_ONE: async (id) => {
    // console.log('CALLING HERE');
    return await axios.get(`/procedures/${id}`);
  },

  GET_ALL: async () => {
    return await axios.get('/procedures');
  },

  GET_BY_CATEGORY: async (category) => {
    let data = await axios.get('/procedures', {});
    let temp = separateCategories(data.data)[category];
    data.data = temp;
    return data;

  },

  GET_CATEGORIES: async () => {
    let data = await axios.get('/procedures', {});
    let temp = Object.keys(separateCategories(data.data));
    data.data = temp;
    return data;
  },

  CREATE: async (data) => {
    return await axios.post('/procedures', data);
  },

  DELETE: async (id) => {
    return await axios.delete(`/procedures/${id}`);
  },

  UPDATE: async (id, data) => {
    return await axios.put(`/procedures/${id}`, data);
  },
};

export default PROCEDURE_SERVICE;
