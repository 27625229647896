import React, { forwardRef, useImperativeHandle, useRef } from "react";
import FastTextInput from './FastTextInput'
import { faTooth } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TreatmentValidation from "../Validation/TreatmentValidation";

let bottomLine = {
    backgroundColor: "transparent", border: "none", borderBottom: "1px solid #000000", borderRadius: "0"
}

const CollapsableButton = ({ btnId, dataTarget, rangeNumbers }) => {
    const margin = btnId === "expandFourth" ? "" : "mr-1";
    const collapse = btnId === "expandFirst" ? "" : "collapsed";
    return (
        <div className={`accordion-item flex flex-column flex-grow marall05 ${margin}`}>
            <button className={`accordion-button btn-primary dropdown-toggle rounded ml-0 my-1 py-2 ${collapse}`}
                id={btnId}
                type="button"
                data-toggle="collapse" data-target={dataTarget}>
                <FontAwesomeIcon icon={faTooth} size="1x" className="mr-3" />
                {rangeNumbers}
            </button>
        </div>
    )
}

function TextInputs(props, ref) {
    const { setValue } = props;
    const treatmentValidation = TreatmentValidation();
    const refs = {
        "treatmentTooth17": useRef(null),
        "treatmentTooth27": useRef(null),
        "treatmentTooth16": useRef(null),
        "treatmentTooth26": useRef(null),
        "treatmentTooth15": useRef(null),
        "treatmentTooth25": useRef(null),
        "treatmentTooth14": useRef(null),
        "treatmentTooth24": useRef(null),
        "treatmentTooth13": useRef(null),
        "treatmentTooth23": useRef(null),
        "treatmentTooth12": useRef(null),
        "treatmentTooth22": useRef(null),
        "treatmentTooth11": useRef(null),
        "treatmentTooth21": useRef(null),
        "treatmentTooth55": useRef(null),
        "treatmentTooth65": useRef(null),
        "treatmentTooth54": useRef(null),
        "treatmentTooth64": useRef(null),
        "treatmentTooth53": useRef(null),
        "treatmentTooth63": useRef(null),
        "treatmentTooth52": useRef(null),
        "treatmentTooth62": useRef(null),
        "treatmentTooth51": useRef(null),
        "treatmentTooth61": useRef(null),
        "treatmentTooth85": useRef(null),
        "treatmentTooth75": useRef(null),
        "treatmentTooth84": useRef(null),
        "treatmentTooth74": useRef(null),
        "treatmentTooth83": useRef(null),
        "treatmentTooth73": useRef(null),
        "treatmentTooth82": useRef(null),
        "treatmentTooth72": useRef(null),
        "treatmentTooth81": useRef(null),
        "treatmentTooth71": useRef(null),
        "treatmentTooth47": useRef(null),
        "treatmentTooth37": useRef(null),
        "treatmentTooth46": useRef(null),
        "treatmentTooth36": useRef(null),
        "treatmentTooth45": useRef(null),
        "treatmentTooth35": useRef(null),
        "treatmentTooth44": useRef(null),
        "treatmentTooth34": useRef(null),
        "treatmentTooth43": useRef(null),
        "treatmentTooth33": useRef(null),
        "treatmentTooth42": useRef(null),
        "treatmentTooth32": useRef(null),
        "treatmentTooth41": useRef(null),
        "treatmentTooth31": useRef(null),
    };

    useImperativeHandle(ref, () => ({
        async onSubmit() {
            const errors = [];
            for (let ref in refs) {
                try {
                    const eachErrors = await refs[ref].current.onSubmit();
                    for (let err in eachErrors)
                        errors.push(eachErrors[err]);
                } catch (error) {
                    console.log(ref);
                }
            }

            return errors;
        },
        setInitialValue(values) {
            for (let ref in refs) {
                try {
                    refs[ref].current.setInitialValue(values);
                } catch (error) {
                    console.log(ref);
                }
            }
        },
    }));

    return (
        <div>
            <div className="form-group accordion" id="accordionPanels">
                <div className="form-row flex flex-align-items-start flex-wrap">
                    <CollapsableButton btnId="expandFirst" dataTarget="#first" rangeNumbers="17...11 - 27...21" />
                    <CollapsableButton btnId="expandSecond" dataTarget="#second" rangeNumbers="55...51 - 65...61" />
                    <CollapsableButton btnId="expandThird" dataTarget="#third" rangeNumbers="85...81 - 75...71" />
                    <CollapsableButton btnId="expandFourth" dataTarget="#fourth" rangeNumbers="47...41 - 37...31" />
                </div>
                <div id="first" className="accordion-collapse collapse marall1" data-parent="#accordionPanels">
                    <div className="accordion-body">
                        <div className="form-group flex flex-row flex-just-space-even">
                            <label>17</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth17}
                                setValue={setValue}
                                id='treatment.tooth.t17'
                                style={bottomLine}
                                ref={refs["treatmentTooth17"]}
                            />
                            <label>27</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth27}
                                setValue={setValue}
                                id='treatment.tooth.t27'
                                style={bottomLine}
                                ref={refs["treatmentTooth27"]}
                            />
                        </div>
                        <div className="form-group flex flex-row flex-just-space-even">
                            <label>16</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth16}
                                setValue={setValue}
                                id='treatment.tooth.t16'
                                style={bottomLine}
                                ref={refs["treatmentTooth16"]}
                            />
                            <label>26</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth26}
                                setValue={setValue}
                                id='treatment.tooth.t26'
                                style={bottomLine}
                                ref={refs["treatmentTooth26"]}
                            />
                        </div>
                        <div className="form-group flex flex-row flex-just-space-even">
                            <label>15</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth15}
                                setValue={setValue}
                                id='treatment.tooth.t15'
                                style={bottomLine}
                                ref={refs["treatmentTooth15"]}
                            />
                            <label>25</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth25}
                                setValue={setValue}
                                id='treatment.tooth.t25'
                                style={bottomLine}
                                ref={refs["treatmentTooth25"]}
                            />
                        </div>
                        <div className="form-group flex flex-row flex-just-space-even">
                            <label>14</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth14}
                                setValue={setValue}
                                id='treatment.tooth.t14'
                                style={bottomLine}
                                ref={refs["treatmentTooth14"]}
                            />
                            <label>24</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth24}
                                setValue={setValue}
                                id='treatment.tooth.t24'
                                style={bottomLine}
                                ref={refs["treatmentTooth24"]}
                            />
                        </div>
                        <div className="form-group flex flex-row flex-just-space-even">
                            <label>13</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth13}
                                setValue={setValue}
                                id='treatment.tooth.t13'
                                style={bottomLine}
                                ref={refs["treatmentTooth13"]}
                            />
                            <label>23</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth23}
                                setValue={setValue}
                                id='treatment.tooth.t23'
                                style={bottomLine}
                                ref={refs["treatmentTooth23"]}
                            />
                        </div>
                        <div className="form-group flex flex-row flex-just-space-even">
                            <label>12</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth12}
                                setValue={setValue}
                                id='treatment.tooth.t12'
                                style={bottomLine}
                                ref={refs["treatmentTooth12"]}
                            />
                            <label>22</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth22}
                                setValue={setValue}
                                id='treatment.tooth.t22'
                                style={bottomLine}
                                ref={refs["treatmentTooth22"]}
                            />
                        </div>
                        <div className="form-group flex flex-row flex-just-space-even">
                            <label>11</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth11}
                                setValue={setValue}
                                id='treatment.tooth.t11'
                                style={bottomLine}
                                ref={refs["treatmentTooth11"]}
                            />
                            <label>21</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth21}
                                setValue={setValue}
                                id='treatment.tooth.t21'
                                style={bottomLine}
                                ref={refs["treatmentTooth21"]}
                            />
                        </div>
                    </div>
                </div>
                <div id="second" className="accordion-collapse collapse marall1" data-parent="#accordionPanels">
                    <div className="accordion-body">
                        <div className="form-group flex flex-row flex-just-space-even">
                            <label>55</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth55}
                                setValue={setValue}
                                id='treatment.tooth.t55'
                                style={bottomLine}
                                ref={refs["treatmentTooth55"]}
                            />
                            <label>65</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth65}
                                setValue={setValue}
                                id='treatment.tooth.t65'
                                style={bottomLine}
                                ref={refs["treatmentTooth65"]}
                            />
                        </div>
                        <div className="form-group flex flex-row flex-just-space-even">
                            <label>54</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth54}
                                setValue={setValue}
                                id='treatment.tooth.t54'
                                style={bottomLine}
                                ref={refs["treatmentTooth54"]}
                            />
                            <label>64</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth64}
                                setValue={setValue}
                                id='treatment.tooth.t64'
                                style={bottomLine}
                                ref={refs["treatmentTooth64"]}
                            />
                        </div>
                        <div className="form-group flex flex-row flex-just-space-even">
                            <label>53</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth53}
                                setValue={setValue}
                                id='treatment.tooth.t53'
                                style={bottomLine}
                                ref={refs["treatmentTooth53"]}
                            />
                            <label>63</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth63}
                                setValue={setValue}
                                id='treatment.tooth.t63'
                                style={bottomLine}
                                ref={refs["treatmentTooth63"]}
                            />
                        </div>
                        <div className="form-group flex flex-row flex-just-space-even">
                            <label>52</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth52}
                                setValue={setValue}
                                id='treatment.tooth.t52'
                                style={bottomLine}
                                ref={refs["treatmentTooth52"]}
                            />
                            <label>62</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth62}
                                setValue={setValue}
                                id='treatment.tooth.t62'
                                style={bottomLine}
                                ref={refs["treatmentTooth62"]}
                            />
                        </div>
                        <div className="form-group flex flex-row flex-just-space-even">
                            <label>51</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth51}
                                setValue={setValue}
                                id='treatment.tooth.t51'
                                style={bottomLine}
                                ref={refs["treatmentTooth51"]}
                            />
                            <label>61</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth61}
                                setValue={setValue}
                                id='treatment.tooth.t61'
                                style={bottomLine}
                                ref={refs["treatmentTooth61"]}
                            />
                        </div>

                    </div>
                </div>
                <div id="third" className="accordion-collapse collapse marall1" data-parent="#accordionPanels">
                    <div className="accordion-body">
                        <div className="form-group flex flex-row flex-just-space-even">
                            <label>85</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth85}
                                setValue={setValue}
                                id='treatment.tooth.t85'
                                style={bottomLine}
                                ref={refs["treatmentTooth85"]}
                            />
                            <label>75</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth75}
                                setValue={setValue}
                                id='treatment.tooth.t75'
                                style={bottomLine}
                                ref={refs["treatmentTooth75"]}
                            />
                        </div>
                        <div className="form-group flex flex-row flex-just-space-even">
                            <label>84</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth84}
                                setValue={setValue}
                                id='treatment.tooth.t84'
                                style={bottomLine}
                                ref={refs["treatmentTooth84"]}
                            />
                            <label>74</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth74}
                                setValue={setValue}
                                id='treatment.tooth.t74'
                                style={bottomLine}
                                ref={refs["treatmentTooth74"]}
                            />
                        </div>
                        <div className="form-group flex flex-row flex-just-space-even">
                            <label>83</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth83}
                                setValue={setValue}
                                id='treatment.tooth.t83'
                                style={bottomLine}
                                ref={refs["treatmentTooth83"]}
                            />
                            <label>73</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth73}
                                setValue={setValue}
                                id='treatment.tooth.t73'
                                style={bottomLine}
                                ref={refs["treatmentTooth73"]}
                            />
                        </div>
                        <div className="form-group flex flex-row flex-just-space-even">
                            <label>82</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth82}
                                setValue={setValue}
                                id='treatment.tooth.t82'
                                style={bottomLine}
                                ref={refs["treatmentTooth82"]}
                            />
                            <label>72</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth72}
                                setValue={setValue}
                                id='treatment.tooth.t72'
                                style={bottomLine}
                                ref={refs["treatmentTooth72"]}
                            />
                        </div>
                        <div className="form-group flex flex-row flex-just-space-even">
                            <label>81</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth81}
                                setValue={setValue}
                                id='treatment.tooth.t81'
                                style={bottomLine}
                                ref={refs["treatmentTooth81"]}
                            />
                            <label>71</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth71}
                                setValue={setValue}
                                id='treatment.tooth.t71'
                                style={bottomLine}
                                ref={refs["treatmentTooth71"]}
                            />
                        </div>

                    </div>
                </div>
                <div id="fourth" className="accordion-collapse collapse marall1" data-parent="#accordionPanels">
                    <div className="accordion-body">
                        <div className="form-group flex flex-row flex-just-space-even">
                            <label>47</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth47}
                                setValue={setValue}
                                id='treatment.tooth.t47'
                                style={bottomLine}
                                ref={refs["treatmentTooth47"]}
                            />
                            <label>37</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth37}
                                setValue={setValue}
                                id='treatment.tooth.t37'
                                style={bottomLine}
                                ref={refs["treatmentTooth37"]}
                            />
                        </div>
                        <div className="form-group flex flex-row flex-just-space-even">
                            <label>46</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth46}
                                setValue={setValue}
                                id='treatment.tooth.t46'
                                style={bottomLine}
                                ref={refs["treatmentTooth46"]}
                            />
                            <label>36</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth36}
                                setValue={setValue}
                                id='treatment.tooth.t36'
                                style={bottomLine}
                                ref={refs["treatmentTooth36"]}
                            />
                        </div>
                        <div className="form-group flex flex-row flex-just-space-even">
                            <label>45</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth45}
                                setValue={setValue}
                                id='treatment.tooth.t45'
                                style={bottomLine}
                                ref={refs["treatmentTooth45"]}
                            />
                            <label>35</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth35}
                                setValue={setValue}
                                id='treatment.tooth.t35'
                                style={bottomLine}
                                ref={refs["treatmentTooth35"]}
                            />
                        </div>
                        <div className="form-group flex flex-row flex-just-space-even">
                            <label>44</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth44}
                                setValue={setValue}
                                id='treatment.tooth.t44'
                                style={bottomLine}
                                ref={refs["treatmentTooth44"]}
                            />
                            <label>34</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth34}
                                setValue={setValue}
                                id='treatment.tooth.t34'
                                style={bottomLine}
                                ref={refs["treatmentTooth34"]}
                            />
                        </div>
                        <div className="form-group flex flex-row flex-just-space-even">
                            <label>43</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth43}
                                setValue={setValue}
                                id='treatment.tooth.t43'
                                style={bottomLine}
                                ref={refs["treatmentTooth43"]}
                            />
                            <label>33</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth33}
                                setValue={setValue}
                                id='treatment.tooth.t33'
                                style={bottomLine}
                                ref={refs["treatmentTooth33"]}
                            />
                        </div>
                        <div className="form-group flex flex-row flex-just-space-even">
                            <label>42</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth42}
                                setValue={setValue}
                                id='treatment.tooth.t42'
                                style={bottomLine}
                                ref={refs["treatmentTooth42"]}
                            />
                            <label>32</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth32}
                                setValue={setValue}
                                id='treatment.tooth.t32'
                                style={bottomLine}
                                ref={refs["treatmentTooth32"]}
                            />
                        </div>
                        <div className="form-group flex flex-row flex-just-space-even">
                            <label>41</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth41}
                                setValue={setValue}
                                id='treatment.tooth.t41'
                                style={bottomLine}
                                ref={refs["treatmentTooth41"]}
                            />
                            <label>31</label>
                            <FastTextInput
                                validation={treatmentValidation.treatmentTooth31}
                                setValue={setValue}
                                id='treatment.tooth.t31'
                                style={bottomLine}
                                ref={refs["treatmentTooth31"]}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default forwardRef(TextInputs);
