import React, { useEffect, useRef, useState } from "react";
import { useParams , useHistory} from "react-router";
// Components
import Layout from "../components/Layout/Layout";
import Identification from "../components/MedicalRecords/Identification";
import History from "../components/MedicalRecords/History";
import DentalHistory from "../components/MedicalRecords/DentalHistory";
import ClinicalExam from "../components/MedicalRecords/ClinicalExam";
import Treatment from "../components/MedicalRecords/Treatment";
import EvolutionNotes from "../components/MedicalRecords/EvolutionNotes";

// Services
import PATIENT_SERVICE from '../services/patient';
import RECORDS_SERVICE from '../services/records'

// Used to convert to a json object from id strings.
import flat from "flat";

const TreatmentInfo = () => {
    const { id, recordId } = useParams();
    const [closed, setClosed] = useState();
    const [showMessage, setShowMessage] = useState(false);
    const [values] = useState({});
    const setValue = (value, id) => {
        values[id] = value;
    }
    const history = useHistory();

    // eslint-disable-next-line no-unused-vars
    const onSubmit = async (e) => {
        // Prevent reload
        e.preventDefault();

        const jsonValues = flat.unflatten(values);

        // Extract observations if any
        const newObservations = jsonValues.medicalHistory?.observations;

        // delete unused values
        delete jsonValues['generalInfo'];
        delete jsonValues['medicalHistory'];
        delete jsonValues['dentalHistory'];
        delete jsonValues['clinicalExam'];

        // Check for errors
        const errors = [];
        for (var ref in refs) {
            try {
                const eachErrors = await refs[ref].current.onSubmit();
                for (var err in eachErrors)
                    errors.push(eachErrors[err]);
            } catch (error) {
                console.log(ref);
            }
        }

        const filteredErrors = [];
        const filter = new RegExp('generalInfo*|medicalHistory*|dentalHistory*|clinicalExam*');
        for (var errr in errors) {
            if (!filter.test(errors[errr]))
                filteredErrors.push(errors[errr]);
        }

        const patienServiceModify = async (id, updatedData) => {
            await PATIENT_SERVICE.MODIFY(id, updatedData);
        }

        if (!filteredErrors.length) {
            // If there is no errors
            if (newObservations) {
                const updatedData = {
                    medicalHistory: {
                        observations: newObservations
                    }
                }
                try {
                    patienServiceModify(id, updatedData);
                } catch (error) {
                    console.log(error)
                }
            }
        }
    }

    const [patientName, setName] = useState("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const refs = {
        identification: useRef(null),
        history: useRef(null),
        dentalHistory: useRef(null),
        clinicalExam: useRef(null),
        treatment: useRef(null),
        evolution: useRef(null),
    };

    useEffect(() => {
        const getTreatmentInfo = async () => {
            const patientProfile = await PATIENT_SERVICE.GET_ONE(id);
            const profile = patientProfile.data;

            const medicalRecord = await RECORDS_SERVICE.GET_ONE_FROM_PATIENT(id, recordId);
            const records = medicalRecord.data;


            const data = {};
            for (var info in profile)
                data[info] = profile[info];

            for (var record in records)
                data[record] = records[record];

            // Values that already exist and don't change.
            for (var ref in refs) {
                try {
                    refs[ref].current.setInitialValues(flat.flatten(data));
                } catch (error) {
                    console.log(ref);
                }
            }

            const generalInfo = data.generalInfo;
            setName(
                `${generalInfo.name} ${generalInfo.paternalLastName} ${generalInfo.maternalLastName}`
            );
        }

        const getMedicalRecordInfo = async () => {
            const { data } = await RECORDS_SERVICE.GET_ONE_FROM_PATIENT(id, recordId);
            //setMedicalRecordInfo(data);
            setClosed(data.closed);
        }

        getTreatmentInfo();
        getMedicalRecordInfo();
    }, [id, recordId, refs]);


    //This could be the good one ma boisss
    const updateProfileInfo = async (e) => {
        e.preventDefault();
        const jsonValues = flat.unflatten(values);
        await RECORDS_SERVICE.MODIFY(id, recordId, jsonValues);
        setShowMessage(true)
        setTimeout(() => {
            setShowMessage(false);
        }, 3000);

    };
    const closeMedicalRecord = async (e) => {
        e.preventDefault();
        const jsonValues = {
            closed: true
        }
        await RECORDS_SERVICE.MODIFY(id, recordId, jsonValues)
        setClosed(closed);
        history.go(0)
    };

    return (

        <Layout>
            <form >

                <div className="container card py-4" style={{ backgroundColor: "white" }}>
                    {showMessage
                        ?
                        <p className="alert alert-success">Se actualizó el expediente</p>
                        :
                        <p></p>
                    }

                    <div className="endRow">
                        {/* In reverse */}
                        {
                            !closed ?
                                <>'
                                    <button className="btn btn-primary" onClick={(e) => { updateProfileInfo(e) }}>
                                        Actualizar datos
                                    </button>
                                    <button className="btn btn-primary" onClick={(e) => { closeMedicalRecord(e) }}>
                                        Cerrar expediente
                                    </button>
                                </>
                                :
                                <p></p>
                        }





                    </div>
                    {closed ? <div class="alert alert-danger mt-3" role="alert">
                        El expediente está cerrado.
                    </div> : <p></p>}
                    <div>
                        <h3 className="pl-2 font-weight-bold">
                            {patientName.toUpperCase()}
                        </h3>
                    </div>
                    <div className="">
                        <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link active"
                                    id="identificacion-tab"
                                    data-toggle="tab"
                                    href="#identificacion"
                                    role="tab"
                                >
                                    Ficha de Identificación
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link"
                                    id="historia-medica-tab"
                                    data-toggle="tab"
                                    href="#historia-medica"
                                    role="tab"
                                >
                                    Historia Médica
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link"
                                    id="historia-dental-tab"
                                    data-toggle="tab"
                                    href="#historia-dental"
                                    role="tab"
                                >
                                    Historia Dental
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link"
                                    id="examen-clinico-tab"
                                    data-toggle="tab"
                                    href="#examen-clinico"
                                    role="tab"
                                >
                                    Examen Clínico
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link"
                                    id="tratamiento-tab"
                                    data-toggle="tab"
                                    href="#tratamiento"
                                    role="tab"
                                >
                                    Plan de Tratamiento
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link"
                                    id="contact-tab"
                                    data-toggle="tab"
                                    href="#notas-evolucion"
                                    role="tab"
                                >
                                    Notas de Evolución
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div
                                className="tab-pane fade show active"
                                id="identificacion"
                                role="tabpanel"
                                aria-labelledby="home-tab"
                            >
                                <Identification
                                    setValue={setValue}
                                    ref={refs["identification"]}
                                    blockForm={closed}
                                />
                            </div>
                            <div
                                className="tab-pane fade"
                                id="historia-medica"
                                role="tabpanel"
                                aria-labelledby="profile-tab"
                            >
                                <History
                                    setValue={setValue}
                                    ref={refs["history"]}
                                    blockForm={closed}
                                />
                            </div>
                            <div
                                className="tab-pane fade"
                                id="historia-dental"
                                role="tabpanel"
                                aria-labelledby="profile-tab"
                            >
                                <DentalHistory
                                    setValue={setValue}
                                    ref={refs["dentalHistory"]}
                                    blockForm={closed}
                                />
                            </div>
                            <div
                                className="tab-pane fade"
                                id="examen-clinico"
                                role="tabpanel"
                                aria-labelledby="contact-tab"
                            >
                                <ClinicalExam
                                    setValue={setValue}
                                    ref={refs["clinicalExam"]}
                                    blockForm={closed}
                                />
                            </div>
                            <div
                                className="tab-pane fade"
                                id="tratamiento"
                                role="tabpanel"
                                aria-labelledby="contact-tab"
                            >
                                <Treatment
                                    setValue={setValue}
                                    ref={refs["treatment"]}
                                    blockForm={closed}
                                />
                            </div>
                            <div
                                className="tab-pane fade"
                                id="notas-evolucion"
                                role="tabpanel"
                                aria-labelledby="contact-tab"
                            >
                                <EvolutionNotes
                                    setValue={setValue}
                                    ref={refs["evolution"]}
                                    blockForm={closed}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Layout>
    );
}

export default TreatmentInfo;
