import React, { useContext } from "react";
import { Link } from "react-router-dom";
import image from "../../assets/img/ion-logo.png";
import { UserContext } from "../../context/context";
import NavBarItem from "./NavBarItem";
import '../../Styles/NavBarItem.css';
import { useHistory } from "react-router-dom";

const NavBar = ({ showModal, dentistToday }) => {
  const userContext = useContext(UserContext);
  const user = userContext.loggedUser;
  const role = user ? user.role : "";
  const id = user ? user.id : "";
  const history = useHistory();

  const toRoute = !user ? "/" : role === "admin" ? "/admin" : role === "dentist" ? "/" + role + "/" + id : user.phone
    ? "/" + role + "/" + id
    : "/completeregistration"
      ? "/receptionist"
      : role === "receptionist"


  const logOut = () => {

    localStorage.removeItem('token');
    history.go(0)
  }

  const resetDate = () => {
    userContext.dateAppointment = null;
  }

  return (


    <nav className="navbar navbar-light navbar-expand-lg fixed-top " style={{ background: "white", maxWidth: "100vw" }}>
      <div className="container-fluid d-flex justify-content-between">
        <Link
          className="navbar-brand"
          to={toRoute || '/'}
        >
          <img
            src={image}
            className="dentalion-logo"
            width="auto"
            alt="Dental ION"
            loading="lazy"
            onClick={role === "dentist" ? resetDate : null}
          />
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
          {user ? (
            <ul className="navbar-nav">
              {!user
                ? (
                  <>
                    <NavBarItem label="Registrarse" link="/register" outlined={true} />
                    <NavBarItem label="Iniciar sesión" link="/login" primary={true} />
                  </>
                )
                : role === "patient"
                  ? (
                    <>
                      <NavBarItem label="Servicios" link="/prices"/>
                      <NavBarItem label="Perfil" sublinks={{ "Mi Perfil": `/${role}/myprofile/${id}`, "Cerrar Sesión": { "onClick":()=> logOut() } }} />
                      <NavBarItem label="citas" link={`/${role}/${id}`} />
                      <NavBarItem label="Agendar Cita" link={`/${role}/${id}`} onClick={showModal} primary={true} />
                    </>
                  )
                  : role === 'dentist'
                    ? (
                      <>
                        <NavBarItem label="Perfil" sublinks={{ "Mi Perfil": `/${role}/${id}`, "Cerrar Sesión": { "onClick": ()=> logOut() } }} />
                        <NavBarItem label="Citas" link={`/${role}/${id}`} onClick={role === "dentist" ? resetDate : null} />
                        <NavBarItem label="Calendario" link={`/${role}/${id}/calendar`} />
                        <NavBarItem label="Pacientes" link="/patients" />
                        <NavBarItem label="Cancelar hoy" onClick={showModal} link={`/${role}/${id}`} canceled={true} />
                      </>
                    )
                    : role === 'admin'
                      ? (
                        <>
                          <NavBarItem label="Citas" link="/appointments" />
                          <NavBarItem label="Procedimientos" sublinks={{ "Ver Procedimientos": `/procedures`, "Añadir Procedimiento": "/procedures/new" }} />
                          <NavBarItem label="Clínicas" sublinks={{ "Ver Clínicas": `/clinics`, "Añadir Clínica": "/clinics/new" }} />
                          <NavBarItem label="Dentistas" sublinks={{ "Ver Dentistas": `/admin/dentists`, "Añadir Dentista": "/admin/dentist/new" }} />
                          <NavBarItem label="Recepcionistas" sublinks={{ "Ver Recepcionistas": `/receptionists`, "Añadir Recepcionista": "/receptionists/new" }} />
                          <NavBarItem label="Pacientes" link="/patients" />
                          <NavBarItem label="Cerrar sesión" onClick={() => logOut()} primary={true} />
                          {/* <NavBarItem label="Admin" onClick={userContext.logout} primary={true} /> */}
                          {/* <NavBarItem label="Código QR" link={`/${role}/appointments`} /> */}
                        </>
                      )
                      : role === 'receptionist'
                        ? (
                          <>
                            <NavBarItem label="Código QR" link="/receptionist" />
                            <NavBarItem label="Cerrar sesión" onClick={() => logOut()} primary={true} />
                          </>
                        )
                        : ''
              }
            </ul>
          ) : (

            <ul className="navbar-nav align-middle me-auto mb-2 mb-lg-0">
              <NavBarItem label="Servicios" link="/prices"/>
              <NavBarItem label="Registrarse" link="/register" outlined={true} />
              <NavBarItem label="Iniciar sesión" link="/login" primary={true} />
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
