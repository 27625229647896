import React, { useState } from 'react';
import TutorCheckbox from './components/TutorCheckbox'
import TutorField from './components/TutorField'

// It has the checkbox to show tutor fields,
// and the fields.
const TutorRegister = (props) => {
    const [displayTutorFields, setValue] = useState(false);
    const formik = props.formik ;

    function handleCheckBox( isChecked )  {
        formik.values.hasTutor = isChecked ;
        setValue( isChecked );
    }

    return(
        <div style={{padding: '0.5em'}}>
            <TutorCheckbox onChange={handleCheckBox}/>
            {formik.errors.hasTutor && formik.touched.hasTutor && (
                  <div
                    className=""
                    style={{ color: "#e74a3b", fontSize: "13px" }}
                  >
                    {formik.errors.hasTutor}
                  </div>
            )}
            <div style={{paddingTop: '0.5em', 
                paddingLeft: '4em', 
                paddingRight: '4em',
                marginBottom: '1em',
                display: displayTutorFields ? 'block':'none'
            }}>
                <TutorField 
                    formik={formik}
                    values={formik.values.tutorName}
                    errors={formik.errors.tutorName}
                    touched={formik.touched.tutorName}
                    id='tutorName'
                    text='Nombre de tutor'
                />
                <TutorField 
                    formik={formik}
                    values={formik.values.tutorPhone}
                    errors={formik.errors.tutorPhone}
                    touched={formik.touched.tutorPhone}
                    id='tutorPhone'
                    text='Teléfono de tutor'
                />
                <TutorField 
                    formik={formik}
                    values={formik.values.tutorEmail}
                    errors={formik.errors.tutorEmail}
                    touched={formik.touched.tutorEmail}
                    id='tutorEmail'
                    text='Email de tutor'
                />
            </div>
        </div>
    );
}

export default TutorRegister;
