import React from "react";

const EvolutionCards = (props) => {

    const { procedureName, docName, studentName, note } = props;
    const date = props.date ? props.date : new Date();

    return (
        <div className="card m-2">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8">
                        <h4 className="card-title2">{procedureName}</h4>
                    </div>
                    <div className="col-md-4">
                        <h6 className="text-right">{`${date.getDate()} de${" "}
          ${date.toLocaleString("es-ES", { month: "long" })} del${" "}
          ${date.getFullYear()}`}
                        </h6>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p className="card-text">{note}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <p className="card-text">{docName}</p>
                    </div>
                    <div className="col-sm-6">
                        <p className="card-text">{studentName}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EvolutionCards;
