import React from 'react';
import {useHistory} from 'react-router-dom';

import APPOINTMENT_SERVICE from '../../services/appointment'

const CancelAppointmentModal = (props) =>{
  const history = useHistory();

  const cancelAppointment = async () => {
    APPOINTMENT_SERVICE.DELETE(props.dataid).then();
    
    history.push(`/patient/${props.patientId}`)
    return [history]
  }

  return (
    <div className={`modal ${props.modalClass}`} style={props.modalStyle}>
      <div className="modal-dialog modal-dialog-centered modal-lg appointment-cancel-modal">
        <div className="modal-content" >
          <div className="modal-body" >
            <div>
              <p>¿Está seguro de querer cancelar esta cita?</p>
            </div>
          </div>
          <div className="modal-footer">
            <div>
              <button type="button" 
                  className="btn btn-danger" 
                  onClick={cancelAppointment}>
                      {'Si, cancelar'}
              </button>
              <button type={'button'} 
                  className="btn btn-primary"
                  onClick={props.close}>
                  No, volver
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelAppointmentModal;
