import React, {useState} from "react";
import { getDescription } from "../../utils/categoriesDescriptions"


const AppointmentFormStep1 = (props) => {
  const style = props.minors ? { display: "" } : { display: "none" };

  /** "selected" will hold values for the service dropdown**/
  // const [selected, setSelected] = useState("");
  const [, setSelected] = useState("");

  const [description, setDescription] = useState("");
  const clinics = props.clinics || [];
  const [categories, setCategories] = useState([]);
 

  /** Function that will set different values to state variable
   * based on which dropdown is selected
  */
  const changeSelectOptionHandler = (event) => {
    // Handle selected clinic and show its categories as options

    let clinicId = event.target.value;
    const clinic = clinics.find( cli => cli._id === clinicId)
    if (clinic) setCategories(clinic.categories)

    props.minors ? props.formik1a.setFieldValue('clinic', clinicId) : props.formik.setFieldValue('clinic', clinicId);

    setSelected(event.target.value);
    sessionStorage.setItem('valor', clinicId)
    
  };

  

  

  return (
    <div className={"col"} style={props.style}>
      <div className="form-group">
        <label htmlFor="clinic">* Clínica: </label>
        <select
          id="clinic"
          name="clinic"
          className={`form-control ${props.minors
            ? props.formik1a.errors.clinic && props.formik1a.touched.clinic
              ? "is-invalid"
              : ""
            : props.formik.errors.clinic && props.formik.touched.clinic
              ? "is-invalid"
              : ""
            }`}
          onBlur={props.minors
            ? props.formik1a.handleBlur
            : props.formik.handleBlur}
          onChange={changeSelectOptionHandler}
          value={props.minors
            ? props.formik1a.values.clinic
            : props.formik.values.clinic}>
          <option defaultValue>Elige...</option>
          { clinics.map((clinic, index) =>
            <option value={clinic._id} key={index}>{clinic.name}</option>
          )}

        </select>
        {props.minors
          ? props.formik1a.errors.clinic && props.formik1a.touched.clinic
            ? <div className="invalid-feedback">{props.formik1a.errors.clinic}</div>
            : null
          : props.formik.errors.clinic && props.formik.touched.clinic
            ? <div className="invalid-feedback">{props.formik.errors.clinic}</div>
            : null
        }
      </div>

      <div>
      <label htmlFor="service">* Servicio: </label>
      <div className={"my-2 text-secondary"}>{description}</div>
        <select
          id="service"
          name="service"
          className={`form-control ${props.minors
            ? props.formik1a.errors.service && props.formik1a.touched.service
              ? "is-invalid"
              : ""
            : props.formik.errors.service && props.formik.touched.service
              ? "is-invalid"
              : ""
            }`}
          onBlur={props.minors
            ? props.formik1a.handleBlur
            : props.formik.handleBlur}
          onChange={props.minors
            ? props.formik1a.handleChange
            : e=>{
              //Change the description on making appointment modal
              props.formik.handleChange(e);
              setDescription(getDescription(e.target.value));
            }}
          value={props.minors
            ? props.formik1a.values.service
            : props.formik.values.service}>
          <option defaultValue>Elige...</option>
          { categories.map((category, index) =>
            <option value={category} key={index}>{category}</option>
          )}
        </select>
        {props.minors
          ? props.formik1a.errors.service && props.formik1a.touched.service
            ? <div className="invalid-feedback">{props.formik1a.errors.service}</div>
            : null
          : props.formik.errors.service && props.formik.touched.service
            ? <div className="invalid-feedback">{props.formik.errors.service}</div>
            : null
        }
      </div>

      {props.minors
        ? (props.procedures[props.formik1a.values.service]?.name === 'Ortodoncia')
          ? <div>
            <p>Información de Ortodoncia:</p>
            <p>• Admisión $600</p>
            <p>• Anticipó $2,500</p>
            <p>• 30 mensualidades $370</p>
          </div>
          : null
        : (props.procedures[props.formik.values.service]?.name === 'Ortodoncia')
          ? <div>
            <p>Información de Ortodoncia:</p>
            <p>• Admisión $600</p>
            <p>• Anticipó $2,500</p>
            <p>• 30 mensualidades $370</p>
          </div>
          : null
      }
      {props.showMinorsToggle
        ? (<div className="custom-control custom-checkbox" >
          <input type="checkbox"
            className="custom-control-input"
            id="customCheck1"
            name="customCheck1"
            onChange={props.toggleMinors}
            checked={props.minors} />
          <label className="custom-control-label" htmlFor="customCheck1">¿Esta cita es para un menor de edad?</label>
        </div>)
        : null}
      {props.showMinorsToggle
        ?
        <div style={style}>
          <div className="form-group">
            <label htmlFor="name">*Nombre del paciente: </label>
            <div className="form-row" style={style}>
              <div className="col">
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  className={`form-control ${props.formik1a.errors.firstName &&
                    props.formik1a.touched.firstName
                    ? "is-invalid"
                    : ""
                    }`}
                  placeholder="Nombre"
                  onBlur={props.formik1a.handleBlur}
                  onChange={props.formik1a.handleChange}
                  value={props.formik1a.values.firstName}
                />

                {props.formik1a.errors.firstName &&
                  props.formik1a.touched.firstName ? (
                  <div className="invalid-feedback">
                    {props.formik1a.errors.firstName}
                  </div>
                ) : null}
              </div>
              <div className="col">
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  className={`form-control ${props.formik1a.errors.lastName && props.formik1a.touched.lastName
                    ? "is-invalid"
                    : ""
                    }`}
                  placeholder="Apellidos"
                  onBlur={props.formik1a.handleBlur}
                  onChange={props.formik1a.handleChange}
                  value={props.formik1a.values.lastName}
                />

                {props.formik1a.errors.lastName &&
                  props.formik1a.touched.lastName ? (
                  <div className="invalid-feedback">
                    {props.formik1a.errors.lastName}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="age">*Edad: </label>
            <input
              id="age"
              name="age"
              type="text"
              className={`form-control ${props.formik1a.errors.age && props.formik1a.touched.age
                ? "is-invalid"
                : ""
                }`}
              placeholder="Edad"
              onBlur={props.formik1a.handleBlur}
              onChange={props.formik1a.handleChange}
              value={props.formik1a.values.age}
            />

            {props.formik1a.errors.age && props.formik1a.touched.age ? (
              <div className="invalid-feedback">{props.formik1a.errors.age}</div>
            ) : null}
          </div>
        </div>
        : null}
    </div>
  );
};

export default AppointmentFormStep1;
