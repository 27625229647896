/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import { parse } from 'query-string';
import SearchBar from '../SearchBar/SearchBar';
import PatientList from './PatientsList';
import USER_SERVICE from '../../services/user';

const ExpedientesContainer = () => {
  const [patients, setPatients] = useState([]);

  const searchQuery = parse(location.search).search;

  useEffect(() => {
    const getUsersByRole = async () => {
      try {
        const { data } = await USER_SERVICE.GET_BY_ROLE('patient', searchQuery);
        setPatients(data);
      } catch (error) {
        console.log(error);
      }
    }

    getUsersByRole();

  }, [searchQuery]);

  return (
    <div>
      <SearchBar />
      <PatientList patients={patients} />
    </div>
  );
};

export default ExpedientesContainer;
