import React, { useEffect, useState } from 'react';

const Checkbox = (props) => {

    const { initialValues, label, prefix, setValue } = props;
    const id = `${prefix}checkBox${label}`;
    const [initialValue, setInitialValue] = useState(false);

    useEffect(() => {
        if (initialValues && initialValues.hasOwnProperty(id))
            setInitialValue(true);

        else
            setInitialValue(false);
    }, [initialValues, id]);

    return (
        initialValue ?
            <div className="form-check flex flex-align-items-base">
                <input
                    className=""
                    type="checkbox"
                    defaultChecked={initialValue}
                    id={id}
                    onChange={e => {
                    }}
                    onClick={e => {
                        setValue(e.target.checked, id);
                    }}
                />
                <label className="">
                    {label}
                </label>
            </div>
            :
            <div className="form-check flex flex-align-items-base">
                <input
                    className=""
                    type="checkbox"
                    id={id}
                    onClick={e => {
                        setValue(e.target.checked, id);
                    }}
                />
                <label className="">
                    {label}
                </label>
            </div>
    );
}

export default Checkbox;
