import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";

const PatientTreatmentCard = ({ id, dentist, date, procedure, recordId, clinic, childs }) => {

  const hasChilds = childs.length > 0;
  const params = useParams();
  const userId = params.id;

  return (
    <>
      <div className="col-12 px-0 py-2">
        <div className="card">
          <div className="card-header d-flex justify-content-between" style={{ backgroundColor: "#03579a" }}>
            <h4 className="card-title" >{procedure}</h4>
            <Link
                to={`/patients/profile/${id}/medicalrecord/${recordId}`}
                className="btn btn-primary"
                style={{ borderRadius: "15px" }}
              >
              Ver Historial
            </Link>
          </div>
          <div className="card-body d-flex justify-content-between">
            <div className="">
              <p className="mb-0 pb-0"><strong>Dentista:</strong> {dentist}</p>
              <p className="mb-0"><strong>Fecha:</strong> {date}</p>
              <p className="mb-0"><strong>Clínica:</strong> {clinic}</p>
              <strong>Sub expedientes: </strong>
              <ul>
                {
                  hasChilds && childs.map((record, idx) => {
                    return (
                      <li key={idx}>
                        <Link key={idx} to={`/patients/profile/${userId}/medicalrecord/${record}`}> Expediente {idx} </Link>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientTreatmentCard;
