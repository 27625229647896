import React from 'react';
import '../../Styles/NavBarItem.css';
import { Link } from "react-router-dom";

const NavBarItem = ({ label, link, sublinks, primary, outlined, canceled, onClick }) => {

  return (
    <>
      {sublinks && !primary && !outlined && !canceled
        ? (
          <>
            <li className="nav-item dropdown navbar-item" onClick={onClick}>
              <span className="nav-link dropdown-toggle navbar-item" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {label}
              </span>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                {Object.keys(sublinks).map((label) => (
                  <li key={label} className="nav-item navbar-item" onClick={onClick}>
                    <Link className="dropdown-item navbar-item" to={sublinks[label] || '/#'} onClick={sublinks[label].onClick}>
                      {label}
                    </Link>
                  </li>
                ))
                }
              </ul>
            </li>
          </>
        )
        : primary && !outlined && !canceled
          ? (
            <li className="nav-item" onClick={onClick}>
              <Link
                to={link || '/#'}
                className="btn btn-primary primary navbar-item nav-link"
              >
                {label}
              </Link>
            </li>
          )
          : outlined && !primary && !canceled
            ? (
              <li className="nav-item" onClick={onClick}>
                <Link
                  to={link || '/#'}
                  className="btn btn-outline-primary outlined navbar-item nav-link"
                >
                  {label}
                </Link>
              </li>
            )
            : canceled && !outlined && !primary
              ? (
                <li className="nav-item" onClick={onClick}>
                  <Link
                    to={link || '/#'}
                    className="navbar-item canceled btn btn-danger nav-link hello"
                  >
                    {label}
                  </Link>
                </li>
              )
              : !canceled && !outlined && !primary && !sublinks
                ? (
                  <li className="nav-item" onClick={onClick}>
                    <Link
                      to={link || '/#'}
                      className="align-middle navbar-item nav-link"
                    >
                      {label}
                    </Link>
                  </li>
                )
                : ''
      }
    </>
  );
};

export default NavBarItem;
