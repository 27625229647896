import React, { useContext } from "react";
import Modal from "react-modal";

import APPOINTMENT_SERVICE from "../../services/appointment";
import { UserContext } from "../../context/context";

const CancelDayModal = (props) => {
  const userContext = useContext(UserContext);

  const handleCancelDay = async () => {
    await APPOINTMENT_SERVICE.CANCEL_DAY(props.idDentist, props.today);
    props.onRequestClose();
    userContext.changeSate();
  };

  Modal.setAppElement("body");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "0",
      boxShadow: "0 1rem 3rem rgba(0,0,0,.175)",
      padding: "4%",
    },
  };

  function afterOpenModal() { }
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      onAfterOpen={afterOpenModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="row">
        <p>¿Estas seguro que quieres cancelar tus citas del dia?</p>
      </div>
      <div className="text-right row">
        <button
          type="button"
          className="col btn btn-secondary btn-block mt-4"
          onClick={props.onRequestClose}
        >
          Cerrar
        </button>
        <button
          type="button"
          className="col btn btn-danger  btn-block mt-4"
          onClick={handleCancelDay}
        >
          Cancelar Día
        </button>
      </div>
    </Modal>
  );
};
export default CancelDayModal;
