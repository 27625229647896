import React, { useEffect, useState, useContext } from 'react';
import PROCEDURE_SERVICE from '../../services/procedure';
import { UserContext } from "../../context/context"


const PriceTable = (props) => {
    const { category } = props;
    const [procedures, setProcedures] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const userContext = useContext(UserContext);

    useEffect(() => {
        setIsLoading(true);

        const proceduresGetByCategory = async () => {
          const { data } = await PROCEDURE_SERVICE.GET_BY_CATEGORY(category);
          setProcedures(data);
          setIsLoading(false);
        }

        proceduresGetByCategory();
    }, [userContext, category]);

    return (
      <>
      <div className="container">
      <h2 className="page-title">{category}</h2>

        <table className="table">
            <thead>
            <tr>
                <th>Nombre</th>
                <th>Precio</th>
            </tr>
            </thead>
            <tbody>
            {procedures.map((procedure, idx) => {
              return !isLoading ? (
                <tr key={idx}>
                          <td style={{"width": "80%"}}>{procedure.name}</td>
                          <td>${procedure.cost}.00 MX</td>
                        </tr>
                      )
                      : null; }
                      )}
            </tbody>
          </table>
        </div>
        </>
    )
};

export default PriceTable;
