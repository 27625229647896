import React from 'react';
import Footer from '../Footer/Footer';
import NavBar from '../NavBar/NavBar';
import '../../Styles/Layout.css';

const Layout = ({children, showModal, dentistToday}) => {
  return (
    <div className="container-fluid p-0">
      <NavBar showModal={showModal} dentistToday={dentistToday}/>
        <main>
          {children}
        </main>
      <Footer />
    </div>
  );
};

export default Layout;
