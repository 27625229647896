import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

// import NavBar from "../../components/NavBar/NavBar";
import CancelDayModal from "../../components/DentistModals/CancelDayModal";
import AppointmentCard from "../../components/Appointment/AppointmentCard";
import APPOINTMENT_SERVICE from "../../services/appointment";
import { UserContext } from "../../context/context";
import Layout from "../../components/Layout/Layout";

const Dentist = () => {
  const userContext = useContext(UserContext);
  const [appointments, setAppointments] = useState([]);

  const role = userContext.loggedUser.role;
  const userID = userContext.loggedUser.id;
  const history = useHistory();
  let { id } = useParams(); //Here we should call the context or use params from the url like the line above

  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const todayDate = year + "-" + month + "-" + day;

  const setToday = () => {
    userContext.calendarDate(null);
  };

  const createDate = () => {
    const date = userContext.dateAppointment;
    let day = date.getDate();
    day = day < 10 ? "0" + day : day;
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return year + "-" + month + "-" + day;
  };

  // Preguntar como se puede mejorar esta parte
  useEffect(() => {

    const createDate = () => {
      const date = userContext.dateAppointment;
      let day = date.getDate();
      day = day < 10 ? "0" + day : day;
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return year + "-" + month + "-" + day;
    };

    const todayAppointments = async () => {
      const date = !userContext.dateAppointment ? todayDate : createDate();
      const response = await APPOINTMENT_SERVICE.GET_ALL_DENTIST_DATE(
        id,
        date
      );
      setAppointments(response.data);
    };
    if (id === userID) {
      todayAppointments();
    } else {
      history.push(`/403`);
      return [history];
    }
    return () => { };
  }, [
    history,
    id,
    userContext.dateAppointment,
    todayDate,
    userID,
  ]);

  const [cancelDayOpen, setCancelDayOpen] = useState(false);
  const showModal = () => {
    setCancelDayOpen(true);
  };
  const closeModal = () => {
    setCancelDayOpen(false);
  };

  const calendarDateTitle = () => {
    const date = new Date(userContext.dateAppointment);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return "fecha: " + day + "-" + month + "-" + year;
  };
  return (
    <Layout showModal={showModal} setToday={setToday}>
      <CancelDayModal
        isOpen={cancelDayOpen}
        onRequestClose={closeModal}
        idDentist={id}
        today={!userContext.dateAppointment ? todayDate : createDate()}
      />
      <div className="row mx-0 px-0">
        <div className="text-center mb-3 col-12">
          <h2 className="font-weight-bold">{userContext.dateAppointment ? calendarDateTitle() : "Tus citas de hoy"}</h2>
        </div>
        {appointments?.map((appointment) => {
          const isPatient = appointment.patient ? true : false;
          if (appointment.status === 0) {
            if (appointment.category) {
              return (
                <AppointmentCard
                  procedure={appointment.category}
                  cost=''
                  doc={
                    isPatient
                      ? appointment.patient.name +
                      " " +
                      appointment.patient.surname
                      : ""
                  }
                  date={appointment.date}
                  time={appointment.starts}
                  key={appointment.id}
                  keydata={appointment.id}
                  role={role}
                  status={appointment.status}
                  patient={isPatient ? appointment.patient.name : ""}
                />
              );
            }
            return null;
          } else { return null; }
        }
        )}
        <div className="text-center mb-3 col-12">
          <h2 className="font-weight-bold">{userContext.dateAppointment ? "" : "Completadas"}</h2>
        </div>
        {appointments?.map((appointment) => {
          const isPatient = appointment.patient ? true : false;
          if (appointment.status !== 0) {
            if (appointment.category) {
              return (
                <AppointmentCard
                  procedure={appointment.category}
                  cost=''
                  doc={
                    isPatient
                      ? appointment.patient.name +
                      " " +
                      appointment.patient.surname
                      : ""
                  }
                  date={appointment.date}
                  time={appointment.starts}
                  key={appointment.id}
                  keydata={appointment.id}
                  role={role}
                  status={appointment.status}
                  patient={isPatient ? appointment.patient.name : ""}
                />
              );
            }
            return null;
          } else { return null; }
        }
        )}
      </div>
    </Layout>
  );
};

export default Dentist;
