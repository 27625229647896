import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
    // useState,
} from "react";
import FastTextInput from "./MedicalRecordsComponents/FastTextInput";
import FastTextArea from "./MedicalRecordsComponents/FastTextArea";
import FastDatePicker from "./MedicalRecordsComponents/FastDatePicker";
import FastRadialButtons from "./MedicalRecordsComponents/FastRadialButtons";
import IdentificationValidation from "./Validation/IdentificationValidation";
//import { reach } from "yup";

const identificationValidation = IdentificationValidation();

function Identification(props, ref) {
    const { setValue} = props;
    const isBlocked = true;
    const propiedades = props;

    useImperativeHandle(ref, () => ({
        async onSubmit() {
            const errors = [];
            for (var ref in refs) {
                try {
                    const eachErrors = await refs[ref].current.onSubmit();
                    for (var err in eachErrors)
                        errors.push(eachErrors[err]);
                } catch (error) {
                    console.log(ref);
                }
            }
            return errors;
        },
        setInitialValues(values) {
            for (var ref in refs) {
                try {
                    refs[ref].current.setInitialValue(values);
                    
                } catch (error) {
                    console.log(ref);
                }
            }
        },
    }));

    const refs = {
        "motivo.consulta": useRef(null),
        "generalInfo.paternalLastName": useRef(null),
        "generalInfo.maternalLastName": useRef(null),
        "generalInfo.name": useRef(null),
        "generalInfo.birthday": useRef(null),
        "generalInfo.age": useRef(null),
        "generalInfo.gender": useRef(null),
        "generalInfo.birthplace.city": useRef(null),
        "generalInfo.birthplace.state": useRef(null),
        "generalInfo.mothersName": useRef(null),
        "generalInfo.fathersName": useRef(null),
        "generalInfo.address.street": useRef(null),
        "generalInfo.address.number": useRef(null),
        "generalInfo.address.neighborhood": useRef(null),
        "generalInfo.address.postalCode": useRef(null),
        "generalInfo.phoneNumber.main": useRef(null),
        "generalInfo.phoneNumber.cellphone": useRef(null),
        "generalInfo.phoneNumber.other": useRef(null),
        medicalAlert: useRef(null),
    };

    return (
        <div className="container">
            <div className={`form-group ${isBlocked && propiedades.block  ? "disable" : ""}`}>
                <div>
                    <label style={{ fontWeight: "bold" }}>MOTIVO DE CONSULTA:</label>
                    <FastTextArea
                        validation={identificationValidation.motivoConsulta}
                        setValue={setValue}
                        id="reasonDentisVisit"
                        rows={3}
                        ref={refs["motivo.consulta"]}
                    />
                </div>
                <label style={{ fontWeight: "bold" }}>NOMBRE DEL PACIENTE</label>
                <div className="form-group flex flex-align-items-start flex-wrap">
                    <div className="form-group flex flex-column marsides02 flex-grow">
                        <label htmlFor="paternalLastName">Apellido Paterno:</label>
                        <FastTextInput
                            validation={identificationValidation.paternalLastName}
                            setValue={setValue}
                            id="generalInfo.paternalLastName"
                            ref={refs["generalInfo.paternalLastName"]}
                        />
                    </div>
                    <div className="form-group flex flex-column marsides02 flex-grow">
                        <label htmlFor="maternalLastName">Apellido Materno:</label>
                        <FastTextInput
                            validation={identificationValidation.maternalLastName}
                            setValue={setValue}
                            id="generalInfo.maternalLastName"
                            ref={refs["generalInfo.maternalLastName"]}
                        />
                    </div>
                    <div className="form-group flex flex-column marsides02 flex-grow">
                        <label htmlFor="name">Nombre(s):</label>
                        <FastTextInput
                            validation={identificationValidation.name}
                            setValue={setValue}
                            
                            id="generalInfo.name"
                            ref={refs["generalInfo.name"]}
                        />
                    </div>
                </div>

                <div className="form-row flex flex-align-items-start">
                    <div className="form-group flex flex-column marsides02 flex-grow-1">
                        <label style={{ fontWeight: "bold" }}>FECHA DE NACIMIENTO</label>
                        <FastDatePicker
                            validation={identificationValidation.birthday}
                            yearDropdownItemNumber={15}
                            showYearDropdown
                            scrollableYearDropdown
                            id="generalInfo.birthday"
                            setValue={setValue}
                            ref={refs["generalInfo.birthday"]}
                        />
                    </div>
                    <div className="form-group flex flex-column marsides02 flex-grow-1">
                        <label style={{ fontWeight: "bold" }} htmlFor="age">
                            EDAD
                        </label>
                        <FastTextInput
                            validation={identificationValidation.age}
                            setValue={setValue}
                            id="generalInfo.age"
                            ref={refs["generalInfo.age"]}
                        />
                    </div>

                    <div className="form-group flex flex-column flex-wrap flex-grow">
                        <label style={{ fontWeight: "bold" }} className="marsides1">
                            GÉNERO
                        </label>
                        <FastRadialButtons validation={identificationValidation.gender}
                            setValue={setValue}
                            id="generalInfo.gender"
                            ref={refs["generalInfo.gender"]}
                            options={["Hombre", "Mujer"]}
                            name={"gender"}
                        />
                    </div>
                </div>

                <div className="form-group">
                    <label style={{ fontWeight: "bold" }}>LUGAR DE NACIMIENTO</label>
                    <div className="form-group flex flex-align-items-start flex-wrap">
                        <div className="form-group flex flex-column marsides02 flex-grow">
                            <label htmlFor="city">Ciudad:</label>
                            <FastTextInput
                                validation={identificationValidation.city}
                                setValue={setValue}
                                id="generalInfo.birthplace.city"
                                ref={refs["generalInfo.birthplace.city"]}
                            />
                        </div>
                        <div className="form-group flex flex-column marsides02 flex-grow">
                            <label htmlFor="state">Estado:</label>
                            <FastTextInput
                                validation={identificationValidation.state}
                                setValue={setValue}
                                id="generalInfo.birthplace.state"
                                ref={refs["generalInfo.birthplace.state"]}
                            />
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label style={{ fontWeight: "bold" }} htmlFor="mothersName">
                        NOMBRE DE MADRE O RESPONSABLE
                    </label>
                    <FastTextInput
                        validation={identificationValidation.mothersName}
                        setValue={setValue}
                        id="generalInfo.mothersName"
                        ref={refs["generalInfo.mothersName"]}
                    />
                </div>

                <div className="form-group">
                    <label style={{ fontWeight: "bold" }} htmlFor="fathersname">
                        NOMBRE DE PADRE
                    </label>
                    <FastTextInput
                        validation={identificationValidation.fathersname}
                        setValue={setValue}
                        id="generalInfo.fathersName"
                        ref={refs["generalInfo.fathersName"]}
                    />
                </div>

                <div className="form-group">
                    <label style={{ fontWeight: "bold" }}>DOMICILIO</label>
                    <div className="form-group flex flex-align-items-start flex-wrap">
                        <div className="form-group flex flex-column marsides02 flex-grow">
                            <label htmlFor="street">Calle:</label>
                            <FastTextInput
                                validation={identificationValidation.street}
                                setValue={setValue}
                                id="generalInfo.address.street"
                                ref={refs["generalInfo.address.street"]}
                            />
                        </div>
                        <div className="form-group flex flex-column marsides02 flex-grow">
                            <label htmlFor="number">Número:</label>
                            <FastTextInput
                                validation={identificationValidation.number}
                                setValue={setValue}
                                id="generalInfo.address.number"
                                ref={refs["generalInfo.address.number"]}
                            />
                        </div>
                        <div className="form-group flex flex-column marsides02 flex-grow">
                            <label htmlFor="neighborhood">Colonia:</label>
                            <FastTextInput
                                validation={identificationValidation.neighborhood}
                                setValue={setValue}
                                id="generalInfo.address.neighborhood"
                                ref={refs["generalInfo.address.neighborhood"]}
                            />
                        </div>
                        <div className="form-group flex flex-column marsides02 flex-grow">
                            <label htmlFor="postalCode">C.P.:</label>
                            <FastTextInput
                                validation={identificationValidation.postalCode}
                                setValue={setValue}
                                id="generalInfo.address.postalCode"
                                ref={refs["generalInfo.address.postalCode"]}
                            />
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="form-group flex flex-align-items-start flex-wrap">
                        <div className="form-group flex flex-column marsides02 flex-grow">
                            <label style={{ fontWeight: "bold" }} htmlFor="phoneNumber">
                                TELÉFONO:
                            </label>
                            <FastTextInput
                                validation={identificationValidation.phoneNumber}
                                setValue={setValue}
                                id="generalInfo.phoneNumber.main"
                                ref={refs["generalInfo.phoneNumber.main"]}
                            />
                        </div>
                        <div className="form-group flex flex-column marsides02 flex-grow">
                            <label style={{ fontWeight: "bold" }} htmlFor="cellphone">
                                CELULAR:
                            </label>
                            <FastTextInput
                                validation={identificationValidation.cellphone}
                                setValue={setValue}
                                id="generalInfo.phoneNumber.cellphone"
                                ref={refs["generalInfo.phoneNumber.cellphone"]}
                            />
                        </div>
                        <div className="form-group flex flex-column marsides02 flex-grow">
                            <label style={{ fontWeight: "bold" }} htmlFor="colony">
                                OTRO:
                            </label>
                            <FastTextInput
                                validation={identificationValidation.other}
                                setValue={setValue}
                                id="generalInfo.phoneNumber.other"
                                ref={refs["generalInfo.phoneNumber.other"]}
                            />
                        </div>
                    </div>
                </div>
                <div
                    className="alert alert-danger"
                    role="alert"
                    style={{ fontWeight: "bold" }}
                >
                    ALERTA MÉDICA (Alergias, padecimientos actuales, tratamientos,
                    medicamentos) :
                    <FastTextArea
                        validation={identificationValidation.medicalAlert}
                        setValue={setValue}
                        id="medicalAlert"
                        rows={3}
                        ref={refs["medicalAlert"]}
                    />
                </div>
                <hr />
            </div>
        </div>
    );
}

export default forwardRef(Identification);
