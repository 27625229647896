import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import { useFormik } from "formik";
import * as Yup from "yup";

import APPOINTMENT_SERVICE from "../../services/appointment";
import CLINIC_SERVICE from "../../services/clinic";
import PROCEDURE_SERVICE from "../../services/procedure";
import NoAppointment from "./NoAppointment";

const NoAppointmentForm = () => {
  const [clinics, setClinics] = useState([]);
  const [categories, setCategories] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [pageState, setPage] = useState(0);
  const [appointmentData, setAppointment] = useState({});

  const [selectedClinic, setSelectedClinic] = useState("");




  let showPage1 = pageState === 0 ? { display: "block" } : { display: "none" };
  let showPage2 = pageState === 1 ? { display: "block" } : { display: "none" };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "0",
      boxShadow: "0 1rem 3rem rgba(0,0,0,.175)",
      padding: "3%",
      borderRadius: "30px",
    },
  };
  const closeModal = () => {
    setOpenModal(false);
  };


  useEffect(() => {
    Modal.setAppElement("body");

    const getClinics = async () => {
      const { data: clinicsData } = await CLINIC_SERVICE.GET_ALL();
      setClinics(clinicsData);
    };
    const getCategories = async () => {
      const { data: categoriesData } = await PROCEDURE_SERVICE.GET_CATEGORIES();
      setCategories(categoriesData);
    };
    getClinics();
    getCategories();
    setLoading(false);
  }, []);


  useEffect(() => {
    const setNewCategories = async () => {
      const { data } = await CLINIC_SERVICE.GET_ONE(selectedClinic);
      setCategories(data.categories);
    }
    if (selectedClinic !== "") {
      setNewCategories();
    }
  }, [selectedClinic]);


  useEffect(() => {
    if (pageState === 1 || pageState === 2) {
      setTimeout(() => {
        history.push("/receptionist/appointments");
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState]);


  const AppointmentService = async (date, clinic, category) => {
    const { data } = await APPOINTMENT_SERVICE.GET_EXPRESS(
      date,
      clinic,
      category
    );
    setAppointment(data);
  };

  const formik = useFormik({
    initialValues: {
      clinic: "",
      category: "",
    },
    validationSchema: Yup.object({
      clinic: Yup.string().required("Clínica requerida"),
      category: Yup.string().required("Categoría requerida"),
    }),
    onSubmit: (values) => {
      const date = new Date();
      try {
        AppointmentService(date, values.clinic, values.category);
        setPage(1);
      } catch (error) {
        console.error(error);
      }
    },
  });


  const handleClinicChange = (event) => {
    setSelectedClinic(event.target.value);
    formik.setFieldValue("clinic", event.target.value);
  }

  if (!loading) {
    return (
      <div>
        <Modal
          isOpen={openModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="QR Modal"
        >
          <form action="" style={showPage1}>
            <h3>Seleccione la clínica y el procedimiento</h3>
            <select
              name="clinic"
              id="clinic"
              className={`form-control form-control-lg ${
                formik.errors.clinic && formik.touched.clinic
                  ? "is-invalid"
                  : ""
              }`}
              value={
                formik.values.clinic ? formik.values.clinic._id : "default"
              }
              onChange={handleClinicChange}
              onBlur={formik.handleBlur}
            >
              <option value="default" key="default" disabled>
                Selecciona una clínica
              </option>

              {clinics.map((clinic) => {
                return (
                  <option key={clinic._id} value={clinic._id}>
                    {clinic.name}
                  </option>
                );
              })}
            </select>
            {formik.errors.clinic && formik.touched.clinic && (
              <div className="invalid-feedback">{formik.errors.clinic}</div>
            )}
            <br />

            <select
              name="category"
              id="category"
              className={`form-control form-control-lg ${
                formik.errors.category && formik.touched.category
                  ? "is-invalid"
                  : ""
              }`}
              value={
                formik.values.category ? formik.values.category : "default"
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value="default" key="default" disabled>
                Selecciona una categoría
              </option>
              {categories.map((category) => {
                return (
                  <option key={category} value={category}>
                    {category}
                  </option>
                );
              })}
            </select>
            {formik.errors.category && formik.touched.category && (
              <div className="invalid-feedback">{formik.errors.category}</div>
            )}

            <br />

            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary w-100 m-0"
                onClick={formik.handleSubmit}
              >
                Guardar
              </button>
            </div>
          </form>
          <div style={showPage2}>
            <NoAppointment data={appointmentData} />
          </div>
        </Modal>
      </div>
    );
  } else {
    return <div>Loading</div>;
  }
};

export default NoAppointmentForm;
