import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProcedureForm from '../components/Procedures/ProcedureForm/ProcedureForm';
import PROCEDURE_SEVICE from '../services/procedure';

const EditProcedure = () => {
  const { id } = useParams();
  const [procedure, setProcedure] = useState();

  const procedureServiceGetOne = async (id) => {
    const { data } = await PROCEDURE_SEVICE.GET_ONE(id);
    setProcedure(data);
  }
  useEffect(() => {
    if (id && id !== "new") {
      procedureServiceGetOne(id);
    }
  }, [id])

  return (
    <>
      {procedure

        ? <ProcedureForm
          id={id}
          name={procedure.name}
          description={procedure.description}
          cost={procedure.cost}
          location={procedure.location}
          duration={procedure.duration}
        />
        : <ProcedureForm />
      }

    </>
  );
};

export default EditProcedure;
