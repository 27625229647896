import React, { forwardRef, useImperativeHandle, useRef, useState } from "react"
import { FastField, Formik } from "formik";
import * as Yup from "yup";

const SelectionBox = (props, ref) => {
    let items = [];
    for (let i = 0; i <= props.items.length; i++) {
        items.push(<option key={i} value={props.items[i]}>{props.items[i]}</option>);
    }
    const { validation, setValue, id } = props;
    const [initialValue, setInitialValue] = useState(null);

    useImperativeHandle(ref, () => ({
        async onSubmit() {
            fastField.current.handleSubmit();
            let errors = await fastField.current.validateForm();
            return (errors);
        },
        setInitialValue(values) {
            setInitialValue(values[id]);
        }
    }));

    const fastField = useRef(null);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                selection: initialValue ?? '',
            }}
            validationSchema={Yup.object().shape({
                selection: validation ? validation : Yup.string(),
            })}
            innerRef={fastField}
            onSubmit={() => {
            }}
        >
            <FastField
                name='selection'
                component={selection}
                setValue={setValue}
                id={id}
                items={items}
            />
        </Formik>
    );
}

const selection = ({ field: { onChange, name, value, onBlur },
    form: { touched, errors },
    ...props }) => {
        const {setValue, id } = props;
    return (
        <>
            <select
                className={`form-control ${touched[name]
                    && errors[name] ? "is-invalid" : ""}`}
                value={value}
                name={name}
                onChange={e => {
                    onChange(e);
                }}
                onBlur={e => {
                    onBlur(e);
                    if (!errors[name]) {
                        setValue(e.target.value, id);
                    }
                }}
            >
                {props.items}
            </select>
            {touched[name] && errors[name]
                && <div className="invalid-feedback">{errors[name]}</div>}
        </>
    );
}

export default forwardRef(SelectionBox);
