import React from 'react';
import { Link } from 'react-router-dom';
//import Patients from '../../pages/Patients';

const PatientCard = ({ id, name, phone, age, birthday,link, url }) => {
  return (
    <>
      <div className="col-12 px-0 pb-4 martop1">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">{name}</h4>
          </div>
          <div className="card-body d-flex justify-content-between">
            <div className="">
              <p><strong>Número telefónico:</strong> {phone}</p>
              <p><strong>Fecha de nacimiento:</strong> {birthday}</p>
              <p className="mb-0"><strong>Edad:</strong> {age}</p>
            </div>
            {link
              ?
              <div className="d-flex justify-content-end align-items-end">
                <Link
                  to={id ? `${url}/${id}` : `/patients/profile/new`}
                  className="btn btn-primary ml-0"
                  style={{ maxHeight: '40px', borderRadius: '15px' }}
                >
                  Ver ficha de paciente
                </Link>
              </div>
              : ""
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientCard;
