import axios from '../config/axios';

const USER_SERVICE = {
  UPDATE_PASSWORD: async (data) => {
    return await axios.post('/users/credentials', data);
  },

  GET_ONE: async (id) => {
    return await axios.get(`/users/${id}`);
  },

  GET_ALL: async () => {
    return await axios.get('/users');
  },

  GET_BY_ROLE: async (role, search) => {
    return await axios.get('/users', {
      params: {
        role,
        search,
      },
    });
  },

  DELETE: async (id) => {
    return await axios.delete(`/users/${id}`);
  },

  COMPLETE_PROFILE: async ({ id, data }) => {
    return await axios.put(`/complete/${id}`, data);
  },

  UPDATE: async (id, data) => {
    return await axios.put(`/users/${id}`, data);
  },

  CREATE: async (data) => {
    const role = data.role;

    if (role === 'receptionist'){
      return await axios.post(`/users/register/receptionist`, data)
    }
  }
};

export default USER_SERVICE;
