import React from 'react';
import SearchBar from '../components/SearchBar/SearchBar';
import Layout from '../components/Layout/Layout';
// import NewPatient from "../components/NewPatient/NewPatient";
import DentistsList from '../components/Dentist/DentistsList';

const Dentists = () => {
    return (
        <>
            <Layout>
                <div className="flex flex-start container-fluid h-100" style={{ backgroundColor: "#063348" }}>
                    <h2 className="page-title marsides2">Dentistas</h2>
                </div>

                <div className="row pb-4" style={{ backgroundColor: "white" }}>
                    <SearchBar baseUrl="/dentists" />
                    {/* <NewPatient baseUrl="/patients"/> */}
                </div>
                <div className="flex flex-start container-fluid px-5" style={{ backgroundColor: "white" }}>
                    <DentistsList />
                </div>
            </Layout>
        </>
    );
};

export default Dentists;
