/* eslint-disable radix */
import React, {
  useState, useEffect, useContext,
} from 'react';
import { useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';

import CancelAppointmentModal from '../../components/Appointment/CancelAppointmentModal';
import ReScheduleAppointmentModal from '../../components/Appointment/ReScheduleAppointmentModal';
import RecurrentAppointmentModal from '../../components/Appointment/RecurrentAppointmentModal';
import ConfirmAppointmentModal from '../../components/Appointment/ConfirmAppointmentModal';
// import NavBar from '../../components/NavBar/NavBar';
import QRGenerator from '../../components/QR/QRGenerator';
import { UserContext } from '../../context/context';

import PROCEDURE_SERVICE from '../../services/procedure';
import APPOINTMENT_SERVICE from '../../services/appointment';
import Layout from '../../components/Layout/Layout';
import DifferAppointmentModal from "../../components/Appointment/DifferAppointmentModal";

const Appointment = () => {
  const userContext = useContext(UserContext);
  const history = useHistory();

  const { id } = useParams();

  const [appointment, setAppointment] = useState({
    date: '',
    starts: '',
    ends: '',
    patient: '',
    dentist: '',
    clinic: '',
    procedure: '',
    confirmed: '',
    status: '',
  });

  const [procedures, setProcedures] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch of procedures
  useEffect(() => {
    let isMounted = true;

    const getProcedures = async () => {
      const { data } = await PROCEDURE_SERVICE.GET_ALL();
      if (isMounted) {
        setProcedures(data);
      }

    };

    getProcedures();
    return () => { isMounted = false; };
  }, [id]);

  // Fetch of appointment
  useEffect(() => {
    let isMounted = true;

    const getAppointment = async () => {
      setIsLoading(true);
      const { data } = await APPOINTMENT_SERVICE.GET_ONE(id);

      if (isMounted === true) {
        if (data) {
          setAppointment(data);
          if (data.patient.id !== userContext.loggedUser.id && userContext.loggedUser.role !== 'admin') {
            history.push('/403');
            return [history];
          }
        } else {
          history.push('/404');
          return [history];
        }
      }
      await setIsLoading(false);
    }

    getAppointment();
    return () => { isMounted = false; };
  }, [userContext, history, id]);

  const dateObject = new Date(appointment.date);
  const year = dateObject.getUTCFullYear();
  let month = dateObject.getUTCMonth() + 1;
  let day = dateObject.getUTCDate();
  if (day <= 9) {
    day = "0" + day;
  }
  if (month <= 9) {
    month = "0" + month;
  }
  const date = `${year}-${month}-${day}`;

  const hours = Math.floor(parseInt(appointment.starts) / 100);
  const mins = (`0${parseInt(appointment.starts - hours * 100)}`).slice(-2);

  // States Cancel modal
  const [openCancel, setOpenCancel] = useState(false);

  // Open New Appointment modal
  const showCancelModal = () => {
    setOpenCancel(true);
  };
  // Close New Appointment modal
  const closeCancelModal = () => {
    setOpenCancel(false);
  };

  const modalCancelStyle = openCancel
    ? { display: 'block' }
    : { display: 'none' };
  const modalCancelClass = openCancel ? 'show' : '';

  // States reschedule modal
  const [openReschedule, setOpenReschedule] = useState(false);

  // Open New Appointment modal
  const showRescheduleModal = () => {
    setOpenReschedule(true);
  };
  // Close New Appointment modal
  const closeRescheduleModal = () => {
    setOpenReschedule(false);
  };

  const modalRescheduleStyle = openReschedule
    ? { display: 'block' }
    : { display: 'none' };
  const modalRescheduleClass = openReschedule ? 'show' : '';

  // States reschedule modal
  const [openNewRecurrent, setNewRecurrent] = useState(false);

  // Open New Appointment modal
  const showNewRecurrent = () => {
    setNewRecurrent(true);
  };
  // Close New Appointment modal
  const closeNewRecurrent = () => {
    setNewRecurrent(false);
  };

  const modalNewRecurrentStyle = openNewRecurrent
    ? { display: 'block' }
    : { display: 'none' };
  const modalNewRecurrentClass = openNewRecurrent ? 'show' : '';

  // States reschedule modal
  const [openConfirm, setConfirm] = useState(false);

  // Open New Appointment modal
  const showConfirm = () => {
    setConfirm(true);
  };
  // Close New Appointment modal
  const closeConfirm = () => {
    setConfirm(false);
  };

  //Differ Appointment modal
  const [differModal, setDifferModal] = useState(false);
  const showDifferModal = () => {
    setDifferModal(true);
  };
  const closeDifferModal = () => {
    setDifferModal(false);
  };

  return (
    <Layout>
      {!isLoading
        ? (
          <div className="container-fluid">
            <div className="row appointment-card">
              <div className="appointment-row">
                <div className="appointment-left">
                  <div className="row">
                    <h2>{appointment.category}</h2>
                  </div>

                  <div className="row">
                    <p>
                      <strong>{appointment.clinic.name}</strong>
                    </p>
                  </div>
                  <div className="row">
                    <p>
                      <strong>Dirección de la clínica: </strong>
                      {appointment.clinic.address}
                    </p>
                  </div>
                  <div className="row">
                    <p>
                      <strong>Dentista:</strong>
                      {' '}
                      {appointment.dentist.name}
                      {' '}
                      {appointment.dentist.surname}
                    </p>
                  </div>
                  {appointment.minor
                    ? (
                      <div className="row">
                        <strong>Nombre del menor:</strong>
                        {' '}
                        {appointment.minor.name}
                        {' '}
                        {appointment.minor.lastName}
                      </div>
                    )
                    : null}
                </div>
                <hr />
                <div className="appointment-right">
                  <div className="row appointment-right-content">
                    <h1>
                      {date}
                      {' '}
                      {hours}
                      :
                      {mins}
                      Hrs
                    </h1>
                  </div>
                  <div className="row appointment-right-content">
                    <p>
                      {appointment.patient.name}
                      {' '}
                      {appointment.patient.surname}
                    </p>
                  </div>
                  <div className="row appointment-right-content">
                    <p>{appointment.patient.email}</p>
                  </div>
                </div>
              </div>
              {appointment.confirmed ? (
                <div className="d-flex justify-content-center">
                  <QRGenerator idAppointment={id} />
                </div>
              ) : null}
              {appointment.status === 2 ? (
                <div className="mt-0 text-center">
                  <p
                    style={{
                      color: 'white',
                      backgroundColor: 'purple',
                      margin: '0px 0px 0px 0px',
                    }}
                  >
                    <strong>Agendar Proxima cita</strong>
                  </p>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="appointment-buttons-div">
              {userContext.loggedUser.role !== "admin" ? (
                <Link
                  type="button"
                  className="mr-auto btn btn-primary btn-lg appointment-buttons"
                  to={`/patient/${appointment.patient.id}`}
                >
                  Regresar a citas
                </Link>
              ) : (
                <Link
                  type="button"
                  className="mr-auto btn btn-primary btn-lg appointment-buttons"
                  to={`/appointments`}
                >
                  Regresar a citas
                </Link>
              )
              }
              {userContext.loggedUser.role === "admin" ? (
                <button
                  type="button"
                  className="btn btn-danger m-0"
                  onClick={showDifferModal}
                >
                  Re-agendar cita
                </button>
              ) : null}
              {appointment.status === 2 ? (
                <button
                  type="button"
                  className="btn btn-success btn-lg appointment-buttons"
                  onClick={showNewRecurrent}
                >
                  Agendar siguiente cita
                </button>
              ) : null}
              { !appointment.confirmed && (appointment.status === 0 || appointment.status === 5) && userContext.loggedUser.role !== "admin"
                ? (
                  <button
                    type="button"
                    className="btn btn-success appointment-buttons"
                    onClick={showConfirm}
                  >
                    Confirmar
                  </button>
                ) : null}
              {(appointment.status === 0 || appointment.status === 5) && !appointment.confirmed && userContext.loggedUser.role !== "admin" ? (
                <button
                  type="button"
                  className="btn btn-primary appointment-buttons"
                  onClick={showRescheduleModal}
                >
                  Reagendar
                </button>
              ) : null}
              {(appointment.status === 0 || appointment.status === 5) && !appointment.confirmed && userContext.loggedUser.role !== "admin" ? (
                <button
                  type="button"
                  className="btn btn-danger appointment-buttons"
                  onClick={showCancelModal}
                >
                  Cancelar
                </button>
              ) : null}
            </div>

            <CancelAppointmentModal
              modalStyle={modalCancelStyle}
              close={closeCancelModal}
              modalClass={modalCancelClass}
              patientId={appointment.patient.id}
              dataid={id}
              procedures={procedures}
            />
            <ReScheduleAppointmentModal
              modalStyle={modalRescheduleStyle}
              close={closeRescheduleModal}
              modalClass={modalRescheduleClass}
              patientId={appointment.patient.id}
              appointment={appointment}
              dataid={id}
              procedures={procedures}
            />
            <RecurrentAppointmentModal
              modalStyle={modalNewRecurrentStyle}
              close={closeNewRecurrent}
              modalClass={modalNewRecurrentClass}
              patientId={appointment.patient.id}
              appointment={appointment}
              dataid={id}
              procedures={procedures}
            />
            <ConfirmAppointmentModal
              isOpen={openConfirm}
              onRequestClose={closeConfirm}
              appointmentId={id}
              date={date}
              hours={hours}
              mins={mins}
              procedures={procedures}
            />
            <DifferAppointmentModal
              isOpen={differModal}
              onRequestClose={closeDifferModal}
              idAppointment={id}
            />
          </div>
        )
        : <div>Loading...</div>}
    </Layout>
  );
};

export default Appointment;
