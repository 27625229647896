import axios from '../config/axios';

const CLINIC_SERVICE = {
  GET_ONE: async (id) => {
    return await axios.get(`/clinics/${id}`);
  },

  GET_ALL: async () => {
    return await axios.get('/clinics/');
  },

  CREATE: async (data) => {
    return await axios.post('/clinics/', data);
  },

  DELETE: async (id) => {
    return await axios.delete(`/clinics/${id}`);
  },

  UPDATE: async (id, data) => {
    return await axios.put(`/clinics/${id}`, data);
  },
};

export default CLINIC_SERVICE;
