import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Register from './pages/Register';
import Landing from './pages/Landing';
import LogIn from './pages/LogIn';
import ResetPassword from './pages/Auth/ResetPassword';
import Admin from './pages/Dashboard/Admin';
import Dentist from './pages/Dashboard/Dentist';
import Patient from './pages/Dashboard/Patient';
import Appointment from './pages/Dashboard/Appointment';
import ProtectedRoute from './components/ProtectedRoute';
import UnAuthorized from './pages/Errors/403';
import QRGenerator from './components/QR/QRGenerator';
import QRScanner from './components/QR/QRScanner';
import PacientArrival from './components/PacientArrival/PacientArrival';
import NoAppointmentForm from './components/PacientArrival/NoAppointmentForm';
import NoDirection from './pages/Errors/404';
import DentistCalendar from './components/Calendar/Calendar';
import CompleteRegistration from './pages/RegisterPatient/CompleteRegistration';
import MedicalRecord from './pages/Dashboard/MedicalRecord';
import Procedures from './pages/Procedures';
import Clinics from './pages/Clinics';
import EditProcedure from './pages/EditProcedure';
import NewProcedure from './pages/NewProcedure';
import Expedients from './components/Patient/Expedients';
import ClinicForm from './components/Clinic/ClinicForm';
import PatientProfile from './pages/PatientProfile';
import Patients from './pages/Patients';
import NewFileRecord from './pages/NewFileRecord';
import ProfileInfo from './pages/ProfileInfo';
import Reports from './pages/Reports';
import Dentists from './pages/Dentists';
import DentistProfile from './pages/DentistProfile'
import NewDentist from './pages/NewDentist';
import NewPatientProfile from './pages/NewPatientProfile'
import NewMedicalRecord from './pages/NewMedicalRecord'
import TreatmentInfo from './pages/TreatmentInfo'
import Receptionist from './pages/Dashboard/Receptionist';
import NewReceptionist from './pages/NewReceptionist';
import Receptionists from './pages/Receptionists';
import ResetPasswordForm from './components/Recepcionist/ResetPasswordForm';
import Prices from './pages/Prices';
import ConfirmEmail from './pages/RegisterPatient/ConfirmEmail';
import UserProfile from './pages/UserProfile';
import Appointments from './pages/Appointments';
import EditPatient from './pages/EditPatient';

const AppRouter = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <Landing />
      </Route>
      <Route path="/register" exact>
        <Register />
      </Route>
      <Route path="/prices" exact>
        <Prices />
      </Route>
      <Route path="/confirm/:id" exact>
        <ConfirmEmail />
      </Route>
      <Route path="/login" exact>
        <LogIn />
      </Route>
      <Route path="/reset-password/:userId/:token" exact>
        <ResetPassword />
      </Route>
      <ProtectedRoute path="/medicalrecord" exact roles={['admin', 'dentist']}>
        <MedicalRecord />
      </ProtectedRoute>
      <ProtectedRoute path="/procedures" exact roles={['admin']}>
        <Procedures />
      </ProtectedRoute>
      <ProtectedRoute path="/procedures/:id" exact roles={['admin']}>
        <EditProcedure />
      </ProtectedRoute>
      <ProtectedRoute path="/procedures/new" exact roles={['admin']}>
        <NewProcedure />
      </ProtectedRoute>
      <ProtectedRoute path="/expedients/new/file/:id" exact roles={['admin', 'dentist']}>
        <NewFileRecord />
      </ProtectedRoute>
      <ProtectedRoute path="/clinics" exact roles={['admin']}>
        <Clinics />
      </ProtectedRoute>
      <ProtectedRoute path="/clinics/:id" exact roles={['admin']}>
        <ClinicForm />
      </ProtectedRoute>
      <ProtectedRoute path="/clinics/new" exact roles={['admin']}>
        <ClinicForm />
      </ProtectedRoute>
      <ProtectedRoute path="/resetpassword" exact roles={['patient']}>
        <ResetPassword />
      </ProtectedRoute>
      <ProtectedRoute path="/completeregistration" exact roles={['patient']}>
        <CompleteRegistration />
      </ProtectedRoute>
      <ProtectedRoute path="/admin" exact roles={['admin']}>
        <Admin />
      </ProtectedRoute>
      <ProtectedRoute path="/reports" exact roles={['admin']}>
        <Reports />
      </ProtectedRoute>
      <ProtectedRoute path="/admin/dentists" exact>
        <Dentists />
      </ProtectedRoute>
      <ProtectedRoute path="/admin/dentist/new" exact>
        <NewDentist />
      </ProtectedRoute>
      <ProtectedRoute path="/admin/dentists/:id" exact>
        <DentistProfile />
      </ProtectedRoute>
      <ProtectedRoute path="/admin/expedients" exact>
        <Expedients />
      </ProtectedRoute>
      <ProtectedRoute path="/patients" exact roles={['admin', 'dentist']}>
        <Patients />
      </ProtectedRoute>
      <ProtectedRoute path="/patients/:id" exact roles={['admin', 'dentist']}>
        <PatientProfile />
      </ProtectedRoute>
      <ProtectedRoute path="/patients/profile/:id" exact roles={['admin', 'dentist']}>
        <ProfileInfo />
      </ProtectedRoute>
      <ProtectedRoute path="/dentist/:id" exact roles={['dentist']}>
        <Dentist />
      </ProtectedRoute>
      <ProtectedRoute path="/patient/:id" exact roles={['patient']}>
        <Patient />
      </ProtectedRoute>
      <ProtectedRoute path="/patient/myprofile/:id" exact roles={["patient"]}>
        <UserProfile/>
      </ProtectedRoute>
      <ProtectedRoute path="/patient/editprofile/:id" exact roles={["patient"]}>
        <EditPatient/>
      </ProtectedRoute>
      <ProtectedRoute path="/appointments/:id" exact roles={['admin', 'patient']}>
        <Appointment />
      </ProtectedRoute>
      <ProtectedRoute path="/test/qr" exact roles={['receptionist']}>
        <QRGenerator />
      </ProtectedRoute>
      <ProtectedRoute path="/receptionist/appointments/" exact roles={['receptionist']}>
        <PacientArrival />
      </ProtectedRoute>
      <ProtectedRoute path="/receptionist/appointments/qr" exact roles={['receptionist']}>
        <QRScanner />
      </ProtectedRoute>
      <ProtectedRoute path="/dentist/:id/calendar" exact roles={['dentist']}>
        <DentistCalendar />
      </ProtectedRoute>
      <ProtectedRoute path="/receptionist/appointments/new" exact>
        <NoAppointmentForm />
      </ProtectedRoute>
      <ProtectedRoute path="/patients/profile/create/new" exact roles={['admin', 'dentist']}>
        <NewPatientProfile />
      </ProtectedRoute>
      <ProtectedRoute path="/patients/profile/:id/create/medicalrecord" exact roles={['admin', 'dentist']}>
        <NewMedicalRecord />
      </ProtectedRoute>
      <ProtectedRoute path="/patients/profile/:id/medicalrecord/:recordId" exact roles={['admin', 'dentist']}>
        <TreatmentInfo />
      </ProtectedRoute>
      <ProtectedRoute path="/receptionist/" exact roles={['receptionist']}>
        <Receptionist />
      </ProtectedRoute>
      <ProtectedRoute path='/receptionists/new' exact roles={['admin']}>
        <NewReceptionist/>
      </ProtectedRoute>
      <ProtectedRoute path='/receptionists' exact roles={['admin']}>
        <Receptionists/>
      </ProtectedRoute>
      <ProtectedRoute path='/receptionists/reset/:id' exact roles={['admin']}>
        <ResetPasswordForm/>
      </ProtectedRoute>
      <ProtectedRoute path="/appointments" exact roles={['admin']}>
        <Appointments />
      </ProtectedRoute>
      <Route path="/403" exact>
        <UnAuthorized />
      </Route>
      <Route>
        <NoDirection />
      </Route>
    </Switch>
  );
};

export default AppRouter;
