import { Formik } from 'formik';
import React from 'react';

const TutorField = (props) => {
    const formik = props.formik ;

    return (
    <Formik>
        <div>
            <label htmlFor='field'>*{props.text}</label>
            <input
                type="text"
                className={`form-control ${
                    props.errors && props.touched
                        ? "is-invalid"
                        : ""
                    }`}
                    id={props.id}
                    name={props.id}
                    value={props.values}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
            />

            {props.errors && props.touched && (
                <div className="invalid-feedback">{props.errors}</div>
            )}
        </div>
    </Formik>
        
    );
}

export default TutorField;
