import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";

const AppointmentRowTable = ({
  appointmentData,
  setHour,
  hourRow,
  numRows,
  id,
  startHour,
  endHour,
}) => {
  const [rowColor] = useState();
  const [, setDate] = useState();
  // const [date, setDate] = useState();
  const [hoursStart, setHoursStart] = useState();
  const [minsStart, setMinsStart] = useState();
  const [hoursEnd, setHoursEnd] = useState();
  const [minsEnd, setMinsEnd] = useState();
  const [statusColor, setStatusColor] = useState();
  const [appointmentStatus, setAppointmentStatus] = useState("");
  const CustomTag = appointmentData.category ? Link : "div";

  const status = useMemo(() => {
    return {
      confirmada: "#92d192",
      cancelada: "#fa8e8e",
      sin_confirmar: "#000000",
    };
  }, []);

  useEffect(() => {
    const dateObject = new Date(appointmentData.date);
    const year = dateObject.getUTCFullYear();
    const month = dateObject.getUTCMonth() + 1;
    const day = dateObject.getUTCDate();
    setDate(`${day}-${month}-${year}`);

    setHoursStart(Math.floor(parseInt(appointmentData.starts) / 100));

    setMinsStart(
      parseInt(appointmentData.starts) % 100 === 0
        ? "00"
        : parseInt(appointmentData.starts) % 100
    );
    setHoursEnd(Math.floor(parseInt(appointmentData.ends) / 100));
    setMinsEnd(
      parseInt(appointmentData.ends) % 100 === 0
        ? "00"
        : parseInt(appointmentData.ends) % 100
    );

    setAppointmentStatus(
      appointmentData.confirmed ? "Confirmada" : "Sin confirmar"
    );
    setStatusColor(
      status[appointmentData.confirmed ? "confirmada" : "sin_confirmar"]
    );
  }, [
    status,
    appointmentData.status,
    startHour,
    endHour,
    appointmentData.confirmed,
    appointmentData.date,
    appointmentData.ends,
    appointmentData.starts,
  ]);

  const hourToString = (hour) => {
    let h = Math.floor(parseInt(hour) / 100);
    let m = parseInt(hour) % 100;
    if (m === 0) m = "00";
    return h + ":" + m;
  };

  const nextHour = (hour) => {
    return hour % 100 === 0 ? hourToString(hour + 30) : hourToString(hour + 70);
  };

  return appointmentData.status !== "Disponible" ? (
    <tr style={{ backgroundColor: rowColor }}>
      {setHour && (
        <td
          style={{ verticalAlign: "middle" }}
          rowSpan={numRows}
          key={
            appointmentData.id +
            "-date-" +
            hoursStart +
            ":" +
            minsStart +
            "-" +
            hoursEnd +
            ":" +
            minsEnd
          }
        >
        <CustomTag
          to={`/appointments/${appointmentData.id}`}
        >
          {hourToString(hourRow)} - {nextHour(parseInt(hourRow))}
        </CustomTag>
        </td>
      )}
      <td key={id + "-clinic-" + appointmentData.clinic.id}>
        <CustomTag
          to={`/appointments/${appointmentData.id}`}
        >
        {appointmentData.clinic.name}
        </CustomTag>
      </td>
      <td key={id + "-doctor-" + appointmentData.dentist.id}>
        <CustomTag
          to={`/appointments/${appointmentData.id}`}
        >
        {appointmentData.dentist.name} {appointmentData.dentist.surname}
        </CustomTag>
      </td>
      <td key={(id + "-patient-" + appointmentData.patient, id)}>
        <CustomTag
          to={`/appointments/${appointmentData.id}`}
        >
        {appointmentData.patient.name} {appointmentData.patient.surname}
        </CustomTag>
      </td>
      <td key={id + "-service-" + appointmentData.category}>
        <CustomTag
          to={`/appointments/${appointmentData.id}`}
        >
        {appointmentData.category}
        </CustomTag>
      </td>
      <td
        key={id + "-status-" + appointmentData.status}
        style={{ color: statusColor }}
      >
        <CustomTag
          to={`/appointments/${appointmentData.id}`}
        >
        {appointmentStatus}
        </CustomTag>
      </td>
    </tr>
  ) : (
    <tr style={{ backgroundColor: "#e0ecff" }}>
      {setHour && (
        <td
          style={{ verticalAlign: "middle" }}
          rowSpan={numRows}
          key={
            appointmentData.id +
            "-date-" +
            hoursStart +
            ":" +
            minsStart +
            "-" +
            hoursEnd +
            ":" +
            minsEnd
          }
        >
          {hourToString(appointmentData.starts)} -{" "}
          {hourToString(appointmentData.ends)}
        </td>
      )}
      <td
        colSpan={5}
        key={id + "-status-" + appointmentData.status}
        style={{ color: statusColor, textAlign: "center" }}
      >
        <div style={{ textAlign: "center" }}>Disponible</div>
      </td>
    </tr>
  );
};

export default AppointmentRowTable;
