import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";

import svg from "../../assets/svg/chart.svg";
import USER_SERVICE from "../../services/user";
// import NavBar from "../../components/NavBar/NavBar";
import { UserContext } from "../../context/context";
import Layout from "../../components/Layout/Layout";

const countryCodes = [
  {
    value: "+52",
    label: "+52 (México)",
  },
  {
    value: "+1",
    label: "+1 (EUA)",
  },
];

registerLocale("es", es);

const CompleteRegistration = () => {
  const userContext = useContext(UserContext);
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [date, setDate] = useState("");
  const id = userContext.loggedUser.id;

  function getAge(birthdayValue) {
    const now = new Date();
    const birthDate = new Date(birthdayValue);
    let age = now.getFullYear() - birthDate.getFullYear();
    const month = now.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && now.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const formik = useFormik({
    initialValues: {
      birthday: " ",
      countryCode: "+52",
      cellphone: "",
    },
    validationSchema: Yup.object({
      birthday: Yup.string()
        .required("Una fecha de nacimiento es requerida.")
        .test("is-adult", "Debes ser mayor de edad.", (value) => {
          return getAge(value) >= 18;
        }),
      countryCode: Yup.string().required("Un codigo de país es requerido."),
      cellphone: Yup.string()
        .required("Un teléfono es requerido.")
        .matches(/^[0-9]{10}$/gi, "Teléfeno debe ser 10 digitos de largo."),
    }),
    onSubmit: async (values) => {
      values.phone = values.countryCode + values.cellphone;
      values.age = getAge(values.birthday);
      try {
        setErrorMessage("");
        const data = values;
        await USER_SERVICE.COMPLETE_PROFILE({ id, data });
        history.push("/patient/" + id);
      } catch (error) {
        console.log(error);
        setErrorMessage("Campos incorrectos");
      }
    },
  });

  return (
    <Layout>
      <div className="container-fluid">
        <div className="row">
          <div className="col px-0 d-none d-lg-block">
            <img src={svg} alt="" width="100%" height="100%" />
          </div>
          <div className="col my-md-5 mr-md-5 pr-md-4">
            <form>
              <div className="form-group">
                <label htmlFor="birthday">*Fecha de nacimiento</label>
                {/* <input
                  type="date"
                  className={`form-control ${
                    formik.errors.birthday && formik.touched.birthday
                      ? "is-invalid"
                      : ""
                  }`}
                  id="birthday"
                  name="birthday"
                  value={formik.values.birthday}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.birthday && formik.touched.birthday && (
                  <div className="invalid-feedback">
                    {formik.errors.birthday}
                  </div>
                )} */}
                <DatePicker
                  customInput={
                    <input className="form-control" name="birthday" />
                  }
                  className={`${
                    formik.errors.birthday && formik.touched.birthday
                      ? "is-invalid"
                      : ""
                  }`}
                  locale="es"
                  withPortal
                  //minDate={new Date("1900-01-01")}
                  maxDate={new Date()}
                  showYearDropdown
                  yearDropdownItemNumber={50}
                  scrollableYearDropdown
                  id="birthday"
                  name="birthday"
                  placeholderText="dd/mm/aaaa"
                  selected={date}
                  onChange={(rawDate) => {
                    formik.values.birthday = rawDate;
                    setDate(rawDate);
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.birthday && formik.touched.birthday && (
                  <div
                    className=""
                    style={{ color: "#e74a3b", fontSize: "13px" }}
                  >
                    {formik.errors.birthday}
                  </div>
                )}
              </div>
              <div>
                <label htmlFor="cellphone">*Número de teléfono</label>
              </div>
              <div className="form-row">
                <div className="form-group col-md-3">
                  <select
                    className={`form-control ${
                      formik.errors.countryCode && formik.touched.countryCode
                        ? "is-invalid"
                        : ""
                    }`}
                    name="countryCode"
                    value={formik.values.countryCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {countryCodes.map((cc) => {
                      return (
                        <option key={cc.value} value={cc.value}>
                          {cc.label}
                        </option>
                      );
                    })}
                    <option>...</option>
                  </select>
                  {formik.errors.countryCode && formik.touched.countryCode && (
                    <div className="invalid-feedback">
                      {formik.errors.countryCode}
                    </div>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <input
                    type="tel"
                    className={`form-control ${
                      formik.errors.cellphone && formik.touched.cellphone
                        ? "is-invalid"
                        : ""
                    }`}
                    id="cellphone"
                    name="cellphone"
                    value={formik.values.cellphone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.cellphone && formik.touched.cellphone && (
                    <div className="invalid-feedback">
                      {formik.errors.cellphone}
                    </div>
                  )}
                  {errorMessage && (
                    <div className="text-danger">{errorMessage}</div>
                  )}
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-primary btn-lg"
                onClick={formik.handleSubmit}
              >
                Completar registro
              </button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default CompleteRegistration;
