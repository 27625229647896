import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import APPOINTMENT_SERVICE from "../../services/appointment";
import IsPatientRegistered from "../../components/DentistModals/IsPatientRegistered";
import DifferAppointmentModal from "./DifferAppointmentModal";

const AppointmentCard = (props) => {
  const [isRegisterPatientModalOpen, setisRegisterPatientModalOpen] = useState(false);

  const id = props.keydata;
  const dateObject = new Date(props.date);
  const year = dateObject.getUTCFullYear();
  let month = dateObject.getUTCMonth() + 1;
  let day = dateObject.getUTCDate();
  const hours = Math.floor(parseInt(props.time) / 100);
  const mins = ("0" + parseInt(props.time - hours * 100)).slice(-2);
  //Format for days adding a 0 before the day if needed
  if (day <= 9) {
    day = "0" + day;
  }
  //Format for month adding a 0 before the month if needed
  if (month <= 9) {
    month = "0" + month;
  }

  const isAppointmentDone = () => {
    const now = new Date();
    return now.getFullYear() - year > 0
      ? true
      : now.getFullYear() - year < 0
        ? false
        : now.getMonth() - month + 1 > 0
          ? true
          : now.getMonth() - month + 1 < 0
            ? false
            : now.getDate() - day > 0
              ? true
              : now.getDate() - day < 0
                ? false
                : true;
                  // now.getHours() - hours > 0
                  // ? true
                  // : now.getHours() - hours < 0
                  //   ? false
                  //   : now.getMinutes() - mins >= 0
                  //     ? true
                  //     : false;
  };
  const data = { status: 0 };

  const isCanceled = props.status === 1 || props.status === 4 || props.status === 3;
  const role = props.role === "dentist" ? "Paciente" : "Dentista";
  const CustomTag = props.role === "patient" && !isCanceled ? Link : "div";
  const isDisabled =
    props.role === "patient" ||
    props.status === 4 ||
    props.status === 1 ||
    !isAppointmentDone();


  const statusAppointment =
    props.status === 2 || props.status === 4 ? true : false;

  let checkedBox = useRef(null);
  const saveAppointments = async () => {
    if (checkedBox.current.checked === true) {
      data.status = 2;
      await APPOINTMENT_SERVICE.UPDATE(id, data);
    } else {
      data.status = 0;
      await APPOINTMENT_SERVICE.UPDATE(id, data);
    }
  };

  const showModal = () => {
    setisRegisterPatientModalOpen(true);
  };
  const closeModal = () => {
    setisRegisterPatientModalOpen(false);
  };

  const [differModal, setDifferModal] = useState(false);

  const closeDifferModal = () => {
    setDifferModal(false);
  };

  return (
    <>
      <IsPatientRegistered
        isOpen={isRegisterPatientModalOpen}
        onRequestClose={closeModal}
        idAppointment={id}
      ></IsPatientRegistered>
      <DifferAppointmentModal
        isOpen={differModal}
        onRequestClose={closeDifferModal}
        idAppointment={id}
      />
      <CustomTag
        className="col col-md-5 card shadow-sm px-0 mb-4"
        to={`/appointments/${props.keydata}`}
      >
        <div className="card-header">
          <h3>{props.procedure}</h3>
        </div>
        <div className="card-body">
          <div className="d-flex mb-0">
            <p className="mb-0"><strong>Fecha:</strong>&nbsp;{year + "-" + month + "-" + day}&nbsp;</p>
            <p className="mb-0">
              <strong>Hora:</strong>&nbsp;{hours}:{mins}
            </p>
          </div>
          <strong>{role}:</strong>&nbsp;{props.doc}
          {!props.patient && !isCanceled ? (
            <div className="row mt-2">
              <button
                type="submit"
                className="btn btn-info "
                onClick={showModal}
              >
                Asignar paciente
              </button>
            </div>
          ) : (
            <></>
          )}
          <form>
            <div className="form-check">
              <input
                ref={checkedBox}
                className="form-check-input"
                type="checkbox"
                id="followUpServiceCheckBox"
                disabled={isDisabled}
                defaultChecked={statusAppointment}
                onChange={saveAppointments}
              />
              <label className="form-check-label" htmlFor="defaultCheck1">
                Cita Recurrente
              </label>
            </div>
          </form>
          {/*buttonDiffer ? (
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-danger m-0"
                onClick={showDifferModal}
              >
                Re-agendar cita
              </button>
            </div>
          ) : (
            <></>
          )*/}
        </div>
        {props.status === 1 || props.status === 4 ? (
          <div className="mt-0 card-footer d-flex justify-content-center" style={{
            backgroundColor: "#dc3545",
            color: "white",
          }}>
            <p className="m-0">
              <strong>Cancelada</strong>
            </p>
          </div>
        ) : (
          <></>
        )}
        {props.status === 6 || props.status === 3 ? (
          <div className="mt-0 card-footer d-flex justify-content-center" style={{
            backgroundColor: "blue",
            color: "white",
          }}>
            <p className="m-0">
              <strong>Completa</strong></p>
          </div>
        ) : (
          <></>
        )}
        {props.status === 2 ? (
          <div className="mt-0 card-footer d-flex justify-content-center" style={{
            backgroundColor: "purple",
            color: "white",
          }}>
            <p className="m-0">
              <strong>Agendar Proxima cita</strong>
            </p>
          </div>
        ) : (
          <></>
        )}
      </CustomTag>
    </>
  );
};

export default AppointmentCard;
