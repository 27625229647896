import { FastField, Formik } from "formik";
import * as Yup from "yup";
import ReactDatePicker from "react-datepicker";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { parseISO } from "date-fns";

const FastDatePicker = ({ id, validation, setValue }, ref) => {

    const [initialValue, setInitialValue] = useState(null);
    useImperativeHandle(ref, () => ({
        async onSubmit() {
            fastField.current.handleSubmit();
            let errors = await fastField.current.validateForm();
            return (errors);
        },
        setInitialValue(values) {
            setInitialValue(values[id]);
        }
    }));

    const fastField = useRef(null);

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    date: initialValue ?? '',
                }}
                validationSchema={Yup.object().shape({
                    date: Yup.string(),
                })}
                innerRef={fastField}
                onSubmit={() => { }}
            >
                <FastField
                    name='date'
                    component={DatePicker}
                    setValue={setValue}
                    id={id}
                />
            </Formik>
        </>
    );
}

const DatePicker = ({ field: { onChange, name, value, onBlur },
    form: { touched, errors }, validation, id, setValue }) => {

    const [date, setDate] = useState(null);

    useEffect(() => {
        if (date) {
            setValue(date, id);
        }
    }, [date, id, setValue]);

    // const { setFieldValue } = useFormikContext();

    return (
        value ?
            <div className='flex flex-column'>
                <ReactDatePicker
                    className={`form-control ${touched[name]
                        && errors[name] ? "is-invalid" : ""}`}
                    onChange={value => {
                        setDate(value);
                        // setFieldValue('date', value);
                    }}
                    name={name}
                    selected={parseISO(value)}
                    showYearDropdown
                    yearDropdownItemNumber={30}
                    scrollableYearDropdown
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/AAAA"
                    maxDate={new Date()}
                    onBlur={e => {
                        onBlur(e);
                        setValue(e.target.value, id);
                    }}
                />
                {touched[name] && errors[name]
                    && <div className="invalid-feedback">{errors[name]}</div>}
            </div>
            :
            <div className='flex flex-column'>
                <ReactDatePicker
                    className={`form-control ${touched[name]
                        && errors[name] ? "is-invalid" : ""}`}
                    onChange={value => {
                        setDate(value);
                    }}
                    name={name}
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    selected={date ?? parseISO((new Date()).toISOString())}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/AAAA"
                    maxDate={new Date()}
                    onBlur={e => {
                        onBlur(e);
                        setValue(e.target.value, id);
                    }}
                />
                {touched[name] && errors[name]
                    && <div className="invalid-feedback">{errors[name]}</div>}
            </div>
    );
}

export default forwardRef(FastDatePicker);
