import { Field, Formik } from 'formik';
import React, { useState } from 'react';

const TutorCheckbox = (props) => {
    const message = `Soy menor de edad / Quiero agregar 
    información de tutor/guardián`

    const [needTutor, setState] = useState( false );

    function handleCheck( event )  {
        const checked = event.target.checked ;
        setState( checked );
        props.onChange( checked );
    }

    return(
        <Formik>
                <label>
                    <Field type='checkbox' 
                            name='needTutor' 
                            checked={needTutor}
                            onChange={handleCheck}
                    />
                    {`  ${message}`}
                </label>
        </Formik>
    );
}

export default TutorCheckbox;
